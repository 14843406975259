import { uniqBy } from "lodash";
import {
  BotPlayerTypes,
  EventConfig,
  FetchBundleFn,
  OnStepConsumedFn,
} from "./types";
import {
  during,
  isChoicesSteps,
  isEmailSteps,
  isGotoSteps,
  isMessageStep,
  isPhoneSteps,
  isStartSteps,
  isStopSteps,
  isTranslateSteps,
  isUrlSteps,
} from "./utils";

class WaitToExecute {}

export type BotPlayerConfig = {
  /**
   * Fonction permettant de charger un bot
   */
  fetchBundleFn: FetchBundleFn;
  /**
   * Fonction permettant de tracker
   */
  onStepConsumed?: OnStepConsumedFn;
  /**
   * Appelé lorsque le client doit afficher un message du bot
   */
  onShowMessage: (config: EventConfig.ShowMessage) => void | Promise<void>;
  /**
   * Appelé lorsque le client doit afficher des choix possibles à l'utilisateur.
   * Chaque option contient une fonction à appeler lorsque l'utilisateur la choisit
   */
  onAskToChoose: (config: EventConfig.AskToChoose) => void | Promise<void>;
  /**
   * Appelé lorsque le client doit afficher une URL à l'utilisateur.
   * Contient une fonction à appeler lorsque l'utilisateur termine la visite du site (ou la skip)
   */
  onOpenLink: (config: EventConfig.OpenLink) => void | Promise<void>;
  /**
   * Appelé lorsque le client doit lancer un appel téléphonique.
   * Contient une fonction à appeler lorsque l'utilisateur raccroche (ou skip l'appel)
   */
  onMakePhoneCall: (config: EventConfig.MakePhoneCall) => void | Promise<void>;
  /**
   * Appelé lorsque le client doit proposer l'envoi d'un email
   * Contient une fonction à appeler lorsque l'utilisateur termine la rédaction (ou skip l'email)
   */
  onSendEmail: (config: EventConfig.SendEmail) => void | Promise<void>;
  /**
   * Appelé lorsque le client doit afficher/masquer le témoin d'écritire
   */
  onTyping: (typing: boolean) => void | Promise<void>;
  /**
   * Appelé lorsque le client doit mettre fin à la conversion (fermer la fenêtre, etc)
   */
  onEnded: () => void | Promise<void>;
  /**
   * Appelé lorsque le client indique que le bot a rencontré une erreur.
   * L'erreur peut être :
   * - Toute erreur levée dans l'une des autres fonctions de configuration du bot
   * - une `BotExecutionError` en cas d'erreur interne au bot
   * - Toute autre erreur
   * A la première erreur recontrée, le bot s'arrête complètement.
   */
  onError: (error: any) => void | Promise<void>;
};

/**
 * @example
 * ```
 * import BotPlayer from "@noese/chatbot-player"
 * const player = new BotPlayer({
 *    onFetchBundle : async (uuid, locale) => {
 *      const res = fetch(`https://bot.noese.io/${uuid}/${locale}`)
 *      const json = await res.json()
 *      return(json)
 *    },
 *    onShowMessage : (e) => {
 *      const element = document.createElement("div")
 *      el.classList.add("message")
 *      el.textContent = e.text
 *      document.window.append(el)
 *    },
 *    ...  // see BotPlayerConfig for full config
 * })
 * player.start("00000000-00000000-00000000-00000000", "fr-fr")
 * ```
 */
export default class BotPlayer {
  private queue: Array<() => any> = [];
  private flushing: boolean = false;
  private started: boolean = false;
  private ended: boolean = false;
  private failed: boolean = false;

  constructor(private config: BotPlayerConfig) {}

  start(locale: string, startAt?: number) {
    if (this.started === true)
      throw new BotExecutionError("Bot is already started");
    this.started = true;

    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const bundle = await this.findBundle(locale);
      this.armBundle(bundle);
      stopTyping();
      if (startAt !== undefined) {
        const step = this.findStep(bundle.uuid, startAt);
        this.sendSteps([step]);
      } else {
        this.sendSteps(bundle.script);
      }
    });
  }

  destroy() {
    this.config.onShowMessage = () => {};
    this.config.onAskToChoose = () => {};
    this.config.onOpenLink = () => {};
    this.config.onMakePhoneCall = () => {};
    this.config.onSendEmail = () => {};
    this.config.onTyping = () => {};
    this.config.onError = () => {};
    this.config.onEnded = () => {};
    this.queue = [];
    this.bundleCache = [];
    this.index = new Map();
    this.started = true;
    this.ended = true;
    this.failed = false;
    this.flushing = false;
  }

  // Bundle management

  private bundle: BotPlayerTypes.Bundle | null = null;
  private bundleCache: Array<BotPlayerTypes.Bundle> = [];
  private currentScript: string | null = null;

  private index = new Map<string, BotPlayerTypes.Step>();

  private async findBundle(locale: string): Promise<BotPlayerTypes.Bundle> {
    const inCache = this.bundleCache.find((b) => b.language === locale);
    if (inCache) return inCache;
    const fromNetwork = await this.config.fetchBundleFn(locale);
    this.bundleCache.unshift(fromNetwork);
    this.bundleCache = uniqBy(this.bundleCache, (b) => b.language);
    return fromNetwork;
  }

  private armBundle(bundle: BotPlayerTypes.Bundle) {
    this.indexBundle(bundle);
    this.bundle = bundle;
    if (this.currentScript === null) this.currentScript = bundle.uuid;
  }

  private indexBundle(bundle: BotPlayerTypes.Bundle) {
    this.index = new Map();
    this.indexSteps(bundle.script, bundle.uuid);
    Object.entries(bundle.external_scripts).forEach(([uuid, s]) =>
      this.indexSteps([s], uuid)
    );
  }

  private indexSteps(steps: Array<BotPlayerTypes.Step>, uuid: string) {
    steps.forEach((s) => {
      this.index.set(`${uuid}#${s.id}`, s);
      if (s.children) this.indexSteps(s.children, uuid);
    });
  }

  private findStep(uuid: string, id: number | string) {
    const step = this.index.get(`${uuid}#${id}`);
    if (!step)
      throw new BotExecutionError("No step found", {
        bundle: this.bundle,
        index: this.index,
        id,
      });
    return step;
  }

  // Queue

  private pushInQueue(fn: () => Promise<void>) {
    this.queue.push(fn);
    this.flushQueue();
  }

  private async flushQueue() {
    if (!this.started) return;
    if (this.failed) return;
    if (this.ended) return;
    if (this.flushing) return;
    const fn = this.queue[0];
    if (fn === undefined) return;
    try {
      this.flushing = true;
      await fn();
      this.queue.shift();
      this.flushing = false;
      this.flushQueue();
    } catch (err) {
      if (err instanceof WaitToExecute) {
        this.flushing = false;
        return;
      } else {
        this.failed = true;
        this.config.onError(err);
      }
    }
  }

  private startTyping() {
    this.config.onTyping(true);
    return () => this.config.onTyping(false);
  }

  private sendSteps(steps: Array<BotPlayerTypes.Step>) {
    if (steps.length === 0) {
      this.stopScript();
    } else if (isStartSteps(steps)) {
      this.sendStartSteps(steps);
    } else if (isMessageStep(steps)) {
      this.sendBotMessagesSteps(steps);
    } else if (isChoicesSteps(steps)) {
      this.sendChoicesSteps(steps);
    } else if (isUrlSteps(steps)) {
      this.sendUrlSteps(steps);
    } else if (isPhoneSteps(steps)) {
      this.sendPhoneSteps(steps);
    } else if (isEmailSteps(steps)) {
      this.sendEmailSteps(steps);
    } else if (isTranslateSteps(steps)) {
      this.sendTranslateSteps(steps);
    } else if (isGotoSteps(steps)) {
      this.sendGotoSteps(steps);
    } else if (isStopSteps(steps)) {
      this.sendStopSteps(steps);
    } else {
      this.pushInQueue(() => {
        throw new BotExecutionError("Unhandled steps", { steps });
      });
    }
  }

  private buildId() {
    return `id${Math.random()}`;
  }

  private getText(step: BotPlayerTypes.Step): string {
    return step.data.name;
  }

  private trackVisit(step: BotPlayerTypes.Step) {
    if (!this.config.onStepConsumed) return;
    const bundle = this.bundle;
    if (!bundle) throw new Error("No bundle");
    const script = this.currentScript;
    if (!script) throw new Error("No script");
    this.config.onStepConsumed(bundle, script, step);
  }

  private sendStartSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const step = steps[0];
      this.trackVisit(step);
      this.sendSteps(steps[0].children || []);
    });
  }

  private sendBotMessagesSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const step = steps[0];
      const text = this.getText(step);
      this.config.onTyping(true);
      const duration = text.length * 30;
      await during(duration);
      this.trackVisit(step);
      stopTyping();
      await this.config.onShowMessage({
        id: this.buildId(),
        type: "ShowMessage",
        text,
      });
      this.sendSteps(steps[0].children || []);
    });
  }

  private sendChoicesSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const duration = steps.map((s) => this.getText(s)).join(" ").length * 10;
      this.config.onTyping(true);
      await during(duration);
      stopTyping();
      const scriptUuid = this.currentScript;
      await this.config.onAskToChoose({
        id: this.buildId(),
        type: "AskToChoose",
        options: steps.map((step) => ({
          id: this.buildId(),
          text: this.getText(step),
          onSelect: () => {
            this.currentScript = scriptUuid;
            this.trackVisit(step);
            this.sendSteps(step.children || []);
          },
        })),
      });
    });
  }

  private sendUrlSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const step = steps[0];
      const data = this.getText(step);
      this.config.onTyping(true);
      const duration = data.length * 20;
      await during(duration);
      stopTyping();
      await this.config.onOpenLink({
        id: this.buildId(),
        type: "OpenLink",
        url: data,
        onVisited: () => {
          this.trackVisit(step);
          this.sendSteps(steps[0].children || []);
        },
        onSkip: () => {
          this.sendSteps(steps[0].children || []);
        },
      });
    });
  }

  private sendPhoneSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const step = steps[0];
      const data = this.getText(step);
      this.config.onTyping(true);
      const duration = data.length * 20;
      await during(duration);
      stopTyping();
      await this.config.onMakePhoneCall({
        id: this.buildId(),
        type: "MakePhoneCall",
        phoneNumber: data.replace(/\s/g, ""),
        onHangUp: () => {
          this.trackVisit(step);
          this.sendSteps(steps[0].children || []);
        },
        onSkip: () => {
          this.sendSteps(steps[0].children || []);
        },
      });
    });
  }

  private sendEmailSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const step = steps[0];
      const data = this.getText(step);
      this.config.onTyping(true);
      const duration = data.length * 20;
      await during(duration);
      stopTyping();
      await this.config.onSendEmail({
        id: this.buildId(),
        type: "SendEmail",
        emailAddress: data,
        onSent: () => {
          this.trackVisit(step);
          this.sendSteps(steps[0].children || []);
        },
        onSkip: () => {
          this.sendSteps(steps[0].children || []);
        },
      });
    });
  }

  private sendGotoSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const step = steps[0];
      this.trackVisit(step);
      const target = steps[0].data.action_args as string;
      const [uuid, stepId] = target.split("#");
      const targetStep = this.findStep(uuid, stepId);
      this.currentScript = uuid;
      stopTyping();
      if (targetStep.data.kind === "CH")
        this.sendSteps(targetStep.children || []);
      else this.sendSteps([targetStep]);
    });
  }

  private sendTranslateSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const stopTyping = this.startTyping();
      const step = steps[0];
      this.trackVisit(step);
      const newLocale = step.data.action_args as string;
      const bundle = await this.findBundle(newLocale);
      this.armBundle(bundle);
      const oldChildren = step.children || [];
      const currentScript = this.currentScript;
      if (!currentScript) throw new Error("No current script");
      const newChildren = oldChildren.map((c) => {
        return this.findStep(currentScript, c.id);
      });
      stopTyping();
      this.sendSteps(newChildren);
    });
  }

  private sendStopSteps(steps: Array<BotPlayerTypes.Step>) {
    this.pushInQueue(async () => {
      const step = steps[0];
      this.trackVisit(step);
      this.ended = true;
      this.config.onEnded();
    });
  }

  private stopScript() {
    this.pushInQueue(async () => {
      this.ended = true;
      this.config.onEnded();
    });
  }
}

export class BotExecutionError extends Error {
  constructor(message: string, readonly extra?: any) {
    super(message);
  }
}

export type { FetchBundleFn, OnStepConsumedFn, EventConfig };
