import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import countries from "i18n-iso-countries";
import fr from "i18n-iso-countries/langs/fr.json";
import { get } from "lodash";
countries.registerLocale(fr);
const phoneUtil = PhoneNumberUtil.getInstance();

export type PhoneZone = {
  code: string;
  label: string;
  flag: any;
  prefix: number;
};

const regions = phoneUtil.getSupportedRegions();
const zones: Array<PhoneZone> = [];
regions.forEach((code) => {
  const name = countries.getName(code, "fr");
  if (!hasFlag(code)) return;
  const flag = getUnicodeFlagIcon(code);
  zones.push({
    code,
    label: name,
    flag,
    prefix: phoneUtil.getCountryCodeForRegion(code),
  });
});

export class InternationalPhoneNumber {
  readonly value: string;
  constructor(input: string) {
    const nb = phoneUtil.parseAndKeepRawInput(input);
    this.value = phoneUtil.format(nb, PhoneNumberFormat.E164);
  }

  getZone() {
    const nb = phoneUtil.parseAndKeepRawInput(this.value);
    const zone = zones.find((z) => z.prefix === nb.getCountryCode());
    if (!zone) throw new Error("Zone not found");
    return zone;
  }

  getLocal() {
    const nb = phoneUtil.parseAndKeepRawInput(this.value);
    const nationalFormat = phoneUtil.format(nb, PhoneNumberFormat.NATIONAL);
    return nationalFormat;
  }

  getDial() {
    const nb = phoneUtil.parseAndKeepRawInput(this.value);
    return phoneUtil.format(nb, PhoneNumberFormat.INTERNATIONAL);
  }

  static parse(value: string) {
    try {
      const nb = phoneUtil.parseAndKeepRawInput(value);
      if (!phoneUtil.isValidNumber(nb)) return null;
      return new InternationalPhoneNumber(
        phoneUtil.format(nb, PhoneNumberFormat.E164)
      );
    } catch (err) {
      return null;
    }
  }
}

export class RawPhoneNumber {
  constructor(private value: string) {}

  getZone() {
    return null;
  }

  getLocal() {
    return this.value;
  }

  getDial() {
    return this.value;
  }
}

function isPhoneNumber(input: string) {
  const international = InternationalPhoneNumber.parse(input);
  if (international) return true;
  const possible = zones.find((z) => {
    try {
      const nb = phoneUtil.parseAndKeepRawInput(input, z.code);
      if (phoneUtil.isPossibleNumber(nb)) return true;
      else return false;
    } catch (err) {
      return false;
    }
  });
  return !!possible;
}

function parse(input: string, zone: PhoneZone | null) {
  if (input === "") return null;
  if (!isPhoneNumber(input)) return null;
  // First, try to detect only on input
  const international = InternationalPhoneNumber.parse(input);
  if (international) return international;
  // Then, try to detect based on zone
  if (zone) {
    const nb = phoneUtil.parseAndKeepRawInput(input, zone.code);
    if (phoneUtil.isValidNumber(nb)) {
      return new InternationalPhoneNumber(
        phoneUtil.format(nb, PhoneNumberFormat.E164)
      );
    } else if (phoneUtil.isPossibleNumber(nb)) {
      return new RawPhoneNumber(
        phoneUtil.format(nb, PhoneNumberFormat.NATIONAL)
      );
    } else {
      return new RawPhoneNumber(input);
    }
  } else {
    // Then, give raw
    return new RawPhoneNumber(input);
  }
}

function getZones() {
  return zones;
}

function getUserZone() {
  const defaultZone: PhoneZone = zones.find((z) => z.code === "FR") || zones[0];
  try {
    const myRegion = new Intl.Locale(navigator.language).region;
    if (myRegion) {
      const zone = get(zones, myRegion) as PhoneZone | undefined;
      if (zone) return zone;
      else return defaultZone;
    }
  } catch (err) {
    return defaultZone;
  }
  return defaultZone;
}

const PhoneNumber = {
  parse,
  getZones,
  getUserZone,
};

export default PhoneNumber;
