/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import dayjs from "dayjs";
import { Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import "reset-css";
import fr from "dayjs/locale/fr";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ToastsView from "./components/ToastsView";
import "./index.css";
import MainRouter from "./MainRouter";
import Api from "./utilities/Api";
import AuthorizationService from "./utilities/AuthorizationService";
import BotApi from "./utilities/BotApi";
import Environment from "./utilities/Environment";
import RootClickBoundary from "./utilities/RootClickBoundary";
import Services, { ServiceList } from "./utilities/Services";

dayjs.extend(localizedFormat);
dayjs.locale(fr);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

declare module "src/utilities/Services" {
  interface ServiceList {
    authorization: AuthorizationService;
    botApi: BotApi;
  }
}

function App() {
  const globalStyles = css`
    * {
      box-sizing: border-box;
    }
    html {
      overscroll-behavior-x: contain;
    }
  `;

  const [services, setServices] = useState<ServiceList | null>(null);

  const loadServices = useCallback(async () => {
    const authorization = new AuthorizationService(
      "chatbot-editor-auth-2",
      Api
    );
    await authorization.init();
    const botApi = new BotApi(
      { url: Environment.findString("REACT_APP_API_ORIGIN") },
      { authorization }
    );
    setServices({
      authorization,
      botApi,
    });
  }, []);

  useEffect(() => {
    loadServices();
  }, []);

  let content: ReactNode = null;
  if (!services) {
    content = null;
  } else {
    content = (
      <RootClickBoundary>
        <Global styles={globalStyles} />
        <ToastsView />
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Services.Provider services={services}>
              <MainRouter />
            </Services.Provider>
          </BrowserRouter>
        </DndProvider>
      </RootClickBoundary>
    );
  }

  return <Fragment>{content}</Fragment>;
}
