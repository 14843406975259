import { css } from "@emotion/react";

const clickableReset = css`
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  color: inherit;
  display: block;
`;

const inputReset = css`
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  color: inherit;
  &:focus {
    outline: none;
  }
`;

const Css = {
  clickableReset,
  inputReset,
};

export default Css;
