/** @jsxImportSource @emotion/react */
import BotDialog from "src/utilities/BotDialog";
import ActionBubble from "src/utilities/BotDialog/ActionBubble";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import ChoicesIntervention from "src/utilities/BotDialog/ChoicesIntervention";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import createRequiredContext from "src/utilities/createRequiredContext";
import { Locale } from "src/utilities/Locales";
import ModelMetadataManager from "src/utilities/ModelMetadataManager";
import {
  ColumnKeyExtractor,
  HeadExtractor,
  ItemKeyExtractor,
  ItemsExtractorFn,
} from "src/utilities/TreeStepper/types";

export class EmptyColumn {
  readonly type = "EmptyColumn";
  id: string;
  dialog: BotDialog;
  private targetId: string | null = null;
  constructor(id: string, dialog: BotDialog) {
    this.id = id;
    this.dialog = dialog;
  }

  /**
   * Item EmptyColumn will continue
   */

  set target(
    entity:
      | EntryPoint
      | MessagesIntervention
      | ChoiceBubble
      | ActionsIntervention
  ) {
    this.targetId = entity.id;
  }

  get target() {
    if (this.targetId === null) throw new Error("EmptyColumn without target");
    const parent = this.dialog.find(this.targetId);
    if (parent instanceof EntryPoint) return parent;
    else if (parent instanceof MessagesIntervention) return parent;
    else if (parent instanceof ChoiceBubble) return parent;
    else if (parent instanceof ActionsIntervention) return parent;
    throw new Error("EmptyColumn with invalid target");
  }

  static after(
    after:
      | EntryPoint
      | MessagesIntervention
      | ChoiceBubble
      | ActionsIntervention,
    dialog: BotDialog
  ) {
    const column = new EmptyColumn(`EmptyColumn[${after.id}]`, dialog);
    column.target = after;
    dialog.register(column);
    return column;
  }
}

export type Column =
  | EntryPoint
  | MessagesIntervention
  | ChoicesIntervention
  | ActionsIntervention
  | EmptyColumn;

export type ColumnMetadata = {
  offset: number;
};

export type Item = EntryPoint | MessageBubble | ChoiceBubble | ActionBubble;

export type ItemMetadata = {
  locale: Locale;
  loading: boolean;
  played: boolean;
};

export const stepKeyExtractor: ItemKeyExtractor<Item> = (bubble) => {
  return bubble.id;
};

export const columnKeyExtractor: ColumnKeyExtractor<Column> = (
  intervention
) => {
  return intervention.id;
};

export const itemsExtractor: ItemsExtractorFn<Column, Item> = (column) => {
  if (column instanceof EmptyColumn) return [];
  else if (column instanceof EntryPoint) return [column];
  else return column.bubbles;
};

export const headExtractor: HeadExtractor<Column> = (
  interaction,
  selectedItem
) => {
  if (selectedItem === null) {
    return null;
  } else if (interaction instanceof MessagesIntervention) {
    return interaction.lastBubble.id;
  } else {
    return selectedItem;
  }
};

export const ItemsMetadataContext =
  createRequiredContext<ModelMetadataManager<ItemMetadata>>();

export type InterventionViewProps = {
  ItemsComponent: React.ComponentType;
};
