import axios, { AxiosInstance } from "axios";
import AuthorizationService from "../AuthorizationService";
import { Language, Locale } from "../Locales";
import {
  default as BotApiTypes,
  default as ChatbotApiTypes,
} from "./BotApiTypes";

type BotApiConfig = {
  url: string;
};

type BotApiDeps = {
  authorization: AuthorizationService;
};

export default class BotApi {
  private axios: AxiosInstance;

  constructor(readonly config: BotApiConfig, private deps: BotApiDeps) {
    this.axios = axios.create({
      baseURL: config.url,
    });
    this.axios.interceptors.request.use(
      async (config) => {
        const token = await this.deps.authorization.getAccessToken();
        if (token) {
          if (!config.headers) config.headers = {};
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (err) => Promise.reject(err)
    );
  }

  // Bots

  async loadBots() {
    const output = await this.axios.get<Array<BotApiTypes.Bot>>(
      `/scripts/api/scripts/`
    );
    return output.data;
  }

  async createBot(name: string) {
    const output = await this.axios.post<BotApiTypes.Bot>(
      `/scripts/cbe/api/script/`,
      { name }
    );
    return output.data;
  }

  async getBot(uuid: string) {
    const result = await this.axios.get<BotApiTypes.Bot>(
      `/scripts/cbe/api/script/${uuid}/`
    );
    return result.data;
  }

  async updateBot(
    uuid: string,
    params: Partial<{ name: string; other_locales: Array<string> }> = {}
  ) {
    const result = await this.axios.patch<BotApiTypes.Bot>(
      `/scripts/cbe/api/script/${uuid}/`,
      params
    );
    return result.data;
  }

  async updateBotName(uuid: string, name: string) {
    await this.axios.patch<BotApiTypes.Bot>(
      `/scripts/cbe/api/script/${uuid}/`,
      {
        name,
      }
    );
  }

  async deleteBot(uuid: string) {
    await this.axios.delete<void>(`/scripts/cbe/api/script/${uuid}/`);
  }

  // Script

  async loadRawSteps(uuid: string, locale: string) {
    const output = await this.axios.get<Array<BotApiTypes.LocalizedRawStep>>(
      `/scripts/cbe/api/script/raw/${uuid}/${locale}/`
    );
    const data = output.data;
    return data;
  }

  // Steps

  async createStep(
    uuid: string,
    parentId: number,
    locale: string,
    kind: string,
    label: string,
    action: BotApiTypes.StepAction | null,
    action_args: string | null,
    sibling: boolean
  ) {
    const result = await this.axios.post<ChatbotApiTypes.StepCreation>(
      `/scripts/cbe/api/script/${uuid}/${parentId}/`,
      {
        kind,
        label: label,
        action: action || "",
        action_args: action_args || null,
        sibling,
      },
      { headers: { "Accept-language": locale } }
    );
    return result.data;
  }

  async createEmptyStep(
    uuid: string,
    parentId: number,
    locale: string,
    kind: string,
    siblings: boolean
  ) {
    return this.createStep(
      uuid,
      parentId,
      locale,
      kind,
      "",
      null,
      null,
      siblings
    );
  }

  async createEmptyActionStep(
    uuid: string,
    parentId: number,
    locale: string,
    action: BotApiTypes.StepAction,
    args: string | null,
    siblings: boolean
  ) {
    return this.createStep(
      uuid,
      parentId,
      locale,
      "AA",
      "",
      action,
      args,
      siblings
    );
  }

  async moveStep(scriptUuid: string, stepToMove: number, newParentId: number) {
    const output = await this.axios.post<"moved">(
      `/scripts/cbe/api/script/${scriptUuid}/move/${stepToMove}/${newParentId}`,
      null
    );
    return output.data;
  }

  async updateStep(
    scriptUuid: string,
    stepId: number,
    locale: string,
    data: Partial<{
      label: string;
      targetable: boolean;
      kind: BotApiTypes.StepKind;
      action: string | null;
      action_args: string | null;
    }>
  ) {
    const result = await this.axios.patch<ChatbotApiTypes.StepUpdate>(
      `/scripts/cbe/api/script/${scriptUuid}/${stepId}/`,
      data,
      { headers: { "Accept-language": locale } }
    );
    return result.data;
  }

  async updateActionArgs(scriptUuid: string, stepId: number, value: string) {
    const result = await this.axios.patch<ChatbotApiTypes.StepUpdate>(
      `/scripts/cbe/api/script/${scriptUuid}/${stepId}/`,
      { action_args: value }
    );
    return result.data;
  }

  // async updateTargetable(
  //   scriptUuid: string,
  //   stepId: number,
  //   targetable: boolean
  // ) {
  //   const result = await this.axios.patch<ChatbotApiTypes.StepUpdate>(
  //     `/scripts/cbe/api/script/${scriptUuid}/${stepId}/`,
  //     { targetable }
  //   );
  //   return result.data;
  // }

  // async updateLabelAndActionArgs(
  //   scriptUuid: string,
  //   stepId: number,
  //   value: string,
  //   locale: string
  // ) {
  //   const result = await this.axios.patch<BotApiTypes.StepUpdate>(
  //     `/scripts/cbe/api/script/${scriptUuid}/${stepId}/`,
  //     { label: value, action_args: value },
  //     { headers: { "Accept-language": locale } }
  //   );
  //   return result.data;
  // }

  async sortSteps(
    scriptUuid: string,
    parentId: number,
    childrenIds: Array<number>
  ) {
    const response = await this.axios.post<Array<number>>(
      `/scripts/cbe/api/script/${scriptUuid}/sort/${parentId}/`,
      { sort: childrenIds }
    );

    return response.data;
  }

  async deleteStep(scriptUuid: string, stepId: number, dropChildren: boolean) {
    const data = dropChildren ? { delete: "full" } : {};
    const response = await this.axios.delete<"deleted">(
      `/scripts/cbe/api/script/${scriptUuid}/${stepId}/`,
      { data }
    );
    return response.data;
  }

  // Releases
  async getReleases(scriptUuid: string) {
    try {
      const result = await this.axios.get<Array<BotApiTypes.Release>>(
        `/scripts/cbe/api/script/${scriptUuid}/save`
      );
      return result.data;
    } catch (err) {
      return [];
    }
  }

  async createRelease(scriptUuid: string, name: string, published: boolean) {
    const result = await this.axios.post<string>(
      `/scripts/cbe/api/script/${scriptUuid}/save/`,
      { name, published }
    );
    return result.data;
  }

  async updatePublication(
    publicationId: string,
    updates: { name?: string; published?: boolean }
  ) {
    const result = await this.axios.patch<void>(
      `/scripts/cbe/api/script/${publicationId}/save/`,
      updates
    );
    return result.data;
  }

  async getPublicationTaskResult(releaseTaskId: string) {
    const result = await this.axios.get<"Running" | "true" | "false">(
      `/scripts/cbe/api/script/${releaseTaskId}/save/result`
    );
    return result.data;
  }

  // Bundles
  async getBundle(script: string, locale: Locale) {
    const response = await this.axios.get<any>(
      `/scripts/api/script/published/${script}/${locale}/`
    );
    return response.data;
  }

  // Translation

  async translate(text: string, from: Language, to: Language) {
    const result = await this.axios.post<string>(`/scripts/translate/api/`, {
      src_lang: from,
      dst_lang: to,
      text,
    });
    return result.data;
  }

  // Translation

  async checkLink(link: string): Promise<BotApiTypes.LinkStatus> {
    const result = await this.axios.post<BotApiTypes.LinkStatus>(
      `/scripts/api/tools/url/`,
      {
        url: link,
      }
    );
    return result.data;
  }
}
