/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Clickable, { ClickableProps } from "../Clickable";
import Icon from "../Icon";
import NoeseIcons from "../Icon/NoeseIcons/NoeseIcons.generated";
import Spacer from "../Spacer";
import Typo from "../Typo";

type PursuitButtonProps = ClickableProps & {
  icon: NoeseIcons;
  label: string;
  sublabel?: string;
};

export default function PursuitButton(props: PursuitButtonProps) {
  const { icon, label, sublabel, ...clickableProps } = props;

  const containerCss = css({
    background: "white",
    padding: 10,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    opacity: 0.7,
    transition: "all 200ms",
    "&:hover": {
      opacity: 1,
    },
  });

  return (
    <Clickable {...clickableProps} containerCss={containerCss}>
      <Icon name={icon} typo="bold" scale={1.5} square />
      <Spacer size={10} />
      <div>
        <Typo name="bold">{label}</Typo>
        {sublabel ? <Typo name="body">{sublabel}</Typo> : null}
      </div>
    </Clickable>
  );
}
