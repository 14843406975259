// Player config

import { FetchBundleFn, OnStepConsumedFn } from "src/player/types";
import Updater from "src/utilities/Updater";

type ChatbotSimulatorState = {
  fetchBundleFn: FetchBundleFn;
  onStepConsumed?: OnStepConsumedFn;
  startLocale: string;
  startAt?: number;
};

let state: ChatbotSimulatorState | null = null;
const playerUpdater = new Updater();

export function set(s: ChatbotSimulatorState | null) {
  state = s;
  playerUpdater.update();
}

export function use() {
  return playerUpdater.useValue(() => state, []);
}

const ChatbotSimulatorController = {
  set,
  use,
};

export default ChatbotSimulatorController;
