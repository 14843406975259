import { Fragment } from "react";
import ErrorZone from "src/components/ErrorZone";
import LoadingView from "src/components/LoadingView";
import DelayedView, { DelayedViewProps } from "./DelayedView";

export default function DelayedZone<TValue>(
  props: Pick<DelayedViewProps<TValue>, "promise" | "children">
) {
  const { promise, children } = props;

  return (
    <DelayedView
      promise={promise}
      renderIdling={() => <Fragment />}
      renderPending={() => <LoadingView />}
      renderRejected={(err) => <ErrorZone error={err} />}
    >
      {children}
    </DelayedView>
  );
}
