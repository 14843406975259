import Routing, { Route, RouteKey } from "./Routing";

const App: Route = {
  pattern: "/",
  parent: null,
};

const ChatbotHome: Route = {
  pattern: "chatbots/:chatbot",
  parent: App,
};

const ChatbotEdition: Route = {
  pattern: "edition",
  parent: ChatbotHome,
};

const ChatbotTranslation: Route = {
  pattern: "translation",
  parent: ChatbotHome,
};

const ChatbotTranslationLocale: Route = {
  pattern: ":locale",
  parent: ChatbotTranslation,
};

const ChatbotSettings: Route = {
  pattern: "settings",
  parent: ChatbotHome,
};

const ChatbotQuality: Route = {
  pattern: "quality",
  parent: ChatbotHome,
};

const ChatbotRelease: Route = {
  pattern: "release",
  parent: ChatbotHome,
};

const ChatbotHealth: Route = {
  pattern: "health",
  parent: ChatbotHome,
};

const routes = {
  App,
  ChatbotHome,
  ChatbotEdition,
  ChatbotTranslation,
  ChatbotTranslationLocale,
  ChatbotSettings,
  ChatbotQuality,
  ChatbotRelease,
  ChatbotHealth,
};

const Navigation = new Routing(routes);

export type ZoneName = RouteKey<typeof Navigation>;

export default Navigation;
