import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import Navigation from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import BotErrorView from "./BotErrorView";
import { EmptyScript } from "./types";

type EmptyScriptViewProps = {
  error: EmptyScript;
};

export default function EmptyScriptView(props: EmptyScriptViewProps) {
  return (
    <BotErrorView
      type="Bot vide"
      icon="sleep"
      description="Le bot ne fait rien. Veillez à ce qu'il commence la conversation en
    envoyant un message de bienvenue à l'utilisateur."
      solutions={
        <Buttons>
          <Button2
            label="Corriger"
            to={Navigation.getPath("ChatbotEdition")}
            color={Theme.colors.blue}
            layout="solid"
          />
        </Buttons>
      }
    ></BotErrorView>
  );
}
