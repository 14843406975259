/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BotManagerContext } from "src/utilities/BotManager";
import { default as Navigation } from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import Button2 from "./Button2";
import Clickable from "./Clickable";
import Spacer from "./Spacer";
import Typo from "./Typo";

export default function ChatbotEditorBar() {
  const manager = BotManagerContext.use();

  const { name } = manager.useSelector(
    () => ({
      name: manager.bot.name,
    }),
    []
  );

  const containerCss = css({
    height: 74,
    display: "flex",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
    background: Theme.colors.lightblue,
    alignItems: "center",
  });

  const scriptZoneCss = css`
    flex-grow: 1;
    display: flex;
    align-items: center;
  `;

  const tabsCss = css({
    height: 40,
    display: "flex",
    backgroundColor: Theme.colors.blue,
    borderRadius: 20,
    alignItems: "center",
    paddingInline: 3,
  });

  const tabCss = css(
    {
      height: 34,
      paddingInline: 34 / 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      borderRadius: 34 / 2,
      color: "white",
    },
    Typos.body
  );

  const selectedTabCss = css(tabCss, Typos.bold, {
    backgroundColor: Theme.colors.teal,
  });

  const edition = Navigation.useIsUnderRoute("ChatbotEdition");
  const translation = Navigation.useIsUnderRoute("ChatbotTranslation");
  const health = Navigation.useIsUnderRoute("ChatbotHealth");
  const release = Navigation.useIsUnderRoute("ChatbotRelease");
  const settings = Navigation.useIsUnderRoute("ChatbotSettings");

  return (
    <div css={containerCss}>
      <div css={scriptZoneCss}>
        <Spacer size={40} />

        <div
          onClick={() => {
            //@ts-ignore
            window.revealAutoHeightInput();
          }}
        >
          <Typo name="heading" color={Theme.colors.blue}>
            {name}
          </Typo>
        </div>
        <Spacer size={40} />
        <Button2
          color={Theme.colors.teal}
          label="Retour À Votre Liste De Bot"
          layout="solid"
          to={Navigation.getPath("App")}
        />
      </div>
      <div css={tabsCss}>
        <Clickable
          to={Navigation.getPath("ChatbotEdition")}
          containerCss={edition ? selectedTabCss : tabCss}
        >
          <Typo name={edition ? "bold" : "body"}>Concevoir</Typo>
        </Clickable>
        <Clickable
          to={Navigation.getPath("ChatbotTranslation")}
          containerCss={translation ? selectedTabCss : tabCss}
        >
          <Typo name={translation ? "bold" : "body"}>Traduire</Typo>
        </Clickable>
        <Clickable
          to={Navigation.getPath("ChatbotHealth")}
          containerCss={health ? selectedTabCss : tabCss}
        >
          <Typo name={health ? "bold" : "body"}>Améliorer</Typo>
        </Clickable>
        <Clickable
          to={Navigation.getPath("ChatbotRelease")}
          containerCss={release ? selectedTabCss : tabCss}
        >
          <Typo name={release ? "bold" : "body"}>Mettre en ligne</Typo>
        </Clickable>
        <Clickable
          to={Navigation.getPath("ChatbotSettings")}
          containerCss={settings ? selectedTabCss : tabCss}
        >
          <Typo name={settings ? "bold" : "body"}>Réglages</Typo>
        </Clickable>
      </div>
      <Spacer size={40} />
    </div>
  );
}
