/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, ReactNode, useCallback } from "react";
import Clickable, { ClickableProps } from "src/components/Clickable";
import Icon from "src/components/Icon";
import Typo from "src/components/Typo";
import {
  border,
  depthBlockPadding,
  depthInlinePadding,
  FilesHover,
  FilesSelection,
  useTranslationController,
} from "../utils";
import Depth from "./Depth";

type StructureProps = {
  stroke: ReactNode;
  toggler?: boolean;
  activeColor: string;
  icon: ReactNode;
  value: string;
} & ClickableProps;

export default function Structure(props: StructureProps) {
  const {
    value,
    stroke,
    activeColor,
    toggler = false,
    icon,
    ...clickable
  } = props;

  const controller = useTranslationController();

  const { fileKey, unfolded, isFirst, isLast } = controller.useFileSelector(
    (c, key) => {
      return {
        fileKey: key,
        bubble: c.findFileData(key),
        unfolded: c.isFileUnfolded(key),
        isFirst: c.isFirst(key),
        isLast: c.isLast(key),
      };
    },
    []
  );

  const hover = FilesHover.use(fileKey);
  const selected = FilesSelection.use(fileKey);

  const whiteBackground = rgba("white", selected ? 1 : 0);
  const background = rgba(activeColor, selected ? 0.1 : 0);
  const borderColor = rgba(activeColor, selected ? 1 : hover ? 0.5 : 0);

  const borderBottom = selected ? border(activeColor) : border();

  const leftCss = css({
    display: "flex",
    alignItems: "stretch",
    position: "relative",
    zIndex: 1,
    flexGrow: 1,
    background: "#FAFAFA",
    borderBottomLeftRadius: isLast ? 6 : 0,
    borderTop: isFirst ? border() : "none",
    borderLeft: border(),
    borderBottom: isLast ? borderBottom : "none",
  });

  const togglerCss = css({
    paddingBlock: depthBlockPadding,
    paddingInline: depthInlinePadding,
    opacity: 0.2,
    alignSelf: "flex-start",
  });

  const contentCss = css({
    flexGrow: 1,
    borderBottom: isLast ? "none" : borderBottom,
    display: "flex",
    paddingBlock: 4,
    background: rgba(activeColor, selected ? 0.2 : hover ? 0.1 : 0),
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  });

  const inputLikeCss = css({
    borderRadius: 4,
    display: "flex",
    flexGrow: 1,
    transition: "all 200ms",
    alignItems: "flex-start",
    paddingBlock: 6,
    paddingInline: 4,
    marginRight: 4,
  });

  const iconCss = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  });

  const sourceTextCss = css({
    borderRadius: 4,
    paddingRight: 6,
    color: activeColor,
  });

  // const contentCss = css({
  //   background,
  //   border: `1px solid ${borderColor}`,
  //   borderRadius: 4,
  //   display: "flex",
  //   flexGrow: 1,
  //   transition: "all 200ms",
  //   alignItems: "flex-start",
  // })

  const wrapperCss = css({
    backgroundColor: whiteBackground,
    display: "flex",
    flexGrow: 1,
    alignSelf: "stretch",
    transition: "all 200ms",
  });

  const sourceCss = css({
    flexGrow: 1,
    display: "flex",
    paddingBlock: 5,
    borderBottom: `1px solid ${rgba("black", 0.05)}`,
  });

  const onMouseEnter = useCallback(() => {
    FilesHover.select(fileKey);
  }, [fileKey]);

  const onToggle = useCallback(() => {
    controller.setUnfolded(fileKey, !unfolded);
  }, [fileKey, unfolded]);

  return (
    <Clickable
      containerCss={leftCss}
      {...clickable}
      onMouseEnter={onMouseEnter}
    >
      <Fragment>
        <Depth />
        {stroke}
        <Clickable
          containerCss={togglerCss}
          onClick={onToggle}
          disabled={!toggler}
        >
          <Icon
            name={toggler ? "tree-chevron" : "tree-circle"}
            typo="body"
            rotate={unfolded ? "90deg" : "0deg"}
          />
        </Clickable>
        <div css={contentCss}>
          <div css={inputLikeCss}>
            <div css={iconCss}>{icon}</div>
            <div css={sourceTextCss}>
              <Typo>{value}</Typo>
            </div>
          </div>
        </div>
      </Fragment>
    </Clickable>
  );
}
