/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "animate.css";
import { useCallback, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Clickable from "src/components/Clickable";
import Divider from "src/components/Divider";
import Icon from "src/components/Icon";
import Droper from "src/components/Nodes/Fragments/Droper";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import NodeInput from "src/components/Nodes/NodeInput";
import StepSuggestions from "src/components/Nodes/StepView/StepSuggestions";
import styles from "src/components/Nodes/styles";
import RelativeContainer from "src/components/RelativeContainer";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import useMemoState from "src/utilities/useMemoState";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function ChoiceBubbleView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { itemKey, selected, selectedItem, bubble, bubbleId, columnKey } =
    controller.useItemSelector((c, itemKey, columnKey) => ({
      itemKey,
      bubbleId: itemKey,
      columnKey,
      bubble: c.findItemData(itemKey) as ChoiceBubble,
      selectedItem: c.getSelectedItem(columnKey),
      selected: c.isItemSelected(itemKey),
    }));

  const { locale } = itemsMetadata.use(itemKey);

  const select = useCallback(() => {
    controller.selectItem(bubbleId);
  }, [bubbleId]);

  const weight = bubble.getWeight() - 1;

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeChoiceBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, [bubble]);

  const containerCss = css({
    color: "white",
    borderRadius: 10,
    background: Theme.colors.purple,
  });

  const weightCss = css({
    position: "absolute",
    top: "50%",
    right: 0,
    transform: `translate3d(100%, -50%, 0px) scale(${
      selectedItem === null ? 1 : 0
    })`,
    display: "flex",
    alignItems: "center",
    color: Theme.colors.purple,
    transition: "all 200ms",
    opacity: selectedItem === null ? 1 : 0,
    pointerEvents: selectedItem === null ? "all" : "none",
    "&:hover": {
      right: -10,
    },
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: columnKey,
    item: { itemKey: bubbleId },
  });

  const [{ dropVisible, dropOver }, dropRef] = useDrop(
    {
      accept: columnKey,
      collect: (monitor) => {
        return {
          dropVisible: monitor.canDrop(),
          dropOver: monitor.isOver(),
        };
      },
      drop: (drop: { itemKey: string }) => {
        const movedBubble = controller.findItemData(
          drop.itemKey
        ) as ChoiceBubble;
        const exec = manager.moveChoiceBubble(movedBubble, bubble);
        feedback(controller, exec);
        feedbackItem(itemsMetadata, exec, movedBubble.id);
      },
    },
    []
  );

  const label = bubble.getLabel(locale);
  const [labelValue, setLabelValue] = useMemoState<string>(
    () => label,
    [label]
  );

  const saveLabel = useCallback(
    (text: string) => {
      const execution = manager.updateLabel(bubble, locale, text);
      feedback(controller, execution);
      feedbackItem(itemsMetadata, execution, bubbleId);
    },
    [bubble, locale]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setLabelValue(e.target.value);
      saveLabel(e.target.value);
    },
    [saveLabel]
  );

  return (
    <RelativeContainer>
      <FromPreviousColumnToItemLine selected={selected} virtual={false} />
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon="open-in-new"
              targetControl={true}
              title={"Proposition"}
              dragRef={dragRef}
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={styles.content}>
              <NodeInput
                value={labelValue}
                onChange={setLabelValue}
                onBlur={onBlur}
                emojis
              />
            </div>
            <Spacer size={4} />
          </div>
          <Revealable revealed={selected} onHeightStartChange={setFooterHeight}>
            <StepSuggestions />
            <NodeFooter translationControl={true} />
          </Revealable>
        </div>
      </AllStepsView>
      <Droper
        dropRef={dropRef}
        visible={dropVisible}
        over={dropOver}
        lineColor={Theme.colors.purple}
      />
      <Spacer size={10} />
      <Clickable containerCss={weightCss} onClick={select}>
        <Spacer size={8} />
        <Divider color={Theme.colors.purple} size={10} />
        <Spacer size={6} />
        <Typo>{weight}</Typo>
        <Spacer size={6} />
        <Icon name="layers-triple-outline" typo="body" />
      </Clickable>
    </RelativeContainer>
  );
}
