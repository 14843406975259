/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import ChoicesIntervention from "src/utilities/BotDialog/ChoicesIntervention";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import ChatbotLines from "src/utilities/ChatbotLines";
import Stroke2 from "src/utilities/Stroke2";
import { useChatbotTreeStepperController } from "..";

type FromPreviousColumnToItemLineProps = {
  selected: boolean;
  virtual: boolean;
};

export default function FromPreviousColumnToColumnLine(
  props: FromPreviousColumnToItemLineProps
) {
  const { selected, virtual } = props;

  const controller = useChatbotTreeStepperController();

  const { column, bubbleTop, targetTop, targetHeight } =
    controller.useColumnSelector((c, columnKey) => {
      const column = c.findColumnData(columnKey);
      const previousColumnKey = c.findPreviousColumn(columnKey);
      const previousColumn = c.findColumnData(previousColumnKey);

      let targetId: string | null;

      if (previousColumn instanceof ChoicesIntervention) {
        targetId = c.getSelectedItem(previousColumnKey);
      } else if (previousColumn instanceof MessagesIntervention) {
        targetId = previousColumn.lastBubble.id;
      } else if (previousColumn instanceof EntryPoint) {
        targetId = previousColumn.id;
      } else if (previousColumn instanceof ActionsIntervention) {
        targetId = previousColumn.lastBubble.id;
      } else {
        throw new Error("Cannot determine line target");
      }

      const bubbleTop = c.findColumnTop(columnKey);
      const targetTop = targetId ? c.findItemTop(targetId) : null;
      const targetHeight = targetId ? c.findItemHeight(targetId) : null;

      return {
        column,
        bubbleTop,
        targetTop,
        targetHeight,
      };
    }, []);

  if (targetTop === null || targetHeight === null) return <Fragment />;

  const endX = ChatbotLines.useLinesWidth(column) * -1;

  const endY = targetTop - bubbleTop + targetHeight / 2 - 20;

  return (
    <Stroke2
      x={endX}
      y={endY}
      css={css({ top: 20 })}
      {...ChatbotLines.getChatbotStrokeProps(selected, virtual)}
    />
  );
}
