/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect } from "react";
import Theme from "src/utilities/Theme";
import Toasts, { Toast } from "src/utilities/Toasts";
import Button from "./Button";
import Typo from "./Typo";

export default function ToastsView() {
  const toasts = Toasts.useList();

  const containerCss = css`
    position: fixed;
    z-index: 3000;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  `;

  return (
    <div css={containerCss}>
      {toasts.map((t) => (
        <ToastView toast={t} key={t.key} />
      ))}
    </div>
  );
}

type ToastViewProps = {
  toast: Toast;
};

function ToastView(props: ToastViewProps) {
  const { toast } = props;
  const { id, type, dismiss } = toast;

  let color = Theme.colors.teal;
  if (type === "error") color = Theme.colors.red;
  else if (type === "success") color = Theme.colors.teal;

  const containerCss = css`
    margin: 10px;
    padding: 6px 8px;
    border-radius: 8px;
    border: 2px solid ${color};
    min-width: 200px;
    max-width: 400px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  `;

  useEffect(() => {
    if (typeof dismiss === "number") {
      const t = setTimeout(() => {
        Toasts.hide(id);
      }, dismiss);
      return () => {
        clearTimeout(t);
      };
    }
  }, [dismiss]);

  const bottomCss = css`
    display: flex;
    justify-content: flex-end;
  `;

  const onClick = useCallback(() => {
    Toasts.hide(toast.id);
  }, [toast]);

  return (
    <div css={containerCss}>
      <Typo>{toast.title}</Typo>
      <Typo name="minor">{toast.message}</Typo>
      {dismiss === "manual" ? (
        <div css={bottomCss}>
          <Button label="Ok" color={color} onClick={onClick} />
        </div>
      ) : null}
    </div>
  );
}
