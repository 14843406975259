/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMemo } from "react";
import Icon from "src/components/Icon";
import Intersperse from "src/components/Intersperse";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import useNow from "src/useNow";
import { BotManagerContext } from "src/utilities/BotManager";
import DelayedZone from "src/utilities/DelayedZone";
import DeadPathView from "./errors/DeadPathView";
import EmptyDeliveryView from "./errors/EmptyDeliveryView";
import EmptyScriptView from "./errors/EmptyScriptView";
import InvalidFormatView from "./errors/InvalidFormat";
import InvalidRedirectionView from "./errors/InvalidRedirectionView";
import MissingTranslationView from "./errors/MissingTranslationsView";
import {
  BotError,
  DeadPath,
  EmptyDelivery,
  EmptyScript,
  InvalidFormat,
  InvalidRedirection,
  MissingTranslation,
} from "./errors/types";

export default function ChatbotHealt() {
  const manager = BotManagerContext.use();

  const localesLoading = useMemo(() => {
    return Promise.all(
      manager.bot.other_locales.map((l) => manager.loadTranslations(l))
    );
  }, []);

  const [now, setNow] = useNow();

  const errors = useMemo(async () => {
    await localesLoading;
    const errors = manager.dialog.getErrors();
    return errors;
  }, [localesLoading, now]);

  const containerCss = css({
    width: "100%",
    maxWidth: 1000,
    marginInline: "auto",
  });

  return (
    <DelayedZone promise={errors}>
      {(errors) => {
        return (
          <div css={containerCss}>
            <Spacer scale={5} />
            <BotErrorList errors={errors} onFixed={setNow} />
          </div>
        );
      }}
    </DelayedZone>
  );
}

type BotErrorListProps = {
  errors: Array<BotError>;
  onFixed: () => any;
};

function BotErrorList(props: BotErrorListProps) {
  const { errors, onFixed } = props;

  const sliderCss = css({
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    flexWrap: "nowrap",
    "& > *": {
      flexShrink: 0,
    },
  });

  return (
    <div>
      <Typo name="heading">
        <Icon name="alert-circle" typo="heading" /> {errors.length} problèmes à
        corriger pour mettre le bot en ligne
      </Typo>
      <div css={sliderCss}>
        <Intersperse between={() => <Spacer scale={2} />}>
          {errors.map((e) => {
            if (e instanceof EmptyScript) return <EmptyScriptView error={e} />;
            if (e instanceof DeadPath)
              return <DeadPathView error={e} onFixed={onFixed} />;
            if (e instanceof EmptyDelivery)
              return <EmptyDeliveryView error={e} />;
            if (e instanceof MissingTranslation)
              return <MissingTranslationView error={e} />;
            if (e instanceof InvalidFormat)
              return <InvalidFormatView error={e} />;
            if (e instanceof InvalidRedirection)
              return <InvalidRedirectionView error={e} onFixed={onFixed} />;
          })}
        </Intersperse>
      </div>
    </div>
  );
}
