/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import React, { useCallback } from "react";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import Clickable from "../Clickable";
import Icon from "../Icon";
import { inputCss } from "./styles";

type TextFieldProps = {
  label: string;
  value: string;
  onChange: (s: string) => any;
};

export default function PasswordField(props: TextFieldProps) {
  const { label, value, onChange } = props;

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const containerCss = css({
    backgroundColor: Theme.colors.white,
    height: 48,
    borderRadius: 48 / 2,
    width: "100%",
    border: `1px solid white`,
    transition: "all 200ms",
    color: Theme.colors.darkblue,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    paddingRight: 48 / 2,
    "&:focus": {
      border: `1px solid ${rgba(Theme.colors.blue, 0.4)}`,
      outline: "none",
    },
  });

  const [revealed, setRevealed] = useBooleanState(false);

  return (
    <div css={containerCss}>
      <input
        type={revealed ? "text" : "password"}
        value={value}
        onChange={onInputChange}
        css={inputCss}
        placeholder={label}
      />
      <Clickable onClick={setRevealed.toggle}>
        <Icon
          name="eye"
          color={Theme.colors.darkblue}
          typo="body"
          scale={1.2}
        />
      </Clickable>
    </div>
  );
}
