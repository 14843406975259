import { BotPlayerTypes } from "src/player/types";
import { DraftBot } from "../BotAdapters";
import BotApiTypes from "../BotApi/BotApiTypes";
import { Locale } from "../Locales";
import ActionsIntervention from "./ActionsIntervention";
import Bubble from "./Bubble";

export default abstract class ActionBubble extends Bubble {
  // Action args

  private actionArgs: string | null = null;

  setActionArgs(actionArgs: string | null) {
    this.actionArgs = actionArgs || null;
  }

  getActionArgs() {
    return this.actionArgs;
  }

  // Ending

  abstract isEnding(): boolean;

  // Intervention

  private interventionId: string | null = null;

  set intervention(intervention: ActionsIntervention) {
    this.interventionId = intervention.id;
  }

  get intervention() {
    if (this.interventionId === null)
      throw new Error("ActionBubble without interventionId");
    const intervention = this.dialog.find(this.interventionId);
    if (intervention instanceof ActionsIntervention) return intervention;
    throw new Error("Invalid ActionsIntervention intervention");
  }

  destroy() {
    this.intervention.removeBubble(this);
    this.interventionId = null;
    this.unregister();
  }

  // Position in intervention

  get isFirst() {
    return this.intervention.bubbles[0] === this;
  }

  get isLast(): boolean {
    return this.intervention.lastBubble === this;
  }

  get index() {
    const bubbles = this.intervention.bubbles;
    const index = bubbles.indexOf(this);
    return index;
  }

  get previous(): ActionBubble | null {
    const index = this.index;
    if (index <= 0) return null;
    return this.intervention.bubbles[index - 1];
  }

  get next(): ActionBubble | null {
    const index = this.index;
    if (index >= this.intervention.bubbles.length - 1) return null;
    return this.intervention.bubbles[index + 1];
  }

  // Registration

  isRegistrable(): boolean {
    if (!this.interventionId) return false;
    return true;
  }

  isUnregistrable(): boolean {
    if (this.interventionId !== null) return false;
    return true;
  }

  // Weight

  getWeight(tracker: number = 0): number {
    let weight = tracker;
    weight += 1;
    if (this.next) {
      weight += this.next.getWeight(tracker);
    } else if (this.isLast) {
      if (this.intervention.ahead) {
        weight += this.intervention.ahead.getWeight(tracker);
      }
    }
    return weight;
  }

  // Kind and action

  getKind() {
    return "AA" as const;
  }

  abstract getAction(): BotApiTypes.StepAction;

  toDraftSteps(): Array<DraftBot.Step> {
    let children: Array<DraftBot.Step> = [];
    if (this.next) {
      children = this.next.toDraftSteps();
    } else if (this.intervention.ahead) {
      children = this.intervention.ahead.toDraftSteps();
    }
    return [
      {
        id: this.findRemoteId(),
        data: {
          kind: this.getKind(),
          action: this.getAction(),
          action_args: this.getActionArgs(),
          label: this.getReleaseLabel(),
          name: this.findName(),
          targetable: this.isTargetable(),
        },
        children,
      },
    ];
  }

  // Export to bundle
  toBundleSteps(locale: Locale): Array<BotPlayerTypes.Step> {
    let children: Array<BotPlayerTypes.Step> = [];
    if (this.next) {
      children = this.next.toBundleSteps(locale);
    } else if (this.intervention.ahead) {
      children = this.intervention.ahead.toBundleSteps(locale);
    }
    return [
      {
        id: this.findRemoteId(),
        data: {
          kind: this.getKind(),
          action: this.getAction(),
          action_args: this.getActionArgs(),
          name: this.getLabel(locale),
        },
        children,
      },
    ];
  }
}
