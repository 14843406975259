/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React, { PropsWithChildren, useCallback } from "react";
import { Link, To } from "react-router-dom";
import Css from "src/utilities/Css";
import PropsWithCss from "src/utilities/PropsWithCss";

type TargetProps = (
  | { submit: true }
  | { onClick: (e: React.MouseEvent) => void; stopPropagation?: boolean }
  | { href: string; target?: string }
  | { to: To; replace?: boolean; state?: any }
  | {}
) & { disabled?: boolean } & Pick<
    React.HTMLAttributes<HTMLElement>,
    "onMouseEnter"
  >;

export type ClickableProps = PropsWithCss<
  PropsWithChildren<TargetProps & { containerCss?: SerializedStyles }>
>;

export default function Clickable(props: ClickableProps) {
  const { disabled } = props;
  const passedProps = {
    onMouseEnter: props.onMouseEnter,
    className: props.className,
  };

  const clickable = isClickable(props);

  const allCss = css(
    Css.clickableReset,
    clickable ? { cursor: "pointer" } : { cursor: "default" },
    props.containerCss
  );

  const clickListenner = useCallback(
    (e: React.MouseEvent) => {
      if (!("onClick" in props)) return;
      if (props.stopPropagation) e.stopPropagation();
      props.onClick(e);
    },
    [
      "onClick" in props && props.onClick,
      "onClick" in props && props.stopPropagation,
    ]
  );

  if (disabled) {
    return (
      <div css={allCss} {...passedProps}>
        {props.children}
      </div>
    );
  } else {
    if ("submit" in props) {
      return (
        <button css={allCss} type="submit" disabled={disabled} {...passedProps}>
          {props.children}
        </button>
      );
    } else if ("onClick" in props) {
      return (
        <button
          type="button"
          css={allCss}
          onClick={clickListenner}
          disabled={disabled}
          {...passedProps}
        >
          {props.children}
        </button>
      );
    } else if ("href" in props) {
      return (
        <a
          css={allCss}
          href={props.href}
          target={props.target}
          {...passedProps}
        >
          {props.children}
        </a>
      );
    } else if ("to" in props) {
      return (
        <Link
          to={props.to}
          state={props.state}
          replace={props.replace}
          css={allCss}
          {...passedProps}
        >
          {props.children}
        </Link>
      );
    } else {
      return (
        <div css={allCss} {...passedProps}>
          {props.children}
        </div>
      );
    }
  }
}

export function isClickable(props: ClickableProps) {
  if (props.disabled) return false;
  if (
    !("submit" in props && props.submit === true) &&
    !("onClick" in props && props.onClick !== undefined) &&
    !("href" in props) &&
    !("to" in props)
  )
    return false;
  return true;
}
