/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import {
  feedback,
  feedbackItem,
} from "src/components/ChatbotEditorStepper/feedbacks";
import ChatbotSimulatorController from "src/components/ChatbotSimulator/ChatbotSimulatorController";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale } from "src/utilities/Locales";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import useMemoState from "src/utilities/useMemoState";
import Droper from "../../Nodes/Fragments/Droper";
import NodeFooter from "../../Nodes/NodeFooter";
import NodeHeader from "../../Nodes/NodeHeader";
import NodeInput from "../../Nodes/NodeInput";
import StepSuggestions from "../../Nodes/StepView/StepSuggestions";
import styles from "../../Nodes/styles";
import { ItemsMetadataContext } from "../config";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function MessageBubbleView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { itemId, bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as MessageBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const { locale } = itemsMetadata.use(itemId);

  const containerCss = css({
    color: "white",
    borderRadius: 10,
    background: Theme.colors.teal,
  });

  const label = bubble.getLabel(locale);
  const [labelValue, setLabelValue] = useMemoState<string>(
    () => label,
    [label]
  );

  const saveLabel = useCallback(
    (text: string) => {
      const execution = manager.updateLabel(bubble, locale, text);
      feedback(controller, execution);
      feedbackItem(itemsMetadata, execution, bubbleId);
    },
    [bubble, locale]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setLabelValue(e.target.value);
      saveLabel(e.target.value);
    },
    [saveLabel]
  );

  const onDelete = useCallback(() => {
    const execution = manager.removeMessageBubble(bubble);
    const previous = bubble.previous;
    const next = bubble.next;
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const [{ dropVisible, dropOver }, dropRef] = useDrop(
    {
      accept: interventionId,
      collect: (monitor) => {
        return {
          dropVisible: monitor.canDrop(),
          dropOver: monitor.isOver(),
        };
      },
      drop: (drop: { bubbleId: string }) => {
        const movedBubble = controller.findItemData(
          drop.bubbleId
        ) as MessageBubble;
        const exec = manager.moveMessageBubble(movedBubble, bubble);
        feedback(controller, exec);
        feedbackItem(itemsMetadata, exec, drop.bubbleId);
      },
    },
    [bubble]
  );

  const onPlay = useCallback(async () => {
    ChatbotSimulatorController.set({
      fetchBundleFn: async (_preferedLocale: string) => {
        const preferedLocale = _preferedLocale as Locale;
        const availableLocales = manager.getAllLocales();
        const locale = availableLocales.includes(preferedLocale)
          ? preferedLocale
          : manager.bot.natural_locale;
        await manager.loadTranslations(locale);
        return manager.getBundle(locale);
      },
      onStepConsumed: (bundle, scriptUuid, step) => {
        if (scriptUuid !== manager.bot.uuid) return;
        itemsMetadata.update(step.id.toString(), (c) => ({
          ...c,
          played: true,
        }));
      },
      startLocale: locale,
      startAt: bubble.findRemoteId(),
    });
  }, [locale]);

  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon="chat-processing-outline"
              title="Message"
              targetControl
              dragRef={dragRef}
              onPlay={onPlay}
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={styles.content}>
              <NodeInput
                value={labelValue}
                onChange={setLabelValue}
                onBlur={onBlur}
                emojis
              />
            </div>
            <Spacer size={4} />
          </div>
          <Revealable revealed={selected} onHeightStartChange={setFooterHeight}>
            <StepSuggestions />
            <NodeFooter translationControl={true} />
          </Revealable>
        </div>
      </AllStepsView>
      <Droper
        dropRef={dropRef}
        visible={dropVisible}
        over={dropOver}
        lineColor={Theme.colors.teal}
      />
      <Spacer size={10} />
    </Fragment>
  );
}
