/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import Content from "src/components/Content";
import SelectField from "src/components/Fields/SelectField";
import Modal from "src/components/Modal";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import BotAdapaters from "src/utilities/BotAdapters";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import GotoBubble, { GoToTarget } from "src/utilities/BotDialog/GotoBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import { TargetableItem } from "src/utilities/BotDialog/types";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import BotManager, { BotManagerContext } from "src/utilities/BotManager";
import DelayedView from "src/utilities/DelayedView";
import getErrorMessage, { FrontError } from "src/utilities/getErrorMessage";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import useHeight from "src/utilities/useHeight";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function GotoBubbleView() {
  const { botApi } = Services.use();
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as GotoBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const containerCss = css({
    borderRadius: 10,
    background: Theme.colors.pink,
    color: Theme.colors.white,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeActionBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  const [modal, setModal] = useBooleanState(false);

  const buttonsNode = (
    <Buttons align="center">
      <Button2
        label="Rediriger"
        onClick={setModal.toTrue}
        layout="outline"
        color={Theme.colors.white}
      />
    </Buttons>
  );

  const actionArgs = bubble.getActionArgs();
  const target = useMemo<GoToTarget | null>(
    () => bubble.getTarget(),
    [actionArgs]
  );

  const botsPromise = useMemo(async () => {
    return botApi.loadBots();
  }, []);

  const targetBot = useMemo(async () => {
    if (!target) return null;
    const bots = await botsPromise;
    const bot = bots.find((b) => b.uuid === target.script);
    if (!bot) throw new FrontError("Bot introuvable");
    return bot;
  }, [botsPromise, target]);

  const targetStep = useMemo(async () => {
    if (!target) return null;
    const bot = await targetBot;
    if (!bot) throw new Error("Invalid script");
    const locale = bot.natural_locale;
    const rawSteps = await botApi.loadRawSteps(bot.uuid, locale);
    const script = BotAdapaters.rawStepsToDraftScript(rawSteps);
    const bundle = BotAdapaters.localizedRawStepsToTranslationBundle(
      rawSteps,
      locale
    );
    const manager = new BotManager(botApi, bot, script);
    manager.addTranslations(bundle);
    return manager.dialog.find(target.step) as TargetableItem;
  }, [botsPromise, target]);

  const displayPromise = useMemo(
    () => Promise.all([targetBot, targetStep]),
    [targetBot, targetStep]
  );

  // Modal

  const [botSelectValue, setBotSelectValue] = useState<string | null>(
    target ? target.script : null
  );
  const [stepSelectValue, setStepSelectValue] = useState<string | null>(
    target ? target.step : null
  );

  const onChangeSelectedBot = useCallback((v: string | null) => {
    setBotSelectValue(v);
    setStepSelectValue(null);
  }, []);

  const targetableItemsPromise = useMemo(async () => {
    if (!botSelectValue) throw new FrontError("Sélectionnez un script");
    const scripts = await botsPromise;
    const bot = scripts.find((s) => s.uuid === botSelectValue);
    if (!bot) throw new Error("Invalid script");
    const locale = bot.natural_locale;
    const rawSteps = await botApi.loadRawSteps(botSelectValue, locale);
    const script = BotAdapaters.rawStepsToDraftScript(rawSteps);
    const bundle = BotAdapaters.localizedRawStepsToTranslationBundle(
      rawSteps,
      locale
    );
    const manager = new BotManager(botApi, bot, script);
    manager.addTranslations(bundle);
    return manager.dialog.getTargetableItems();
  }, [botsPromise, botSelectValue]);

  const getStepLabel = useCallback((item: TargetableItem) => {
    const locale = item.dialog.manager.bot.natural_locale;
    if (item instanceof EntryPoint) return "Au début du script";
    else if (item instanceof ChoiceBubble)
      return `Comme si l'utilisateur avait choisi l'option "${item.getLabel(
        locale
      )}"`;
    else if (item instanceof MessageBubble)
      return `Au message "${item.getLabel(locale)}"`;
    else if (item instanceof UrlBubble)
      return `A l'envoi de la page "${item.getLabel(locale)}"`;
    else if (item instanceof EmailBubble)
      return `A l'envoi de l'email à l'adresse "${item.getLabel(locale)}"`;
    else return "-";
  }, []);

  const [onSubmit] = useSubmitCallback(() => {
    if (!botSelectValue || !stepSelectValue) return;
    const exec = manager.updateActionArgs(
      bubble,
      `${botSelectValue}#${stepSelectValue}`
    );
    feedback(controller, exec);
    feedbackItem(itemsMetadata, exec, bubble.id);
    setModal.toFalse();
  }, [botSelectValue, stepSelectValue]);

  const resultZoneCss = css({
    background: "white",
    marginInline: 4,
    borderRadius: 8,
    padding: 8,
    color: "black",
  });

  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon={bubble.getIcon()}
              title="Redirection"
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={resultZoneCss}>
              <DelayedView
                promise={displayPromise}
                renderPending={() => (
                  <Typo name="bold">Chargement en cours...</Typo>
                )}
                renderRejected={(err) => (
                  <Typo name="bold" color={Theme.colors.red}>
                    {getErrorMessage(err)}
                  </Typo>
                )}
              >
                {([bot, step]) => {
                  return (
                    <div>
                      {bot && step ? (
                        <Fragment>
                          <Typo name="bold">Sur le bot "{bot.name}"</Typo>
                          <Typo name="bold">{getStepLabel(step)}</Typo>
                        </Fragment>
                      ) : (
                        <Typo>Aucun point de reprise</Typo>
                      )}
                    </div>
                  );
                }}
              </DelayedView>
            </div>

            {/* {previewNode} */}
            <Spacer size={4} />
            <Revealable revealed={selected}>
              <Spacer size={10} />
              {buttonsNode}
              <Spacer size={10} />
              <NodeFooter />
            </Revealable>
          </div>
        </div>
      </AllStepsView>
      <Modal visible={modal} onClose={setModal.toFalse}>
        <form onSubmit={onSubmit}>
          <Content scale={2}>
            <Spacer scale={2} />
            <Typo name="heading">Sélectionnez un point de reprise</Typo>
            <Spacer scale={2} />
            <SelectField
              value={botSelectValue}
              onChange={onChangeSelectedBot}
              options={botsPromise}
              label="Chatbot"
              keyExtractor={(b) => b.uuid}
              labelExtractor={(b) => b.name}
              placeholder="Sélectionnez le chatbot de reprise"
            />
            <Spacer />
            <SelectField
              value={stepSelectValue}
              onChange={setStepSelectValue}
              options={targetableItemsPromise}
              label="Point de reprise"
              keyExtractor={(b) => b.id}
              labelExtractor={getStepLabel}
              placeholder="Sélectionnez le point de reprise"
            />
            <Spacer />
            <Buttons align="center">
              <Button2
                label="Valider"
                submit
                color={Theme.colors.blue}
                layout="solid"
              />
            </Buttons>
          </Content>
        </form>
      </Modal>
      <Spacer size={10} />
    </Fragment>
  );
}
