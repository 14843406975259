/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Button from "src/components/Button";
import Clickable from "src/components/Clickable";
import Icon from "src/components/Icon";
import Spinner from "src/components/Loading";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { BotManagerContext } from "src/utilities/BotManager";
import DelayedView from "src/utilities/DelayedView";
import { Locale, localeToLanguage } from "src/utilities/Locales";
import Zones from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import {
  border,
  FilesHover,
  FilesSelection,
  mixBackgroundColors,
  paddingBlock,
  useTranslationController,
} from "../utils";

type SuggestionProps = {
  value: string | null;
  translate?: boolean;
  onSave: (value: string) => any;
  activeColor: string;
};

export default function Suggestion(props: SuggestionProps) {
  const { value, translate = true, onSave, activeColor } = props;

  const manager = BotManagerContext.use();
  const controller = useTranslationController();

  const { natural_locale } = manager.useSelector(
    () => ({
      natural_locale: manager.bot.natural_locale,
    }),
    []
  );

  const targetLocale = Zones.useParam("locale") as Locale;

  const { fileKey, isFirst, isLast } = controller.useFileSelector((c, key) => {
    return {
      fileKey: key,
      isFirst: c.isFirst(key),
      isLast: c.isLast(key),
    };
  }, []);

  const hover = FilesHover.use(fileKey);
  const selected = FilesSelection.use(fileKey);

  const [translationPromise, setTranslationPromise] =
    useState<Promise<string> | null>(null);

  const { ref: suggestionRef, inView: suggestionVisible } = useInView({
    threshold: [0.1],
  });

  const translateValue = useCallback(
    (value: string) => {
      setTranslationPromise(
        manager.api.translate(
          value,
          localeToLanguage(natural_locale),
          localeToLanguage(targetLocale)
        )
      );
    },
    [natural_locale, targetLocale]
  );

  useEffect(() => {
    if (value === null) return;
    if (value === "") return;
    if (!suggestionVisible) return;
    if (translate) translateValue(value);
    else setTranslationPromise(Promise.resolve(value));
  }, [value, suggestionVisible, translate, translateValue]);

  const onMouseEnter = useCallback(() => {
    FilesHover.select(fileKey);
  }, [fileKey]);

  const wrapperCss = css(
    {
      borderRight: border(),
      borderBottom: selected ? border(activeColor) : border(),
      borderTop: isFirst ? border() : "none",
      borderBottomRightRadius: isLast ? 6 : 0,
      paddingBlock: 4,
      display: "flex",
    },
    mixBackgroundColors(
      "#FAFAFA",
      rgba(activeColor, selected ? 0.2 : hover ? 0.1 : 0)
    )
  );

  const idlingCss = css({
    display: "flex",
    paddingBlock: 6,
    paddingInline: 6,
    flexGrow: 1,
    opacity: 0.25,
  });

  const spinnerCss = css({
    display: "flex",
    paddingBlock: 6,
    paddingInline: 6,
    opacity: 0.5,
    flexGrow: 1,
  });

  const errorCss = css({
    display: "flex",
    paddingBlock: 6,
    paddingInline: 6,
    color: Theme.colors.red,
    flexGrow: 1,
  });

  const textCss = css({
    color: hover || selected ? activeColor : "black",
    flexGrow: 1,
    paddingInline: 6,
    display: "flex",
    transition: "all 200ms",
    paddingBlock,
    "&:hover": {
      transform: "translateX(-5px)",
    },
  });

  return (
    <div css={wrapperCss} ref={suggestionRef} onMouseEnter={onMouseEnter}>
      {translationPromise ? (
        <DelayedView
          promise={translationPromise}
          renderPending={() => (
            <div css={spinnerCss}>
              <Spinner active={true} typo="minor" />
              <Spacer size={4} />
              <Typo name="minor">Traduction en cours...</Typo>
            </div>
          )}
          renderRejected={(err) => (
            <div css={errorCss}>
              <Typo name="minor">Traduction indisponible</Typo>
              <Spacer size={4} />
              <Button
                label="Réessayer"
                color={Theme.colors.red}
                onClick={() => translateValue(value || "")}
              />
            </div>
          )}
        >
          {(value) => (
            <Clickable onClick={() => onSave(value)} containerCss={textCss}>
              <Spacer size={10} />
              <Icon name="chevron-down" typo="minor" rotate="90deg" />
              <Spacer size={10} />
              <Typo name="minorItalic">{value}</Typo>
            </Clickable>
          )}
        </DelayedView>
      ) : (
        <div css={idlingCss}>
          <Typo name="minor">Aucun texte à traduire</Typo>
        </div>
      )}
    </div>
  );
}
