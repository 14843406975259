/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { PropsWithChildren, useCallback } from "react";
import { useChatbotTreeStepperController } from "../../ChatbotEditorStepper";
import { ItemsMetadataContext } from "../config";

type AllStepsViewProps = PropsWithChildren<{}>;

export default function AllStepsView(props: AllStepsViewProps) {
  const controller = useChatbotTreeStepperController();

  const { itemKey, selected } = controller.useItemSelector((c, itemKey) => {
    return { itemKey, selected: c.isItemSelected(itemKey) };
  }, []);

  const itemsMetadata = ItemsMetadataContext.use();

  const metadata = itemsMetadata.use(itemKey);
  const played = metadata.played;

  const outerCss = css({
    position: "relative",
    outline: `${played ? 2 : 10}px solid ${
      played ? rgba("black", 0.6) : rgba("black", 0)
    }`,
    outlineOffset: 4,
    transition: "outline 200ms",
    borderRadius: 10,
  });

  let containerCss = css({
    opacity: selected ? 1 : 0.5,
    transition: "all 200ms",
    borderRadius: 10,
    outline: "1px solid rgba(0,0,0,0)",
  });

  if (!selected) {
    containerCss = css(containerCss, {
      "&:hover": {
        outline: "1px solid rgba(0,0,0,0.5)",
        cursor: "pointer",
      },
    });
  }

  const onClick = useCallback(() => {
    controller.selectItem(itemKey);
  }, [itemKey]);

  return (
    <div css={outerCss} data-step-id={itemKey}>
      <div css={containerCss} onClick={onClick}>
        {props.children}
      </div>
    </div>
  );
}
