/** @jsxImportSource @emotion/react */

import React, { DependencyList, useLayoutEffect, useState } from "react";

const useHeight = (ref: React.RefObject<HTMLElement>, def: number = 0) => {
  const [height, setHeight] = useState<number | null>(null);
  useElementLayoutEffect(
    ref,
    (el) => {
      setHeight(el.getBoundingClientRect().height);
      const observer = new ResizeObserver(() => {
        setHeight(el.getBoundingClientRect().height);
      });
      observer.observe(el);
      return () => observer.disconnect;
    },
    []
  );
  return height === null ? def : height;
};

export const useWidth = (ref: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number | null>(null);
  useElementLayoutEffect(
    ref,
    (el) => {
      setWidth(el.getBoundingClientRect().width);
      const observer = new ResizeObserver(() => {
        setWidth(el.getBoundingClientRect().width);
      });
      observer.observe(el);
      return () => observer.disconnect;
    },
    []
  );
  return width;
};

export function useElementLayoutEffect<Element extends HTMLElement>(
  ref: React.RefObject<Element>,
  fn: (el: Element) => (() => void) | void,
  deps: DependencyList = []
) {
  return useLayoutEffect(() => {
    const el = ref.current;
    if (!el) return;
    return fn(el);
  }, [ref, ...deps]);
}

export default useHeight;
