/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import TextField from "src/components/Fields/TextField";
import Icon from "src/components/Icon";
import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { BotManagerContext } from "src/utilities/BotManager";
import Navigation from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";

export default function ChatbotSettings() {
  const containerCss = css({
    width: "50%",
    maxWidth: 400,
    margin: "auto",
    flex: 1,
    padding: 20,
  });

  const manager = BotManagerContext.use();
  const navigate = useNavigate();

  const [name, setName] = useState<string>(manager.bot.name);

  const onUpdateName = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await manager.api.updateBotName(manager.bot.uuid, name);
      manager.setBotName(name);
    },
    [name]
  );

  const onDelete = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const confirmation = window.confirm(
        "Ce bot sera supprimé définitivement"
      );
      if (!confirmation) return;
      await manager.api.deleteBot(manager.bot.uuid);
      navigate(Navigation.getPath("App"));
    },
    [name]
  );

  return (
    <div css={containerCss}>
      <Panel heading="Nom du bot">
        <form onSubmit={onUpdateName}>
          <TextField value={name} onChange={setName} label="Nom du bot" />
          <Spacer size={20} />
          <Buttons align="center">
            <Button2
              submit
              color={Theme.colors.blue}
              label="Valider"
              layout="solid"
            />
          </Buttons>
        </form>
      </Panel>
      <Spacer size={20} />
      <Panel heading="Effacer ce bot">
        <form onSubmit={onDelete}>
          <Spacer size={20} />
          <Buttons align="center">
            <Button2
              submit
              color={Theme.colors.red}
              label="Effacer définitivement"
              layout="solid"
            />
          </Buttons>
        </form>
      </Panel>
    </div>
  );
}

type PanelProps = PropsWithChildren<{
  icon?: NoeseIcons;
  heading?: string;
}>;

export function Panel(props: PanelProps) {
  const { icon, heading, children } = props;

  const panelCss = css({
    background: Theme.colors.lightblue,
    padding: 20,
    borderRadius: 10,
  });

  const headerCss = css({
    color: Theme.colors.blue,
    display: "flex",
    alignItems: "center",
  });

  return (
    <div>
      <div css={headerCss}>
        <Typo name="heading">
          {icon ? (
            <Fragment>
              <Icon name={icon} typo={"heading"} />{" "}
            </Fragment>
          ) : null}
          {heading}
        </Typo>
      </div>
      <Spacer />
      <div css={panelCss}>{children}</div>
    </div>
  );
}
