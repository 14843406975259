/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";

type RelativeContainerProps = PropsWithChildren<{
  zIndex?: number;
}>;

export default function RelativeContainer(props: RelativeContainerProps) {
  const { zIndex = 1, children } = props;
  const containerCss = css`
    position: relative;
    z-index: ${zIndex};
  `;
  return <div css={containerCss}>{children}</div>;
}
