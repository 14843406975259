/** @jsxImportSource @emotion/react */

import { useCallback, useState } from "react";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import Content from "src/components/Content";
import TextField from "src/components/Fields/TextField";
import Intersperse from "src/components/Intersperse";
import Modal from "src/components/Modal";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import BotApiTypes from "src/utilities/BotApi/BotApiTypes";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import PublicationView from "./PublicationView";
import { Publication } from "./types";

type HistoryReleaseProps = {
  publication: Publication;
  isFirst?: boolean;
  onRename: (publication: Publication, newName: string) => any;
  onPublish: (publication: Publication) => any;
  onChat: (publication: BotApiTypes.Release) => any;
};

export default function HistoryRelease(props: HistoryReleaseProps) {
  const { publication, isFirst, onRename, onPublish, onChat } = props;

  const [renameModal, setRenameModal] = useBooleanState(false);
  const [name, setName] = useState(publication.name);

  const onPressPublish = useCallback(async () => {
    await onPublish(publication);
  }, [onPublish, publication]);

  const [onRenameSubmit, renaming] = useSubmitCallback(async () => {
    await onRename(publication, name);
    setRenameModal.toFalse();
  }, [onRename, name, publication]);

  return (
    <div>
      <PublicationView
        publication={publication}
        onChat={onChat}
        color={"grey"}
      />
      <Spacer scale={2} />
      <Buttons align="right" row>
        <Intersperse between={() => <Spacer />}>
          {!isFirst ? (
            <Button2
              onClick={setRenameModal.toTrue}
              label="Modifier"
              color={"grey"}
              layout="outline"
            />
          ) : null}
          <Button2
            onClick={onPressPublish}
            label="Remettre en ligne"
            color={"grey"}
            layout="outline"
          />
        </Intersperse>
      </Buttons>
      <Modal visible={renameModal} onClose={setRenameModal.toFalse}>
        <form onSubmit={onRenameSubmit}>
          <Content scale={2}>
            <Spacer scale={2} />
            <Typo name="heading">Notes de version</Typo>
            <Spacer />
            <Typo>
              Décrivez succintement les améliorations de cette version et/ou les
              changements apportés depuis la version précédente.
            </Typo>
            <Spacer />
            <TextField
              value={name}
              onChange={setName}
              label="Notes de version"
            />
            <Spacer />
            <Buttons align="center">
              <Button2
                label="Valider"
                submit
                color={Theme.colors.blue}
                layout="solid"
                loading={renaming}
              />
            </Buttons>
          </Content>
        </form>
      </Modal>
    </div>
  );
}
