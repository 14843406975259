/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import ActionBubble from "src/utilities/BotDialog/ActionBubble";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import GotoBubble from "src/utilities/BotDialog/GotoBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import PhoneBubble from "src/utilities/BotDialog/PhoneBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import { Execution } from "src/utilities/Executions";
import Navigation from "src/utilities/Navigation";
import Toasts from "src/utilities/Toasts";
import useBooleanState from "src/utilities/useBooleanState";
import { Solution, Solutions } from "../Solutions";
import BotErrorView from "./BotErrorView";
import { DeadPath } from "./types";

type DeadPathViewProps = {
  error: DeadPath;
  onFixed: () => any;
};

export default function DeadPathView(props: DeadPathViewProps) {
  const { error, onFixed } = props;
  const manager = BotManagerContext.use();

  const target = error.target;

  const [fixing, setFixing] = useBooleanState(false);

  const goToRoot = useCallback(() => {
    const bubble = new GotoBubble(null);
    const botUuid = manager.bot.uuid;
    const rootNodeid = manager.dialog.getEntryPoint().findRemoteId();
    const actionArgs = `${botUuid}#${rootNodeid}`;
    bubble.setActionArgs(actionArgs);
    let exec: Execution | null;
    if (target instanceof ActionBubble)
      exec = manager.appendActionBubble(target.intervention, bubble);
    else exec = manager.createActionsIntervention(target, bubble);
    exec.events.on("started", setFixing.toTrue);
    exec.events.on("failed", (err) => Toasts.fromError(err));
    exec.events.on("done", () => {
      onFixed();
    });
    exec.events.on("ended", setFixing.toFalse);
  }, [target, onFixed]);

  let message: string = `L'une des étapes du bot n'est suivie d'aucun comportement`;
  let solutions = (
    <Solutions>
      <Solution
        icon="subdirectory-arrow-left"
        text="Corriger : Reprendre au début du script"
        onClick={goToRoot}
      />
      <Solution
        icon="eye"
        text="Voir le problème"
        to={Navigation.getPath("ChatbotEdition", {
          query: { step: target.id },
        })}
      />
    </Solutions>
  );

  if (target instanceof ChoiceBubble) {
    const label = target.getLabel(manager.bot.natural_locale);
    message = `Le bot propose l'option "${label}" mais ne fait rien si l'utilsateur la choisit.`;
  } else if (target instanceof MessagesIntervention) {
    const label = target.lastBubble.getLabel(manager.bot.natural_locale);
    message = `Le bot envoie le message "${label}" mais ne fait rien ensuite.`;
  } else if (target instanceof ActionBubble) {
    const label = target.getLabel(manager.bot.natural_locale);
    if (target instanceof UrlBubble) {
      message = `Le bot envoie le lien "${label}" mais ne fait rien ensuite.`;
    } else if (target instanceof EmailBubble) {
      message = `Le bot propose l'envoi d'un email à "${label}" mais ne fait rien ensuite.`;
    } else if (target instanceof PhoneBubble) {
      message = `Le bot lance un appel téléphonique à "${label}" mais ne fait rien ensuite.`;
    }
  }

  return (
    <BotErrorView
      type="Chemin mort"
      icon="sign-direction-remove"
      description={message}
      solutions={solutions}
      fixing={fixing}
    ></BotErrorView>
  );
}
