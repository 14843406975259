/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, PropsWithChildren } from "react";
import Theme from "src/utilities/Theme";
import Icon from "./Icon";
import NoeseIcons from "./Icon/NoeseIcons/NoeseIcons.generated";
import Spacer from "./Spacer";
import Typo from "./Typo";

type Panel2Props = PropsWithChildren<{
  icon?: NoeseIcons;
  heading?: string;
}>;

export function Panel2(props: Panel2Props) {
  const { icon, heading, children } = props;

  const panelCss = css({
    background: Theme.colors.lightblue,
    borderRadius: 10,
  });

  const headerCss = css({
    color: Theme.colors.blue,
    display: "flex",
    alignItems: "center",
  });

  return (
    <div>
      <div css={headerCss}>
        <Typo name="heading">
          {icon ? (
            <Fragment>
              <Icon name={icon} typo={"heading"} />{" "}
            </Fragment>
          ) : null}
          {heading}
        </Typo>
      </div>
      <Spacer />
      <div css={panelCss}>{children}</div>
    </div>
  );
}
