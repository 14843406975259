import { rgba } from "polished";
import {
  Column,
  EmptyColumn,
} from "src/components/ChatbotEditorStepper/config";
import ActionsIntervention from "./BotDialog/ActionsIntervention";
import ChoicesIntervention from "./BotDialog/ChoicesIntervention";
import MessagesIntervention from "./BotDialog/MessagesIntervention";
import { StrokeProps } from "./Stroke";

function useLinesWidth(column: Column) {
  if (
    column instanceof MessagesIntervention ||
    column instanceof ActionsIntervention ||
    column instanceof EmptyColumn
  ) {
    return 30;
  } else if (column instanceof ChoicesIntervention) {
    return column.bubbles.length * 6 + 20;
  } else {
    return 0;
  }
}

function getChatbotLineStyle(
  selected: boolean
): React.SVGProps<SVGPathElement> {
  if (selected)
    return {
      stroke: "black",
      strokeWidth: 2,
      strokeLinecap: "round",
    };
  else
    return {
      stroke: "black",
      strokeWidth: 1,
      opacity: 0.5,
      strokeDasharray: "1 3",
      strokeLinecap: "round",
    };
}

function getChatbotStrokeProps(
  selected: boolean,
  virtual: boolean
): Omit<StrokeProps, "path"> {
  return {
    color: selected ? "black" : rgba("black", 0.5),
    strength: selected ? 2 : 1,
    dash: virtual ? "2 4" : undefined,
  };
}

function getTranslationStrokeProps(): Omit<StrokeProps, "path"> {
  return {
    color: "#999999",
    strength: 1,
  };
}

function buildCurve(
  startX: number,
  startY: number,
  endX: number,
  endY: number
) {
  const startHandleX = (startX + endX) / 2;
  const startHandleY = startY;
  const endHandleX = (startX + endX) / 2;
  const endHandleY = endY;
  return `M ${startX} ${startY} C ${startHandleX} ${startHandleY} ${endHandleX} ${endHandleY} ${endX} ${endY}`;
}

const ChatbotLines = {
  useLinesWidth,
  getChatbotLineStyle,
  getChatbotStrokeProps,
  buildCurve,
  getTranslationStrokeProps,
};

export default ChatbotLines;
