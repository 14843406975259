/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import Spacer from "src/components/Spacer";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import ChatbotLines from "src/utilities/ChatbotLines";
import { useChatbotTreeStepperController } from "..";
import { InterventionViewProps } from "../config";

export default function EntryPointColumnView(props: InterventionViewProps) {
  const { ItemsComponent } = props;

  const controller = useChatbotTreeStepperController();

  const { columnKey, entryPoint } = controller.useColumnSelector(
    (c, columnKey) => ({
      columnKey,
      entryPoint: c.findColumnData(columnKey) as EntryPoint,
    }),
    []
  );

  const stepsWidth = 180;
  const linesWidth = ChatbotLines.useLinesWidth(entryPoint);
  const occupation = stepsWidth + linesWidth;

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    width: stepsWidth,
    marginLeft: linesWidth,
    transition: "margin-left 200ms, padding-top 200ms",
  });

  useEffect(() => {
    controller.selectItem(columnKey);
  }, []);

  controller.useColumnWidth(columnKey, occupation);

  return (
    <div css={containerCss}>
      <ItemsComponent />
      <Spacer size={10} />
    </div>
  );
}
