/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect } from "react";
import { useDrop } from "react-dnd";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import Droper from "src/components/Nodes/Fragments/Droper";
import Spacer from "src/components/Spacer";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import ChoicesIntervention from "src/utilities/BotDialog/ChoicesIntervention";
import { BotManagerContext } from "src/utilities/BotManager";
import ChatbotLines from "src/utilities/ChatbotLines";
import Theme from "src/utilities/Theme";
import { InterventionViewProps, ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";

export default function ChoicesInterventionView(props: InterventionViewProps) {
  const { ItemsComponent } = props;

  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { columnKey, intervention } = controller.useColumnSelector(
    (c, columnKey) => {
      const intervention = c.findColumnData(columnKey) as ChoicesIntervention;
      return { columnKey, intervention };
    },
    []
  );

  const stepsWidth = 200;
  const linesWidth = ChatbotLines.useLinesWidth(intervention);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    width: stepsWidth,
    marginLeft: linesWidth,
    transition: "margin-left 200ms, padding-top 200ms",
  });

  controller.useColumnHeaderHeight(columnKey, 10);
  controller.useColumnWidth(columnKey, stepsWidth + linesWidth);

  useEffect(() => {
    const bubbles = intervention.bubbles;
    if (bubbles.length === 1) controller.selectItem(bubbles[0].id);
  }, [intervention]);

  const [{ dropVisible, dropOver }, dropRef] = useDrop(
    {
      accept: columnKey,
      collect: (monitor) => {
        return {
          dropVisible: monitor.canDrop(),
          dropOver: monitor.isOver(),
        };
      },
      drop: (drop: { itemKey: string }) => {
        const bubble = controller.findItemData(drop.itemKey) as ChoiceBubble;
        const exec = manager.moveChoiceBubble(bubble, null);
        feedback(controller, exec);
        feedbackItem(itemsMetadata, exec, bubble.id);
      },
    },
    []
  );

  return (
    <div css={containerCss}>
      <Droper
        dropRef={dropRef}
        visible={dropVisible}
        over={dropOver}
        lineColor={Theme.colors.purple}
      />
      <Spacer size={10} />
      <ItemsComponent />
      <Spacer size={30} />
      <AppendUserChoiceButton />
    </div>
  );
}

function AppendUserChoiceButton() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { distance, intervention } = controller.useColumnSelector(
    (c, columnKey) => {
      const intervention = c.findColumnData(columnKey) as ChoicesIntervention;
      const distance = c.getColumnDistanceFromLastOne(columnKey);
      return { distance, intervention };
    },
    []
  );

  const onAddUserChoice = useCallback(() => {
    const execution = manager.appendChoiceBubble(intervention);
    feedback(controller, execution);
    execution.events.on("commit-done", (bubble) => {
      controller.selectItem(bubble.id);
      feedbackItem(itemsMetadata, execution, bubble.id);
    });
  }, [intervention]);

  return (
    <Buttons align="center">
      <Button2
        icon="open-in-new"
        label="Ajouter une option"
        onClick={onAddUserChoice}
        layout="solid"
        color={Theme.colors.purple}
      />
    </Buttons>
  );
}
