import { sumBy } from "lodash";
import { DependencyList, useEffect } from "react";
import createRequiredContext from "../createRequiredContext";
import findInMap from "../findInMap";
import StrictMap from "../StrictMap";
import Updater from "../Updater";
import { FileKeyExtractor, FilesExtractor } from "./types";

export type TreeStructureControllerConfig<TFile> = {
  rootFiles: Array<TFile>;
  fileKeyExtractor: FileKeyExtractor<TFile>;
  filesExtractor: FilesExtractor<TFile>;
  FileComponent: React.ComponentType;
};

type FileWrapper<TFile> = {
  key: string;
  data: TFile;
  parent: string | null;
  children: Array<string>;
  unfolded: boolean;
  depth: number;
  index: number;
  height: number | null;
};

export default class TreeStructureController<TFile> {
  readonly rootFiles: Array<TFile>;
  readonly updater = new Updater();
  readonly fileKeyExtractor: FileKeyExtractor<TFile>;
  readonly filesExtractor: FilesExtractor<TFile>;
  readonly FileComponent: React.ComponentType;
  private filesWrappers: StrictMap<string, FileWrapper<TFile>> =
    new StrictMap();
  private files: Array<string> = [];

  constructor(config: TreeStructureControllerConfig<TFile>) {
    this.rootFiles = config.rootFiles;
    this.fileKeyExtractor = config.fileKeyExtractor;
    this.filesExtractor = config.filesExtractor;
    this.FileComponent = config.FileComponent;
    this.compute();
  }

  compute() {
    this.computeFiles(this.rootFiles, null, 0);
    this.updater.update();
  }

  private computeFiles(
    files: Array<TFile>,
    parent: string | null,
    depth: number
  ) {
    if (depth === 0) {
      this.files = [];
    }

    files.forEach((file) => {
      const key = this.fileKeyExtractor(file);
      const existingWrapper = this.filesWrappers.get(key);
      const wrapper: FileWrapper<TFile> = {
        key,
        data: file,
        parent,
        children: [],
        unfolded: existingWrapper ? existingWrapper.unfolded : false,
        depth: depth,
        index: this.files.length,
        height: existingWrapper ? existingWrapper.height : null,
      };
      this.filesWrappers.set(key, wrapper);
      this.files.push(key);

      if (wrapper.unfolded) {
        const children = this.filesExtractor(file);
        if (children) {
          this.computeFiles(children, key, depth + 1);
        }
      }
    });
  }

  getFiles() {
    return this.files;
  }

  useFileSelector<TOutput>(
    fn: (c: this, fileKey: string) => TOutput,
    deps: DependencyList
  ) {
    const fileKey = TreeStructureFileContext.use();
    return this.updater.useValue(() => fn(this, fileKey), [...deps, fileKey]);
  }

  searchFileParent(key: string) {
    return findInMap(this.filesWrappers, key).parent;
  }

  searchPreviousFile(key: string) {
    const index = findInMap(this.filesWrappers, key).index;
    if (index > 0) return this.files[index - 1];
    else return null;
  }

  findFileIndex(fileKey: string) {
    return this.filesWrappers.find(fileKey).index;
  }

  findFileData(fileKey: string) {
    return this.filesWrappers.find(fileKey).data;
  }

  // Position

  isFirst(key: string) {
    return this.files[0] === key;
  }

  isLast(key: string) {
    return this.files[this.files.length - 1] === key;
  }

  // Folded / Unfolded

  isFileUnfolded(fileKey: string) {
    return this.filesWrappers.find(fileKey).unfolded;
  }

  setUnfolded(fileKey: string, unfolded: boolean) {
    const wrapper = this.filesWrappers.find(fileKey);
    wrapper.unfolded = unfolded;
    this.compute();
  }

  // Depth

  findFileDepth(fileKey: string) {
    return this.filesWrappers.find(fileKey).depth;
  }

  getFilesInDepth(depth: number) {
    return this.files.filter(
      (key) => findInMap(this.filesWrappers, key).depth <= depth
    );
  }

  /**
   * Containing file is the file
   * - having depth <= at file's depth
   * - before me
   * - the closest from me
   */
  getContainingFile(key: string) {
    const wrapper = findInMap(this.filesWrappers, key);
    const depth = wrapper.depth;
    let containingElement: string | null = null;
    let filePassed = false;
    this.files.forEach((file) => {
      if (filePassed) return;
      if (file === key) {
        filePassed = true;
        return;
      }
      const fileWrapper = findInMap(this.filesWrappers, file);
      if (fileWrapper.depth > wrapper.depth) return;
      containingElement = file;
    });
    return containingElement as string | null;
  }

  // Height

  useFileHeight(itemKey: string, height: number) {
    useEffect(() => {
      const item = findInMap(this.filesWrappers, itemKey);
      if (item.height !== height) {
        item.height = height;
        this.updater.update();
      }
    }, [itemKey, height]);
  }

  getCumulatedHeight(from: string, to: string, includeFrom = false) {
    const fromWrapper = findInMap(this.filesWrappers, from);
    const toWrapper = findInMap(this.filesWrappers, to);
    let modifier = includeFrom ? 0 : 1;
    const files = this.files.slice(
      fromWrapper.index + modifier,
      toWrapper.index
    );
    return sumBy(files, (f) => findInMap(this.filesWrappers, f).height || 0);
  }
}

export const TreeStructureControllerContext =
  createRequiredContext<TreeStructureController<any>>();

export const TreeStructureFileContext = createRequiredContext<string>();
