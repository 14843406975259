import { css, SerializedStyles } from "@emotion/react";

export const TYPO_LINE_HEIGHT = 1.5;

type TypoDescriptor = {
  weight: number;
  size: number;
  height: number;
  extra?: SerializedStyles;
};

function getStyles(descriptor: TypoDescriptor) {
  const { weight, size, height, extra } = descriptor;
  return css(
    {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: weight,
      fontSize: `${size}px`,
      lineHeight: `${height}px`,
    },
    extra
  );
}

// Product

const productDescriptor: TypoDescriptor = {
  weight: 300,
  size: 33,
  height: 38,
};

const product = getStyles(productDescriptor);

// Heading

const headingDescriptor: TypoDescriptor = {
  weight: 300,
  size: 24,
  height: 28,
};

const heading = getStyles(headingDescriptor);

// Button

const buttonDescriptor: TypoDescriptor = {
  weight: 500,
  size: 14,
  height: 16,
};

const button = getStyles(buttonDescriptor);

// Body

const bodyDescriptor: TypoDescriptor = {
  weight: 300,
  size: 14,
  height: 16,
};

const body = getStyles(bodyDescriptor);

// Label

const labelDescriptor: TypoDescriptor = {
  weight: 600,
  size: 14,
  height: 16,
};

const label = getStyles(labelDescriptor);

// Bold

const boldDescriptor: TypoDescriptor = {
  weight: 700,
  size: 14,
  height: 16,
};

const bold = getStyles(boldDescriptor);

// Minor

const minorDescriptor: TypoDescriptor = {
  weight: 400,
  size: 10,
  height: 11,
};

const minor = getStyles(minorDescriptor);

const subheadingDescriptor: TypoDescriptor = {
  weight: 600,
  size: 18,
  height: 22,
  // extra: css({ textTransform: "uppercase" }),
};

const subheading = getStyles(subheadingDescriptor);

const minorItalicDescriptor: TypoDescriptor = {
  weight: 500,
  size: 13,
  height: 17,
  extra: css({ fontStyle: "italic" }),
};
const minorItalic = getStyles(minorItalicDescriptor);

const pillDescriptor: TypoDescriptor = {
  weight: 500,
  size: 14,
  height: 18,
};
const pill = getStyles(pillDescriptor);

export type TypoName = keyof typeof Typos;

const Typos = {
  heading,
  product,
  subheading,
  minor,
  body,
  button,
  label,
  bold,
  pill,
  minorItalic,
};

export const TypoDescriptors: { [name in TypoName]: TypoDescriptor } = {
  heading: headingDescriptor,
  product: productDescriptor,
  subheading: subheadingDescriptor,
  minor: minorDescriptor,
  bold: boldDescriptor,
  body: bodyDescriptor,
  button: buttonDescriptor,
  pill: pillDescriptor,
  minorItalic: minorItalicDescriptor,
  label: labelDescriptor,
};

export default Typos;
