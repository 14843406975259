import { css } from "@emotion/react";
import { rgba } from "polished";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import ModelMetadataManager from "src/utilities/ModelMetadataManager";
import SelectedElementManager from "src/utilities/SelectedElementManager";
import TreeStructureController, {
  TreeStructureControllerContext,
} from "src/utilities/TreeStructure/TreeStructureController";
import { TypoDescriptors } from "src/utilities/Typos";

export type ChatbotTranslatorStructureFile =
  | MessageBubble
  | ChoiceBubble
  | UrlBubble;

/**
 * @deprecated
 */
export function useChatbotTreeStructureController() {
  return TreeStructureControllerContext.use() as TreeStructureController<ChatbotTranslatorStructureFile>;
}

export function useTranslationController() {
  return TreeStructureControllerContext.use() as TreeStructureController<ChatbotTranslatorStructureFile>;
}

const updatePixelRatio = () => {
  const pixelRatio = window.devicePixelRatio;
  const hairlineWidth = (1 / pixelRatio).toFixed(2) + "px";
  document.documentElement.style.setProperty("--hairline-width", hairlineWidth);
  matchMedia(`(resolution: ${pixelRatio}dppx)`).addEventListener(
    "change",
    updatePixelRatio,
    { once: true }
  );
};

export function mixBackgroundColors(...colors: Array<string>) {
  return css({
    background: [...colors]
      .reverse()
      .map((c) => `linear-gradient(to left, ${c}, ${c})`)
      .join(", "),
  });
}

updatePixelRatio();

export const depthBlockPadding = 10;
export const depthInlinePadding = 4;
export const depthWidth = TypoDescriptors.body.height + depthInlinePadding * 2;
export const depthHeight = TypoDescriptors.body.height + depthBlockPadding * 2;
export const border = (color: string = rgba("black", 0.4)) =>
  `var(--hairline-width) solid ${color}`;

export const paddingBlock = 6;

export type FileMetadata = { loading: boolean };

export const FilesMetadata = new ModelMetadataManager<FileMetadata>(() => ({
  loading: false,
}));

export const FilesHover = new SelectedElementManager();
export const FilesSelection = new SelectedElementManager();
