import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import Navigation from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import BotErrorView from "./BotErrorView";
import { EmptyDelivery } from "./types";

type EmptyDeliveryViewProps = {
  error: EmptyDelivery;
};

export default function EmptyDeliveryView(props: EmptyDeliveryViewProps) {
  const { error } = props;

  const target = error.target;

  let message: string = "";
  if (target instanceof MessageBubble) {
    message = `Le bot envoie un message vide. Veillez à ce que tous les messages du bot contiennent du texte.`;
  } else if (target instanceof ChoiceBubble) {
    message = `Le bot propose un choix vide. Veillez à ce que tous les choix proposé par le bot contiennent du texte.`;
  }

  const solutions = (
    <Buttons>
      <Button2
        label="Corriger"
        to={Navigation.getPath("ChatbotEdition", {
          query: { step: target.id },
        })}
        color={Theme.colors.blue}
        layout="solid"
      />
    </Buttons>
  );

  return (
    <BotErrorView
      type="Envoi vide"
      icon="translate"
      description={message}
      solutions={solutions}
    ></BotErrorView>
  );
}
