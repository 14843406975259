import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import { BotPlayerTypes } from "src/player/types";
import { BotError, DeadPath } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import ActionsIntervention from "./ActionsIntervention";
import Bubble from "./Bubble";
import ChoicesIntervention from "./ChoicesIntervention";
import MessagesIntervention from "./MessagesIntervention";
import { TargetableItem } from "./types";

export default class ChoiceBubble extends Bubble {
  readonly type = "ChoiceBubble";

  // Intervention
  private interventionId: string | null = null;

  set intervention(intervention: ChoicesIntervention) {
    this.interventionId = intervention.id;
  }

  get intervention() {
    if (this.interventionId === null)
      throw new Error("ChoiceBubble without interventionId");
    const intervention = this.dialog.find(this.interventionId);
    if (intervention instanceof ChoicesIntervention) return intervention;
    throw new Error("Bad ChoiceBubble intervention");
  }

  getKind() {
    return "CH" as const;
  }

  // Registration

  isRegistrable(): boolean {
    if (!this.interventionId) return false;
    return true;
  }

  isUnregistrable(): boolean {
    if (this.interventionId !== null) return false;
    return true;
  }

  // Ahead

  private aheadId: string | null = null;

  set ahead(
    ahead:
      | ChoicesIntervention
      | MessagesIntervention
      | ActionsIntervention
      | null
  ) {
    if (ahead === null) {
      this.aheadId = null;
    } else {
      this.aheadId = ahead.id;
      ahead.parent = this;
    }
  }

  get ahead() {
    if (this.aheadId === null) return null;
    const ahead = this.dialog.find(this.aheadId);
    if (ahead instanceof ChoicesIntervention) return ahead;
    if (ahead instanceof MessagesIntervention) return ahead;
    if (ahead instanceof ActionsIntervention) return ahead;
    throw new Error("Invalid ChoiceBubble nextIntervention");
  }

  // Position

  get index() {
    const bubbles = this.intervention.bubbles;
    const index = bubbles.indexOf(this);
    return index;
  }

  get previous() {
    const index = this.index;
    if (index <= 0) return null;
    return this.intervention.bubbles[index - 1];
  }

  get next() {
    const index = this.index;
    if (index >= this.intervention.bubbles.length - 1) return null;
    return this.intervention.bubbles[index + 1];
  }

  getIcon(): NoeseIcons {
    return "open-in-new";
  }

  // Weight

  getWeight(tracker: number = 0) {
    let weight = tracker;
    if (this.ahead) weight += this.ahead.getWeight(tracker);
    weight += 1;
    return weight;
  }

  getErrors() {
    const errors: Array<BotError> = [];
    errors.push(...this.getMissingTranslationErrors());
    if (!this.ahead) errors.push(new DeadPath(this));
    if (this.ahead) errors.push(...this.ahead.getErrors());
    return errors;
  }

  destroy() {
    this.intervention.removeBubble(this);
    this.interventionId = null;
    this.unregister();
  }

  // Export to draft
  toDraftStep(): DraftBot.Step {
    return {
      id: this.findRemoteId(),
      data: {
        kind: "CH",
        action: null,
        action_args: null,
        label: this.getReleaseLabel(),
        name: this.findName(),
        targetable: this.isTargetable(),
      },
      children: this.ahead ? this.ahead.toDraftSteps() : [],
    };
  }

  // Export to bundle
  toBundleStep(locale: Locale): BotPlayerTypes.Step {
    return {
      id: this.findRemoteId(),
      data: {
        kind: "CH",
        action: null,
        action_args: null,
        name: this.getLabel(locale),
      },
      children: this.ahead ? this.ahead.toBundleSteps(locale) : [],
    };
  }

  static fromStep(step: DraftBot.Step, dialog: BotDialog) {
    // Checks
    if (step.data.kind !== "CH") {
      throw new BotStructureError(
        "Cannot create choices bubble from a non-CH step",
        {
          step,
        }
      );
    }

    // Self
    const me = new ChoiceBubble(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.setTargetable(step.data.targetable);

    // Children

    const children = step.children || [];

    if (children.length === 0) {
      me.ahead = null;
    } else {
      const firstChildren = children[0];
      if (firstChildren.data.kind === "BI") {
        const ahead = MessagesIntervention.fromSteps(children, dialog);
        me.ahead = ahead;
        ahead.register();
      } else if (firstChildren.data.kind === "CH") {
        const ahead = ChoicesIntervention.fromSteps(children, dialog);
        me.ahead = ahead;
        ahead.register();
      } else if (firstChildren.data.kind === "AA") {
        const ahead = ActionsIntervention.fromSteps(children, dialog);
        me.ahead = ahead;
        ahead.register();
      } else {
        throw new BotStructureError(
          "Choice step can only be continued with 1 BI children, 1 AA children or 1+ CH children",
          { step, children }
        );
      }
    }

    return me;
  }

  getTargetableItems() {
    const output: Array<TargetableItem> = [];
    if (this.isTargetable()) output.push(this);
    if (this.ahead) output.push(...this.ahead.getTargetableItems());
    return output;
  }
}
