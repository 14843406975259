/** @jsxImportSource @emotion/react */
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import ActionsInterventionView from "src/components/ChatbotEditorStepper/ColumnView/ActionsInterventionView";
import { EmptyColumn } from "src/components/ChatbotEditorStepper/config";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import ChoicesIntervention from "src/utilities/BotDialog/ChoicesIntervention";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import ChoicesInterventionView from "./ChoicesInterventionView";
import EmptyColumnView from "./EmptyColumnView";
import EntryPointView from "./EntryPointView";
import MessagesInterventionView from "./MessagesInterventionView";

type ColumnViewProps = {
  ItemsComponent: React.ComponentType;
};

export default function ColumnView(props: ColumnViewProps) {
  const controller = useChatbotTreeStepperController();

  const { interaction } = controller.useColumnSelector((c, columnKey) => {
    return { columnKey, interaction: c.findColumnData(columnKey) };
  }, []);

  if (interaction instanceof EntryPoint) {
    return <EntryPointView {...props} />;
  } else if (interaction instanceof MessagesIntervention) {
    return <MessagesInterventionView {...props} />;
  } else if (interaction instanceof ChoicesIntervention) {
    return <ChoicesInterventionView {...props} />;
  } else if (interaction instanceof ActionsIntervention) {
    return <ActionsInterventionView {...props} />;
  } else if (interaction instanceof EmptyColumn) {
    return <EmptyColumnView {...props} />;
  } else {
    return null;
  }
}
