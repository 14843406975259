/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import ChatbotStepper from "src/components/ChatbotEditorStepper";
import Assets, { useBackgroundAsset } from "src/utilities/Assets";

export default function ChatbotEditor() {
  const containerCss = css(
    {
      width: "100%",
    },
    useBackgroundAsset(Assets.dot)
  );

  return (
    <div css={containerCss}>
      <ChatbotStepper />
    </div>
  );
}
