import { css } from "@emotion/react";
import { readableColor, rgba } from "polished";
import { TypoName } from "src/utilities/Typos";
import Clickable, { ClickableProps } from "./Clickable";
import Icon from "./Icon";
import NoeseIcons from "./Icon/NoeseIcons/NoeseIcons.generated";
import Typo from "./Typo";

type ButtonContentProps = { label: string } | { icon: NoeseIcons };

type ButtonProps = ClickableProps & {
  color: string;
  textColor?: string;
  padding?: string;
  border?: string;
  typo?: TypoName;
} & ButtonContentProps;

export default function Button(props: ButtonProps) {
  const {
    color,
    textColor,
    padding,
    border,
    typo = "button",
    ...clickableProps
  } = props;

  const containerCss = css`
    display: inline-block;
    background-color: ${color};
    color: ${textColor || readableColor(color)};
    padding: ${padding || "4px 8px"};
    border-radius: 16px;
    transition: background-color 100ms;
    outline: 0px solid ${rgba(color, 0)};
    border: ${border || "none"};
    transition: all 100ms;
    &:hover {
      outline: 4px solid ${rgba(color, 0.3)};
    }
  `;

  const content =
    "label" in props ? (
      <Typo name={typo}>{props.label}</Typo>
    ) : (
      <Icon square name={props.icon} typo={typo} />
    );

  return (
    <Clickable {...clickableProps} containerCss={containerCss}>
      {content}
    </Clickable>
  );
}
