/** @jsxImportSource @emotion/react */
import { css, CSSObject, SerializedStyles } from "@emotion/react";
import React, { useCallback } from "react";

export type AutoHeightInputProps = {
  value: string;
  onChange: (value: string) => any;
  typoCss?: SerializedStyles;
  paddingCss?: SerializedStyles;
  containerCss?: SerializedStyles | CSSObject;
  color?: string;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
  noNewLines?: boolean;
} & Pick<
  React.HTMLAttributes<HTMLTextAreaElement>,
  "onBlur" | "onFocus" | "id"
>;

export default function AutoHeightInput(props: AutoHeightInputProps) {
  const {
    value,
    onChange,
    typoCss,
    paddingCss,
    containerCss,
    textareaRef,
    noNewLines,
    ...textareaProps
  } = props;

  const wrapperCss = css({ position: "relative" }, containerCss);

  const lineBreakCss = css({
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
  });

  const displayCss = css(
    typoCss,
    lineBreakCss,
    {
      padding: 0,
      margin: 0,
      border: "none",
      color: "transparent",
    },
    paddingCss
  );

  const textAreaWrapperCss = css({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  const textareaCss = css(
    lineBreakCss,
    {
      width: "100%",
      height: "100%",
      color: "inherit",
      background: "transparent",
      padding: 0,
      margin: 0,
      border: "none",
      resize: "none",
      opacity: 1,
      "&:focus": {
        outline: "none",
      },
    },
    typoCss,
    paddingCss
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        if (noNewLines) {
          e.preventDefault();
        }
      }
    },
    [noNewLines]
  );

  const onTexteareaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div css={wrapperCss} className="autoHeightInput">
      <div css={displayCss}>{value + "\u00A0"}</div>
      <div css={textAreaWrapperCss}>
        <textarea
          ref={textareaRef}
          css={textareaCss}
          value={value}
          onChange={onTexteareaChange}
          onKeyDown={onKeyDown}
          {...textareaProps}
        />
      </div>
    </div>
  );
}

//@ts-ignore
window.revealAutoHeightInput = () => {
  const elements = [
    //@ts-ignore
    ...document.getElementsByClassName("autoHeightInput"),
  ].forEach((el: HTMLDivElement) => {
    //@ts-ignore
    el.children[0].style.color = "rgba(0,0,0,0.5)";
    //@ts-ignore
    el.children[1].style.color = "rgba(255,0,0,0.5)";
  });
};
