/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { createContext, PropsWithChildren, useEffect, useRef } from "react";
import useHeight from "src/utilities/useHeight";

type RevealableProps = PropsWithChildren<{
  revealed: boolean;
  onHeightStartChange?: (height: number) => any;
}>;

export default function Revealable(props: RevealableProps) {
  const { revealed, children, onHeightStartChange } = props;

  // const contentRef = useRef<HTMLDivElement>(null);
  // const contentHeightNumber = useHeight(contentRef);
  // const contentHeightCss =
  //   contentHeightNumber === null ? "auto" : `${contentHeightNumber}px`;
  // const containerHeight = revealed ? contentHeightCss : "0px";
  // const containerCss = css`
  //   position: relative;
  //   overflow: hidden;
  //   height: ${containerHeight};
  //   transition: height 200ms;
  // `;

  // useEffect(() => {
  //   if (!onHeightStartChange) return;
  //   if (!revealed) onHeightStartChange(0);
  //   else {
  //     if (contentHeightNumber === null) return;
  //     else onHeightStartChange(contentHeightNumber);
  //   }
  // }, [contentHeightNumber, onHeightStartChange, revealed]);

  // return (
  //   <ReveableContext.Provider value={revealed}>
  //     <div css={containerCss}>
  //       <div ref={contentRef}>{children}</div>
  //     </div>
  //   </ReveableContext.Provider>
  // );

  const measuredRef = useRef<HTMLDivElement>(null);
  const measuredHeight = useHeight(measuredRef);
  const height = revealed ? measuredHeight : 0;

  const containerCss = css({
    position: "relative",
    overflow: "visible",
  });

  const measuredCss = css({
    position: "absolute",
    left: 0,
    right: 0,
    pointerEvents: revealed ? "auto" : "none",
    opacity: 0,
  });

  const displayCss = css({
    opacity: revealed ? 1 : 0,
    pointerEvents: revealed ? "auto" : "none",
    height,
    filter: `blur(${revealed ? "0px" : "10px"})`,
    transition: "all 200ms",
  });

  useEffect(() => {
    if (!onHeightStartChange) return;
    if (revealed) onHeightStartChange(height);
    else onHeightStartChange(0);
  }, [height, revealed, onHeightStartChange]);

  return (
    <div css={containerCss}>
      <div css={measuredCss} ref={measuredRef}>
        {children}
      </div>
      <div css={displayCss}>{children}</div>
    </div>
  );
}

export const ReveableContext = createContext<boolean>(false);
