/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FormEvent, useCallback, useState } from "react";
import Asset from "src/components/Asset";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import PasswordField from "src/components/Fields/PasswordField";
import TextField from "src/components/Fields/TextField";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import Api from "src/utilities/Api";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";

export default function AuthScreen() {
  return <AuthByEmailAndPasswordScreen />;
}

type AuthResponse = {
  access: string;
  email: string;
  refresh: string;
  username: string;
};

function AuthByEmailAndPasswordScreen() {
  const { authorization } = Services.use();

  const containerCss = css({
    backgroundColor: Theme.colors.lightblue,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  });

  const contentCss = css({
    flexGrow: 1,
  });

  const formCss = css({
    width: "50%",
    maxWidth: 390,
    paddingInline: 30,
    margin: "auto",
    marginTop: 40,
  });

  const footerCss = css({
    backgroundColor: Theme.colors.white,
    height: Theme.sizes.bottombarHeight,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingInline: 30,
  });

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!email) return;
      if (!password) return;
      const response = await Api.post<AuthResponse>("/api/pdl/auth/login/", {
        login: email,
        password,
      });
      authorization.setTokens({
        access: response.data.access,
        refresh: response.data.refresh,
      });
    },
    [email, password]
  );

  return (
    <div css={containerCss}>
      <div css={contentCss}>
        <form css={formCss} onSubmit={onSubmit}>
          <Typo name="heading" center color={Theme.colors.blue}>
            Connexion
          </Typo>
          <Spacer size={30} />
          <TextField value={email} onChange={setEmail} label="Adresse email" />
          <Spacer size={30} />
          <PasswordField
            value={password}
            onChange={setPassword}
            label="Mot de passe"
          />
          <Spacer size={30} />
          <Buttons align="center">
            <Button2
              label="Entrer"
              submit
              layout="solid"
              color={Theme.colors.blue}
            />
          </Buttons>
        </form>
      </div>
      <div css={footerCss}>
        <Typo name="minor">Une solution proposée par</Typo>
        <Spacer size={30} />
        <Asset name="noese" />
      </div>
    </div>
  );
}

// function AuthByTokenScreen() {

//   const { authorization } = Services.use();

//   const containerCss = css({
//     backgroundColor: Theme.colors.lightblue,
//     flex: 1,
//     display: "flex",
//     flexDirection: "column"
//   })

//   const contentCss = css({
//     flexGrow: 1
//   })

//   const formCss = css({
//     width: "50%",
//     maxWidth: 390,
//     paddingInline: 30,
//     margin: "auto",
//     marginTop: 40
//   })

//   const footerCss = css({
//     backgroundColor: Theme.colors.white,
//     height: Theme.sizes.bottombarHeight,
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     paddingInline: 30
//   })

//   const [token, setToken] = useState<string>("");

//   const onSubmit = useCallback(
//     (e: FormEvent<HTMLFormElement>) => {
//       e.preventDefault();
//       if (!token) return;
//       authorization.setTokens(token);
//     },
//     [token]
//   );

//   return (
//     <div css={containerCss}>
//       <div css={contentCss}>
//         <form css={formCss} onSubmit={onSubmit}>
//           <Typo name="heading" center color={Theme.colors.blue}>
//             Connexion
//           </Typo>
//           <Spacer size={30} />
//           <TextField value={token} onChange={setToken} label="Jeton d'identification" />
//           <Spacer size={30} />
//           <Buttons align="center">
//             <Button2 label="Entrer" submit layout="solid" color={Theme.colors.blue} centered />
//           </Buttons>
//         </form>
//       </div>
//       <div css={footerCss}>
//         <Typo name="minor">Une solution proposée par</Typo>
//         <Spacer size={30} />
//         <Asset name="noese" />
//       </div>
//     </div>
//   );
// }
