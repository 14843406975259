type Asset = {
  path1x: string;
  path2x: string;
  path3x: string;
  alernativeText: string;
  width: number;
  height: number;
};

const PUBLIC_URL = process.env.PUBLIC_URL;

const logo: Asset = {
  path1x: `${PUBLIC_URL}/assets/logo@1x.png`,
  path2x: `${PUBLIC_URL}/assets/logo@2x.png`,
  path3x: `${PUBLIC_URL}/assets/logo@3x.png`,
  alernativeText: "Logo Lina",
  width: 235,
  height: 49,
};

const noese: Asset = {
  path1x: `${PUBLIC_URL}/assets/noese@1x.png`,
  path2x: `${PUBLIC_URL}/assets/noese@2x.png`,
  path3x: `${PUBLIC_URL}/assets/noese@3x.png`,
  alernativeText: "Logo Noèse",
  width: 97,
  height: 26,
};

const defaultAvatar: Asset = {
  path1x: `${PUBLIC_URL}/assets/defaultAvatar@1x.png`,
  path2x: `${PUBLIC_URL}/assets/defaultAvatar@2x.png`,
  path3x: `${PUBLIC_URL}/assets/defaultAvatar@3x.png`,
  alernativeText: "Personne",
  width: 100,
  height: 100,
};

const dot: Asset = {
  path1x: `${PUBLIC_URL}/assets/dot@1x.png`,
  path2x: `${PUBLIC_URL}/assets/dot@2x.png`,
  path3x: `${PUBLIC_URL}/assets/dot@3x.png`,
  alernativeText: "Point",
  width: 16,
  height: 16,
};

const Assets = { logo, defaultAvatar, noese, dot };

export function useBackgroundAsset(asset: Asset) {
  return `background-image: image-set(
    url("${asset.path1x}") 1x,
    url("${asset.path2x}") 2x,
    url("${asset.path3x}") 3x);`;
}

export type AssetName = keyof typeof Assets;

export default Assets;
