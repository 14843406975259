import { DependencyList, useEffect } from "react";
import createRequiredContext from "../createRequiredContext";
import findInMap from "../findInMap";
import Updater from "../Updater";
import { TreeStepperColumnContext } from "./TreeStepperColumn";
import { TreeStepperItemContext } from "./TreeStepperItem";
import {
  ColumnExtractorFn,
  ColumnKeyExtractor,
  ItemKeyExtractor,
  ItemsExtractorFn,
} from "./types";

export type ColumnWrapper<TColumn> = {
  index: number;
  key: string;
  data: TColumn;
  items: Array<string>;
  selectedItem: string | null;
  width: number | null;
  offset: number;
  headerHeight: number;
};

export type ItemWrapper<TItem> = {
  index: number;
  key: string;
  data: TItem;
  parentColumn: string;
  height: number | null;
};

export type TreeStepperControllerConfig<TColumn, TItem> = {
  column: TColumn;
  itemsExtractor: ItemsExtractorFn<TColumn, TItem>;
  columnExtractor: ColumnExtractorFn<TColumn, TItem>;
  columnKeyExtractor: ColumnKeyExtractor<TColumn>;
  itemKeyExtractor: ItemKeyExtractor<TItem>;
  onCompute?: () => void;
  minimumOffset?: number;
};

export class TreeStepperController<TColumn, TItem> {
  constructor(readonly config: TreeStepperControllerConfig<TColumn, TItem>) {
    this.writeColumn(0, this.config.column);
  }

  // Updater

  readonly updater = new Updater();

  render() {
    this.updater.update();
  }

  compute() {
    this.writeColumn(0, this.config.column);
    this.computeOffsets();
    this.render();
  }

  writeColumn(index: number, data: TColumn) {
    const {
      itemKeyExtractor,
      columnKeyExtractor,
      itemsExtractor,
      columnExtractor,
    } = this.config;
    const key = columnKeyExtractor(data);
    const existingWrapper = this.columnWrappers.get(key);
    const items = itemsExtractor(data, this);
    const itemsKeys = items.map(itemKeyExtractor);
    let selectedItem = existingWrapper ? existingWrapper.selectedItem : null;
    if (selectedItem && !itemsKeys.includes(selectedItem)) selectedItem = null;
    const headerHeight = existingWrapper ? existingWrapper.headerHeight : 0;
    const width = existingWrapper ? existingWrapper.width : null;
    let offset: number = 0;
    if (existingWrapper) {
      offset = existingWrapper.offset;
    } else {
      if (index === 0) {
        offset = 0;
      } else {
        offset = findInMap(this.columnWrappers, this.columns[index - 1]).offset;
      }
    }

    const columnWrapper: ColumnWrapper<TColumn> = {
      index,
      key,
      data,
      items: itemsKeys,
      selectedItem,
      headerHeight,
      width,
      offset,
    };
    this.columnWrappers.set(columnWrapper.key, columnWrapper);
    this.columns = this.columns.slice(0, index);
    this.columns.push(key);

    items.forEach((item, index) => {
      this.writeItem(index, item, columnWrapper);
    });

    const nextColumn = columnExtractor(data, this);
    if (nextColumn) {
      this.writeColumn(index + 1, nextColumn);
    }
    return key;
  }

  writeItem(index: number, data: TItem, parentColumn: ColumnWrapper<TColumn>) {
    const { itemKeyExtractor } = this.config;
    const key = itemKeyExtractor(data);
    const existingWrapper = this.itemWrappers.get(key);
    const itemWrapper: ItemWrapper<TItem> = {
      index,
      key,
      data,
      parentColumn: parentColumn.key,
      height: existingWrapper ? existingWrapper.height : null,
    };
    this.itemWrappers.set(itemWrapper.key, itemWrapper);

    return key;
  }

  // Updater

  useColumnSelector<TSelection>(
    fn: (c: this, columnKey: string) => TSelection,
    deps: DependencyList = []
  ): TSelection {
    const columnKey = TreeStepperColumnContext.use();
    return this.updater.useValue(
      () => fn(this, columnKey),
      [columnKey, ...deps]
    );
  }

  useItemSelector<TSelection>(
    fn: (c: this, itemKey: string, columnKey: string) => TSelection,
    deps: DependencyList = []
  ): TSelection {
    const columnKey = TreeStepperColumnContext.use();
    const itemKey = TreeStepperItemContext.use();
    return this.updater.useValue(
      () => fn(this, itemKey, columnKey),
      [columnKey, itemKey, ...deps]
    );
  }

  // Columns

  readonly columnWrappers: Map<string, ColumnWrapper<TColumn>> = new Map();
  readonly itemWrappers: Map<string, ItemWrapper<TItem>> = new Map();
  private columns: Array<string> = [];

  findColumn(columnKey: string) {
    return findInMap(this.columnWrappers, columnKey);
  }

  findColumnIndex(columnKey: string) {
    return findInMap(this.columnWrappers, columnKey).index;
  }

  findPreviousColumn(columnKey: string) {
    const index = findInMap(this.columnWrappers, columnKey).index;
    if (index === 0) throw new Error("No previous column");
    return this.columns[index - 1];
  }

  findColumnData(columnKey: string) {
    return this.findColumn(columnKey).data;
  }

  getColumnsWrappers() {
    return this.columns.map((c) => this.findColumn(c));
  }

  getColumnItems(columnKey: string) {
    return findInMap(this.columnWrappers, columnKey).items;
  }

  getColumnDistanceFromLastOne(columnKey: string) {
    const index = this.columns.indexOf(columnKey);
    if (index === -1) return Infinity;
    const maxIndex = this.columns.length - 1;
    return maxIndex - index;
  }

  // Items

  getColumnItemsWrappers(columnKey: string) {
    const column = this.findColumn(columnKey);
    const items = column.items.map((i) => findInMap(this.itemWrappers, i));
    return items;
  }

  findItemData(itemKey: string) {
    return findInMap(this.itemWrappers, itemKey).data;
  }

  // Selection

  getSelectedItem(columnKey: string) {
    return findInMap(this.columnWrappers, columnKey).selectedItem;
  }

  isItemSelected(itemKey: string) {
    const item = findInMap(this.itemWrappers, itemKey);
    const column = findInMap(this.columnWrappers, item.parentColumn);
    return column.selectedItem === itemKey;
  }

  setSelectedItem(key1: string, key2?: string | null) {
    let columnKey: string;
    let itemKey: string | null;
    if (key2 === undefined) {
      itemKey = key1;
      columnKey = findInMap(this.itemWrappers, itemKey).parentColumn;
    } else {
      columnKey = key1;
      itemKey = key2;
    }
    const column = findInMap(this.columnWrappers, columnKey);
    column.selectedItem = itemKey;
    this.compute();
  }

  selectItem(itemKey: string) {
    const item = findInMap(this.itemWrappers, itemKey);
    const column = findInMap(this.columnWrappers, item.parentColumn);
    column.selectedItem = itemKey;
    this.compute();
  }

  selectNoItem(columnKey: string) {
    const column = findInMap(this.columnWrappers, columnKey);
    column.selectedItem = null;
    this.compute();
  }

  // Dimensions

  useColumnWidth(columnKey: string, width: number) {
    useEffect(() => {
      const column = findInMap(this.columnWrappers, columnKey);
      column.width = width;
      this.compute();
    }, [columnKey, width]);
  }

  useColumnHeaderHeight(columnKey: string, height: number) {
    useEffect(() => {
      const column = findInMap(this.columnWrappers, columnKey);
      if (column.headerHeight !== height) {
        column.headerHeight = height;
        this.computeOffsets();
      }
    }, [columnKey, height]);
  }

  useItemHeight(itemKey: string, height: number) {
    useEffect(() => {
      const item = findInMap(this.itemWrappers, itemKey);
      if (item.height !== height) {
        item.height = height;
        this.computeOffsets();
      }
    }, [itemKey, height]);
  }

  findColumnOffset(columnKey: string) {
    return findInMap(this.columnWrappers, columnKey).offset;
  }

  computeOffsets() {
    const focusTops = this.columns.map((id) => {
      const wrapper = this.findColumn(id);
      const selectedItem = wrapper.selectedItem;
      let focusTop = wrapper.headerHeight || 0;
      if (selectedItem)
        focusTop += this.getCumulatedItemsHeight(id, selectedItem);
      return focusTop;
    });

    const maxFocusTop = Math.max(...focusTops);

    const naturalOffsets = this.columns.map((id, i) => {
      const focusTop = focusTops[i];
      return maxFocusTop - focusTop;
    });

    const maximalNaturalOffset = Math.max(...naturalOffsets);
    const minimumOffset = this.config.minimumOffset || 0;

    const offsetCorrection =
      maximalNaturalOffset < minimumOffset
        ? minimumOffset - maximalNaturalOffset
        : 0;

    const correctedOffsets = naturalOffsets.map((o) => o + offsetCorrection);

    this.columns.forEach((id, i) => {
      const wrapper = this.findColumn(id);
      wrapper.offset = correctedOffsets[i];
    });

    this.render();
  }

  private getCumulatedItemsHeight(
    columnKey: string,
    itemKey: string | null = null
  ) {
    const column = findInMap(this.columnWrappers, columnKey);
    const items = column.items.map((i) => findInMap(this.itemWrappers, i));
    let height: number = 0;
    for (let item of items) {
      if (item.key === itemKey) break;
      height += item.height || 0;
    }
    return height;
  }

  findItemHeight(itemKey: string) {
    return findInMap(this.itemWrappers, itemKey).height;
  }

  findItemTop(itemKey: string) {
    const itemWrapper = findInMap(this.itemWrappers, itemKey);
    return (
      this.findColumnTop(itemWrapper.parentColumn) +
      this.getCumulatedItemsHeight(itemWrapper.parentColumn, itemKey)
    );
  }

  findColumnTop(columnKey: string) {
    const columnWrapper = findInMap(this.columnWrappers, columnKey);
    return columnWrapper.offset + columnWrapper.headerHeight;
  }

  findItemIndex(itemKey: string) {
    return findInMap(this.itemWrappers, itemKey).index;
  }
}

export const TreeStepperContext =
  createRequiredContext<TreeStepperController<any, any>>();
