import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import {
  BotError,
  InvalidRedirection,
} from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import ActionBubble from "./ActionBubble";
import { TargetableItem } from "./types";

export type GoToTarget = { script: string; step: string };

export default class GotoBubble extends ActionBubble {
  isEnding(): boolean {
    return true;
  }

  getAction() {
    return "GTO" as const;
  }

  getIcon(): NoeseIcons {
    return "subdirectory-arrow-left";
  }

  countTranslations(locale: Locale, emptyOnes: boolean): number {
    return 0;
  }

  getTarget(): GoToTarget | null {
    const action_args = this.getActionArgs();
    if (action_args === null) return null;
    const [script, step] = action_args.split("#");
    if (script === undefined || step === undefined) return null;
    return { script, step };
  }

  setTarget(target: GoToTarget | null) {
    if (target) this.setActionArgs(`${target.script}#${target.step}`);
    else this.setActionArgs(null);
  }

  getErrors() {
    const errors: Array<BotError> = [];
    const target = this.getTarget();
    if (target === null) errors.push(new InvalidRedirection(this));
    return errors;
  }

  getTargetableItems(): Array<TargetableItem> {
    return [];
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    if (steps.length !== 1) {
      throw new BotStructureError(
        "Creating action bubble from children that doesn't have 1 (and only 1) item",
        { steps }
      );
    }

    const step = steps[0];
    if (step.data.kind !== "AA") {
      throw new BotStructureError("Creating action bubble from a non-AA step", {
        step,
      });
    }

    if (step.data.action !== "GTO") {
      throw new BotStructureError(
        "Creating action bubble from a non-AA GTO step",
        { step }
      );
    }

    const me = new GotoBubble(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.setActionArgs(step.data.action_args);

    return me;
  }
}
