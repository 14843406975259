/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Locale } from "src/utilities/Locales";

type FlagProps = {
  code: Locale;
  size: number;
  country?: string;
};

export default function Flag(props: FlagProps) {
  const { code, size, country } = props;

  const imgCss = css({
    display: "block",
    transition: "all 100ms",
    width: size,
    height: size,
  });
  const url = `/assets/flags/flag-${code}.png`;

  return <img css={imgCss} src={url} alt={country}></img>;
}
