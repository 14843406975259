import { sum, without } from "lodash";
import { BotPlayerTypes } from "src/player/types";

import { BotError, DeadPath } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import NonEmptyArray from "../NonEmptyArray";
import ActionsIntervention from "./ActionsIntervention";
import ChoiceBubble from "./ChoiceBubble";
import ChoicesIntervention from "./ChoicesIntervention";
import EntryPoint from "./EntryPoint";
import Intervention from "./Intervention";
import MessageBubble from "./MessageBubble";
import { TargetableItem } from "./types";

export default class MessagesIntervention extends Intervention {
  // Parent

  private parentId: string | null = null;

  set parent(parent: EntryPoint | ChoiceBubble | ActionsIntervention) {
    this.parentId = parent.id;
  }

  get parent() {
    if (this.parentId === null)
      throw new Error("MessagesIntervention has no parentIntervention");
    const parent = this.dialog.find(this.parentId);
    if (parent instanceof EntryPoint) return parent;
    else if (parent instanceof ChoiceBubble) return parent;
    else if (parent instanceof ActionsIntervention) return parent;
    throw new Error("Invalid MessagesIntervention parent");
  }

  findChainableRemoteId() {
    return this.lastBubble.findRemoteId();
  }

  // Registration

  isRegistrable(): boolean {
    if (!this.parentId) return false;
    return true;
  }

  isUnregistrable(): boolean {
    if (this.parentId !== null) return false;
    return true;
  }

  // Ahead

  private aheadId: string | null = null;

  set ahead(ahead: ChoicesIntervention | ActionsIntervention | null) {
    if (ahead === null) {
      this.aheadId = null;
    } else {
      this.aheadId = ahead.id;
      ahead.parent = this;
    }
  }

  get ahead() {
    if (this.aheadId === null) return null;
    const intervention = this.dialog.find(this.aheadId);
    if (intervention instanceof ChoicesIntervention) return intervention;
    else if (intervention instanceof ActionsIntervention) return intervention;
    throw new Error("Invalid MessagesIntervention ahead");
  }

  // Bubbles

  private bubblesIds: Array<string> = [];

  set bubbles(bubbles: NonEmptyArray<MessageBubble>) {
    this.bubblesIds = bubbles.map((b) => b.id);
  }

  get bubbles() {
    const bubbles = this.bubblesIds.map((id) => {
      const bubble = this.dialog.find(id);
      if (bubble instanceof MessageBubble) return bubble;
      throw new Error("Invalid MessageBubble bubbles type");
    });
    if (bubbles.length === 0) throw new Error("Empty bubbles");
    return bubbles as NonEmptyArray<MessageBubble>;
  }

  get lastBubble() {
    const lastBubbleId = this.bubblesIds[this.bubblesIds.length - 1];
    if (lastBubbleId === undefined)
      throw new Error("No bubbles in MessageIntervention");
    const bubble = this.dialog.find(lastBubbleId);
    if (bubble instanceof MessageBubble) return bubble;
    throw new Error("Invalid last Bubble in MessageIntervention");
  }

  appendBubble(bubble: MessageBubble) {
    this.bubblesIds.push(bubble.id);
    bubble.intervention = this;
  }

  injectBubbleAt(bubble: MessageBubble, index: number) {
    this.bubblesIds.splice(index, 0, bubble.id);
    bubble.intervention = this;
  }

  removeBubble(bubble: MessageBubble) {
    this.bubblesIds = without(this.bubblesIds, bubble.id);
    if (this.bubblesIds.length === 0) this.destroy();
  }

  // Weight

  getWeight(tracker: number = 0) {
    let weight = tracker;
    weight += this.bubbles[0].getWeight(tracker);
    return weight;
  }

  countTranslations(locale: Locale, emptyOnes: boolean) {
    let nb = sum(
      this.bubbles.map((b) => b.countTranslations(locale, emptyOnes))
    );
    if (this.ahead) nb += this.ahead.countTranslations(locale, emptyOnes);
    return nb;
  }

  getErrors() {
    const errors: Array<BotError> = [];
    this.bubbles.forEach((b) => errors.push(...b.getErrors()));
    if (!this.ahead) errors.push(new DeadPath(this));
    if (this.ahead) errors.push(...this.ahead.getErrors());
    return errors;
  }

  getTargetableItems() {
    const output: Array<TargetableItem> = [];
    this.bubbles.forEach((b) => output.push(...b.getTargetableItems()));
    if (this.ahead) output.push(...this.ahead.getTargetableItems());
    return output;
  }

  // Destroy

  destroy() {
    const parent = this.parent;
    if (parent instanceof EntryPoint) {
      throw new Error("Cannot remove initial message");
    }
    parent.ahead = this.ahead;
    this.parentId = null;
    this.unregister();
  }

  // Export to draft

  toDraftSteps(): Array<DraftBot.Step> {
    return this.bubbles[0].toDraftSteps();
  }

  // Export to bundle

  toBundleSteps(locale: Locale): Array<BotPlayerTypes.Step> {
    return this.bubbles[0].toBundleSteps(locale);
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    const me = new MessagesIntervention(dialog);

    // Bubbles
    const bubble = MessageBubble.fromSteps(steps, dialog);
    me.appendBubble(bubble);
    bubble.register();

    let children = steps[0].children || [];
    while (true) {
      try {
        const bubble = MessageBubble.fromSteps(children, dialog);
        me.appendBubble(bubble);
        bubble.register();
        children = children[0].children || [];
      } catch (err) {
        break;
      }
    }

    // Ahead

    if (children.length === 0) {
      me.ahead = null;
    } else {
      const firstChildren = children[0];
      if (firstChildren.data.kind === "CH") {
        const intervention = ChoicesIntervention.fromSteps(children, dialog);
        me.ahead = intervention;
        intervention.register();
      } else if (firstChildren.data.kind === "AA") {
        const intervention = ActionsIntervention.fromSteps(children, dialog);
        me.ahead = intervention;
        intervention.register();
      } else {
        throw new BotStructureError(
          "Last message of a chain of message can only have CH steps or AA step as children",
          { step: children }
        );
      }
    }

    return me;
  }

  // static after(
  //   parent: EntryPoint | ChoiceBubble | ActionsIntervention,
  //   dialog: BotDialog
  // ) {
  //   if (parent.ahead !== null) throw new Error("Parent already has an ahead");
  //   const intervention = new MessagesIntervention(dialog);
  //   MessageBubble.atTheEndOf(intervention, dialog);
  //   parent.ahead = intervention;
  //   intervention.parent = parent;
  //   intervention.register();
  //   return intervention;
  // }
}
