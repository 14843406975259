import { DependencyList, useEffect, useRef, useState } from "react";

export default function useMemoState<V>(
  getter: (current: V | undefined) => V,
  deps: DependencyList
) {
  const mountPassed = useRef<boolean>(false);
  const [state, setState] = useState<V>(() => getter(undefined));

  useEffect(() => {
    if (!mountPassed.current) {
      mountPassed.current = true;
      return;
    }
    setState((c) => getter(c));
  }, deps);

  return [state, setState] as const;
}
