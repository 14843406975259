import {BotPlayerTypes} from './types';

export function during(duration: number) {
  return new Promise(r => setTimeout(r, duration));
}

export function isStartSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return steps.length === 1 && steps[0].data.kind === 'RT';
}

export function isMessageStep(steps: Array<BotPlayerTypes.Step>): boolean {
  return steps.length === 1 && steps[0].data.kind === 'BI';
}

export function isChoicesSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return steps.length >= 1 && steps[0].data.kind === 'CH';
}

export function isUrlSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return (
    steps.length === 1 &&
    steps[0].data.kind === 'AA' &&
    steps[0].data.action === 'URL'
  );
}

export function isPhoneSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return (
    steps.length === 1 &&
    steps[0].data.kind === 'AA' &&
    steps[0].data.action === 'CAL'
  );
}

export function isEmailSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return (
    steps.length === 1 &&
    steps[0].data.kind === 'AA' &&
    steps[0].data.action === 'EMA'
  );
}

export function isTranslateSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return (
    steps.length === 1 &&
    steps[0].data.kind === 'AA' &&
    steps[0].data.action === 'I18N'
  );
}

export function isGotoSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return (
    steps.length === 1 &&
    steps[0].data.kind === 'AA' &&
    steps[0].data.action === 'GTO'
  );
}

export function isStopSteps(steps: Array<BotPlayerTypes.Step>): boolean {
  return (
    steps.length === 1 &&
    steps[0].data.kind === 'AA' &&
    steps[0].data.action === 'STOP'
  );
}
