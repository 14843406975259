import ChatbotLines from "src/utilities/ChatbotLines";
import Stroke from "src/utilities/Stroke";
import {
  depthBlockPadding,
  depthHeight,
  depthInlinePadding,
  depthWidth,
  useTranslationController,
} from "../utils";

export default function FromParentStroke() {
  const controller = useTranslationController();

  const { heightToParent } = controller.useFileSelector((c, key) => {
    const containingElement = c.getContainingFile(key);
    const heightToParent = containingElement
      ? c.getCumulatedHeight(containingElement, key, true)
      : 0;
    return { heightToParent };
  }, []);

  return (
    <Stroke
      path={`M ${depthInlinePadding} ${depthHeight / 2} L ${depthWidth / -2} ${
        depthHeight / 2
      } L ${depthWidth / -2} ${
        heightToParent * -1 + depthHeight - depthBlockPadding
      }`}
      {...ChatbotLines.getTranslationStrokeProps()}
    />
  );
}
