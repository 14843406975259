import { nanoid } from "nanoid";
import BotDialog from ".";

export default abstract class Intervention {
  readonly dialog: BotDialog;
  readonly id: string;

  constructor(dialog: BotDialog) {
    this.id = nanoid();
    this.dialog = dialog;
  }

  // Registration

  abstract isRegistrable(): boolean;

  register() {
    if (!this.isRegistrable()) throw new Error("Item is not registrable");
    this.dialog.register(this);
  }

  abstract isUnregistrable(): boolean;

  unregister() {
    if (!this.isUnregistrable()) throw new Error("Item is not unregistrable");
    this.dialog.unregister(this);
  }

  // Restore mecanism

  protected restorer: (() => any) | null = null;

  restore() {
    if (!this.restorer) throw new Error("No restore fn");
    this.restore();
  }
}
