/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Typos from "src/utilities/Typos";
import AutoHeightInput, { AutoHeightInputProps } from "../AutoHeightInput";

type NodeInputProps = AutoHeightInputProps & {
  emojis?: boolean;
};

export default function NodeInput(props: NodeInputProps) {
  const { emojis, ...autoHeightProps } = props;

  const containerCss = css`
    background-color: white;
    border-radius: 8px;
  `;

  const paddingCss = css({
    paddingBlock: 8,
    paddingInline: 8,
  });

  const typoCss = css(Typos.bold, {
    color: "black",
  });

  return (
    <AutoHeightInput
      {...autoHeightProps}
      containerCss={containerCss}
      typoCss={typoCss}
      paddingCss={paddingCss}
    />
  );
}
