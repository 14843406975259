import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import { BotError } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import ActionBubble from "./ActionBubble";
import { TargetableItem } from "./types";

export default class TranslateBubble extends ActionBubble {
  isEnding(): boolean {
    return false;
  }

  getAction() {
    return "I18N" as const;
  }

  getIcon(): NoeseIcons {
    return "translate";
  }

  countTranslations(locale: Locale, emptyOnes: boolean): number {
    return 0;
  }

  getErrors() {
    const errors: Array<BotError> = [];
    return errors;
  }

  getTargetableItems(): Array<TargetableItem> {
    return [this];
  }

  getActionArgs() {
    return super.getActionArgs() as Locale | null;
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    if (steps.length !== 1) {
      throw new BotStructureError(
        "Creating action bubble from children that doesn't have 1 (and only 1) item",
        { steps }
      );
    }

    const step = steps[0];
    if (step.data.kind !== "AA") {
      throw new BotStructureError("Creating action bubble from a non-AA step", {
        step,
      });
    }

    if (step.data.action !== "I18N") {
      throw new BotStructureError(
        "Creating action bubble from a non-AA I18N step",
        { step }
      );
    }

    const me = new TranslateBubble(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.setActionArgs(step.data.action_args);

    return me;
  }
}
