/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

type DividerProps = {
  color: string;
  grow?: boolean;
  size?: number;
  thickness?: number;
  vertical?: boolean;
};

export default function Divider(props: DividerProps) {
  const { color, size, grow, thickness = 1, vertical } = props;

  const pxSize = size || undefined;
  const dividerCss = css({
    width: vertical ? thickness : pxSize,
    height: vertical ? pxSize : thickness,
    flexGrow: grow ? 1 : 0,
    border: "none",
    background: color,
    padding: 0,
    margin: 0,
  });

  return <hr css={dividerCss} />;
}
