/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useCallback, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import StopBubble from "src/utilities/BotDialog/StopBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import { useChatbotTreeStepperController } from "..";
import { feedback } from "../feedbacks";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function StopBubbleView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();

  const { bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as StopBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const containerCss = css({
    borderRadius: 10,
    background: Theme.colors.pink,
    color: Theme.colors.white,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeActionBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon={bubble.getIcon()}
              title="Fin de la discussion"
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <Revealable revealed={selected}>
              <Spacer size={10} />
              <NodeFooter />
            </Revealable>
          </div>
        </div>
      </AllStepsView>
      <Spacer size={10} />
    </Fragment>
  );
}
