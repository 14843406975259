import { css } from "@emotion/react";

const outer = css({
  borderRadius: 4,
});
const inner = css({});
const content = css({
  paddingInline: 4,
});

const inputSavingIndicator = css`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const actionNodeContainer = css();

const styles = {
  outer,
  inner,
  content,
  inputSavingIndicator,
  actionNodeContainer,
};

export default styles;
