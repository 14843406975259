import { Execution } from "src/utilities/Executions";
import ModelMetadataManager from "src/utilities/ModelMetadataManager";
import Toasts from "src/utilities/Toasts";
import { TreeStepperController } from "src/utilities/TreeStepper/TreeStepperController";
import { Column, Item, ItemMetadata } from "./config";

export function feedback(
  controller: TreeStepperController<Column, Item>,
  execution: Execution
) {
  execution.events.on("commit-done", () => controller.compute());
  execution.events.on("merge-done", () => controller.compute());
  execution.events.on("revert-done", () => controller.compute());
  execution.events.on("failed", (err) => Toasts.show(Toasts.fromError(err)));
}

export function feedbackItem(
  metadata: ModelMetadataManager<ItemMetadata>,
  execution: Execution,
  itemKey: string
) {
  execution.events.on("push-started", () =>
    metadata.update(itemKey, (c) => ({ ...c, loading: true }))
  );
  execution.events.on("push-ended", () =>
    metadata.update(itemKey, (c) => ({ ...c, loading: false }))
  );
}
