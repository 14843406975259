/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ActionBubble from "src/utilities/BotDialog/ActionBubble";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import ChoicesIntervention from "src/utilities/BotDialog/ChoicesIntervention";
import DataActionBubble from "src/utilities/BotDialog/DataActionBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import { BotManagerContext } from "src/utilities/BotManager";
import createRequiredContext from "src/utilities/createRequiredContext";
import DelayedZone from "src/utilities/DelayedZone";
import { Locale } from "src/utilities/Locales";
import Zones from "src/utilities/Navigation";
import TreeStructure from "src/utilities/TreeStructure";
import TreeStructureController from "src/utilities/TreeStructure/TreeStructureController";
import {
  FileKeyExtractor,
  FilesExtractor,
} from "src/utilities/TreeStructure/types";
import Header from "./Header";
import TranslationSequence from "./TranslationSequence";
import {
  ChatbotTranslatorStructureFile,
  FilesHover,
  FilesSelection,
} from "./utils";

const fileKeyExtractor: FileKeyExtractor<ChatbotTranslatorStructureFile> = (
  s
) => s.id.toString();

const filesExtractor: FilesExtractor<ChatbotTranslatorStructureFile> = (s) => {
  let ahead:
    | ChoicesIntervention
    | MessagesIntervention
    | ActionsIntervention
    | null = null;

  if (s instanceof ChoiceBubble) {
    ahead = s.ahead;
  } else if (s instanceof MessageBubble) {
    if (s.isLast) {
      ahead = s.intervention.ahead;
    }
  } else if (s instanceof ActionBubble) {
    if (s.isLast) {
      ahead = s.intervention.ahead;
    }
  }

  if (ahead === null) {
    return null;
  } else if (ahead instanceof ActionsIntervention) {
    return ahead.bubbles.filter(
      (b) => b instanceof DataActionBubble
    ) as Array<ChatbotTranslatorStructureFile>;
  } else {
    return ahead.bubbles;
  }
};

export default function ChatbotTranslatorStructure() {
  const manager = BotManagerContext.use();

  const otherLocale = Zones.useParam("locale") as Locale;

  const loading = useMemo(
    () => manager.loadTranslations(otherLocale),
    [otherLocale]
  );

  return (
    <FilesHover.Provider value={FilesHover}>
      <FilesSelection.Provider value={FilesSelection}>
        <DelayedZone promise={loading}>
          {() => <Table key={otherLocale} />}
        </DelayedZone>
      </FilesSelection.Provider>
    </FilesHover.Provider>
  );
}

function Table() {
  const manager = BotManagerContext.use();

  const containerCss = css({
    display: "grid",
    gridTemplateColumns: "4fr 4fr 3fr",
  });

  const onMouseLeave = useCallback(() => {
    FilesHover.select(null);
  }, []);

  const controllerRef =
    useRef<TreeStructureController<ChatbotTranslatorStructureFile>>();

  useEffect(() => {
    if (controllerRef.current) {
      const unsub = controllerRef.current.updater.onUpdate(() => {
        manager.updater.update();
      });
      return unsub;
    }
  }, []);

  const rootFiles = useMemo(() => {
    const entryPoint = manager.dialog.getEntryPoint();
    const firstMessages = entryPoint.ahead;
    if (firstMessages) return firstMessages.bubbles;
    else return [];
  }, []);

  return (
    <div css={containerCss} onMouseLeave={onMouseLeave}>
      <Header />
      <TreeStructure
        fileKeyExtractor={fileKeyExtractor}
        filesExtractor={filesExtractor}
        controllerRef={controllerRef}
        rootFiles={rootFiles}
        FileComponent={TranslationSequence}
      />
    </div>
  );
}

export const ChatbotTranslatorLocaleContext = createRequiredContext<string>();
