/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useCallback } from "react";
import { useChatbotTreeStepperController } from "../ChatbotEditorStepper";
import { ItemsMetadataContext } from "../ChatbotEditorStepper/config";
import Clickable from "../Clickable";
import Icon from "../Icon";
import Spinner from "../Loading";
import Spacer from "../Spacer";
import Typo from "../Typo";
import LanguageSwitch from "./Fragments/LanguageSwitch";

type NodeFooterProps = {
  translationControl?: boolean;
};

export default function NodeFooter(props: NodeFooterProps) {
  const { translationControl = false } = props;

  const itemsMetadata = ItemsMetadataContext.use();
  const controller = useChatbotTreeStepperController();

  const { itemKey, interventionId, bubble } = controller.useItemSelector(
    (c, itemKey, columnKey) => ({
      itemKey,
      interventionId: columnKey,
      bubble: c.findItemData(itemKey),
    }),
    []
  );

  const { loading } = itemsMetadata.use(itemKey);

  const weight = bubble.getWeight() - 1;

  const containerCss = css({
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    alignItems: "center",
  });

  const languageCss = css({
    display: "flex",
  });

  const foldCss = css({
    display: "flex",
    justifyContent: "center",
  });

  // Weight

  const weightZoneCss = css({
    display: "flex",
    justifyContent: "flex-end",
    padding: 4,
    paddingInline: 8,
  });

  const weightCss = css({
    display: "flex",
    alignItems: "center",
  });

  const weightNode = (
    <div css={weightCss}>
      <Icon name="layers-triple-outline" typo="body" />
      <Spacer size={4} />
      <Typo name="body">{weight}</Typo>
    </div>
  );

  const onFold = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    controller.selectNoItem(interventionId);
  }, []);

  return (
    <div css={containerCss}>
      <div css={languageCss}>
        {translationControl ? <LanguageSwitch /> : null}
      </div>
      <div css={foldCss}>
        <Clickable onClick={onFold} stopPropagation>
          <Icon name="chevron-up" typo="body" />
        </Clickable>
      </div>
      <div css={weightZoneCss}>
        <Spinner active={loading} typo="body" />
        <Spacer size={4} />
        {weightNode}
      </div>
    </div>
  );
}
