/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useCallback } from "react";
import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import StepCreator from "src/components/Nodes/Fragments/StepCreator";
import PursuitButton from "src/components/Nodes/PursuitButton";
import Spacer from "src/components/Spacer";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import GotoBubble from "src/utilities/BotDialog/GotoBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import PhoneBubble from "src/utilities/BotDialog/PhoneBubble";
import StopBubble from "src/utilities/BotDialog/StopBubble";
import TranslateBubble from "src/utilities/BotDialog/TranslateBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import ChatbotLines from "src/utilities/ChatbotLines";
import { localeToText } from "src/utilities/Locales";
import useBooleanState from "src/utilities/useBooleanState";
import { useChatbotTreeStepperController } from "..";
import {
  EmptyColumn,
  InterventionViewProps,
  ItemsMetadataContext,
} from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromPreviousColumnToColumnLine from "../Lines/FromPreviousColumnToColumnLine";

export default function EmptyInteractionView(props: InterventionViewProps) {
  const controller = useChatbotTreeStepperController();

  const { column, target } = controller.useColumnSelector((c, columnKey) => {
    const column = c.findColumnData(columnKey) as EmptyColumn;
    const target = column.target;
    return { columnKey, column, target };
  }, []);

  const itemsMetadata = ItemsMetadataContext.use();

  const metadata = itemsMetadata.use(target.id);

  const linesWidth = ChatbotLines.useLinesWidth(column);

  controller.useColumnWidth(column.id, linesWidth + 300);

  let buttonIcon: NoeseIcons;
  let buttonText: string;
  let buttonInfo: string | undefined = undefined;

  if (target instanceof EntryPoint) {
    buttonIcon = "play-circle";
    buttonText = "Définir le premier message du bot";
  } else if (target instanceof MessagesIntervention) {
    const label = target.lastBubble.getLabel(metadata.locale);
    buttonIcon = "play-circle";
    buttonText = "Définir le comportement du bot";
    buttonInfo = `après le message "${label}"`;
  } else if (target instanceof ChoiceBubble) {
    const label = target.getLabel(metadata.locale);
    buttonIcon = "play-circle";
    buttonText = "Définir le comportement du bot";
    buttonInfo = `si l'utilisateur choisit "${label}"`;
  } else if (target instanceof ActionsIntervention) {
    const lastAction = target.lastBubble;
    if (lastAction instanceof UrlBubble) {
      const url = lastAction.getLabel(metadata.locale);
      buttonIcon = "play-circle";
      buttonText = "Définir le comportement du bot";
      buttonInfo = `lorsque l'utilisateur aura visité le site ${url}`;
    } else if (lastAction instanceof EmailBubble) {
      const email = lastAction.getLabel(metadata.locale);
      buttonIcon = "play-circle";
      buttonText = "Définir le comportement du bot";
      buttonInfo = `lorsque l'utilisateur aura envoyé un email à ${email}`;
    } else if (lastAction instanceof PhoneBubble) {
      const number = lastAction.getLabel(metadata.locale);
      buttonIcon = "play-circle";
      buttonText = "Définir le comportement du bot";
      buttonInfo = `lorsque l'utilisateur aura terminé l'appel avec ${number}`;
    } else if (lastAction instanceof TranslateBubble) {
      const locale = lastAction.getActionArgs();
      buttonIcon = "play-circle";
      buttonText = "Définir le comportement du bot";
      buttonInfo = locale
        ? `lorsqu'il discutera en ${localeToText(locale).toLocaleLowerCase()}`
        : `lorsqu'il aura changé de langue`;
    } else {
      throw new Error("Not handled");
    }
  } else {
    throw new Error("Not handled");
  }

  const [creatorVisible, setCreatorVisible] = useBooleanState(false);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    width: 300,
    marginLeft: linesWidth,
    transition: "margin-left 200ms, padding-top 200ms",
  });

  return (
    <Fragment>
      <div css={containerCss}>
        <FromPreviousColumnToColumnLine selected={false} virtual={true} />
        <PursuitButton
          icon={buttonIcon}
          label={buttonText}
          sublabel={buttonInfo}
          onClick={setCreatorVisible.toTrue}
        />
        <Spacer scale={2} />
        <EmptyColumnStepCreator
          target={target}
          visible={creatorVisible}
          onClose={setCreatorVisible.toFalse}
        />
      </div>
    </Fragment>
  );
}

type EmptyColumnStepCreatorProps = {
  target:
    | EntryPoint
    | ChoiceBubble
    | MessagesIntervention
    | ActionsIntervention;
  visible: boolean;
  onClose: () => any;
};

function EmptyColumnStepCreator(props: EmptyColumnStepCreatorProps) {
  const { target, visible, onClose } = props;

  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const isEntryPoint = target instanceof EntryPoint;
  const isMessagesIntervention = target instanceof MessagesIntervention;
  const isChoiceBubble = target instanceof ChoiceBubble;
  const isActionsIntervention = target instanceof ActionsIntervention;

  const addBotInteraction =
    isEntryPoint || isChoiceBubble || isActionsIntervention;
  const onAddBotInteraction = useCallback(() => {
    if (!addBotInteraction) return;
    onClose();
    const execution = manager.createMessagesIntervention(target);
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const addUserChoice =
    isMessagesIntervention || isChoiceBubble || isActionsIntervention;
  const onAddUserChoice = useCallback(() => {
    if (!addUserChoice) return;
    onClose();
    const execution = manager.createChoicesIntervention(target);
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const addAction = isMessagesIntervention || isChoiceBubble;
  const onAddUrlAction = useCallback(() => {
    if (!addAction) return;
    onClose();
    const execution = manager.createActionsIntervention(
      target,
      new UrlBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const onAddEmailAction = useCallback(() => {
    if (!addAction) return;
    onClose();
    const execution = manager.createActionsIntervention(
      target,
      new EmailBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const onAddPhoneAction = useCallback(() => {
    if (!addAction) return;
    onClose();
    const execution = manager.createActionsIntervention(
      target,
      new PhoneBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const onAddGotoAction = useCallback(() => {
    if (!addAction) return;
    onClose();
    const bubble = new GotoBubble(null);
    const bot = manager.bot.uuid;
    const entryPoint = manager.dialog.getEntryPoint().findRemoteId();
    bubble.setActionArgs(`${bot}#${entryPoint}`);
    const execution = manager.createActionsIntervention(target, bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const onAddTranslateAction = useCallback(() => {
    if (!addAction) return;
    onClose();
    const execution = manager.createActionsIntervention(
      target,
      new TranslateBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  const onAddStopAction = useCallback(() => {
    if (!addAction) return;
    onClose();
    const execution = manager.createActionsIntervention(
      target,
      new StopBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", (intervention) => {
      const id = intervention.bubbles[0].id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [target, onClose]);

  return (
    <StepCreator
      visible={visible}
      onClose={onClose}
      onAddBotInteraction={addBotInteraction ? onAddBotInteraction : undefined}
      onAddUserChoice={addUserChoice ? onAddUserChoice : undefined}
      onAddUrlAction={addAction ? onAddUrlAction : undefined}
      onAddEmailAction={addAction ? onAddEmailAction : undefined}
      onAddPhoneAction={addAction ? onAddPhoneAction : undefined}
      onAddGotoAction={addAction ? onAddGotoAction : undefined}
      onAddTranslateAction={addAction ? onAddTranslateAction : undefined}
      onAddStopAction={addAction ? onAddStopAction : undefined}
    />
  );
}
