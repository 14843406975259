import Spacer from "src/components/Spacer";
import { depthWidth, useTranslationController } from "../utils";

export default function Depth() {
  const controller = useTranslationController();

  const { depth } = controller.useFileSelector((c, key) => {
    return {
      depth: c.findFileDepth(key),
    };
  }, []);

  return <Spacer size={depth * depthWidth} direction="horizontal" />;
}
