/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TypoDescriptors } from "src/utilities/Typos";
import Clickable, { ClickableProps, isClickable } from "./Clickable";
import Icon from "./Icon";
import NoeseIcons from "./Icon/NoeseIcons/NoeseIcons.generated";
import Spacer from "./Spacer";
import Typo from "./Typo";

type Button2Props = ClickableProps & {
  label: string;
  icon?: NoeseIcons;
  color: string;
  layout: "solid" | "outline";
  loading?: boolean;
};

export default function Button2(props: Button2Props) {
  const { label, icon, color, layout, loading, ...clickableProps } = props;

  const clickable = isClickable(clickableProps);

  let containerCss = css({
    height: 44,
    borderRadius: 44 / 2,
    paddingInline: 44 / 2,
    display: "flex",
    alignItems: "center",
    filter: clickable ? "none" : "grayscale(1)",
    opacity: clickable ? 1 : 0.5,
  });

  if (layout === "outline") {
    containerCss = css(containerCss, {
      border: `1px solid ${color}`,
      background: "transparent",
      color: color,
    });
  } else {
    containerCss = css(containerCss, {
      border: `1px solid ${color}`,
      background: color,
      color: "white",
    });
  }

  const textAndIconCss = css({
    display: "flex",
    alignItems: "center",
  });

  let content = <Typo name="button">{loading ? "..." : label}</Typo>;
  if (icon)
    content = (
      <div css={textAndIconCss}>
        <Icon name={icon} typo="button" scale={1.3} />
        <Spacer size={TypoDescriptors.button.height / 2} />
        {content}
      </div>
    );

  return (
    <Clickable {...clickableProps} containerCss={containerCss}>
      {content}
    </Clickable>
  );
}
