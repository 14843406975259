/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback } from "react";
import { ColumnProps, ItemProps } from ".";
import createRequiredContext from "../createRequiredContext";
import { ColumnWrapper, TreeStepperContext } from "./TreeStepperController";
import TreeStepperItem from "./TreeStepperItem";

type TreeStepperColumnProps<TColumn> = {
  columnWrapper: ColumnWrapper<TColumn>;
  ColumnComponent: React.ComponentType<ColumnProps>;
  ItemComponent: React.ComponentType<ItemProps>;
};

export default function TreeStepperColumn<TColumn>(
  props: TreeStepperColumnProps<TColumn>
) {
  const { columnWrapper, ColumnComponent, ItemComponent } = props;

  const ItemsComponent = useCallback(() => {
    const controller = TreeStepperContext.use();

    const { items } = controller.useColumnSelector((c, columnKey) => {
      return {
        items: c.getColumnItemsWrappers(columnKey),
      };
    }, []);

    return (
      <div>
        {items.map((item) => (
          <TreeStepperItem
            key={item.key}
            itemWrapper={item}
            ItemComponent={ItemComponent}
          />
        ))}
      </div>
    );
  }, []);

  const offseterCss = css({
    height: columnWrapper.offset,
    transition: "height 200ms",
  });

  return (
    <div>
      <TreeStepperColumnContext.Provider value={columnWrapper.key}>
        <div css={offseterCss}></div>
        <ColumnComponent ItemsComponent={ItemsComponent} />
      </TreeStepperColumnContext.Provider>
    </div>
  );
}

export const TreeStepperColumnContext = createRequiredContext<string>();
