export type TreeChildrenExtractor<Payload> = (
  payload: Payload
) => Array<Payload> | Payload | null;

function searchItem<Payload>(
  payloads: Array<Payload>,
  childrenExtractor: TreeChildrenExtractor<Payload>,
  matchFn: (p: Payload) => boolean
): Payload | null {
  const validating = payloads.find(matchFn);
  if (validating) return validating;
  for (let item of payloads) {
    const children = extract(childrenExtractor, item);
    const match = searchItem(children, childrenExtractor, matchFn);
    if (match) return match;
  }
  return null;
}

function getNestedItems<Payload>(
  payloads: Array<Payload>,
  childrenExtractor: TreeChildrenExtractor<Payload>
): Array<Payload> {
  let output: Array<Payload> = [...payloads];
  for (let item of payloads) {
    const children = extract(childrenExtractor, item);
    output = [...output, ...getNestedItems(children, childrenExtractor)];
  }
  return output;
}

function getPath<Payload>(
  payloads: Array<Payload>,
  childrenExtractor: TreeChildrenExtractor<Payload>,
  matchFn: (p: Payload) => boolean,
  browsing: Array<Payload> = [],
  currentIndex: number = 0
): Array<Payload> | null {
  for (let item of payloads) {
    browsing[currentIndex] = item;
    const matches = matchFn(item);
    if (matches) {
      return browsing;
    } else {
      const children = extract(childrenExtractor, item);
      if (children.length > 0) {
        const foundInChildren = !!getPath(
          children,
          childrenExtractor,
          matchFn,
          browsing,
          currentIndex + 1
        );
        if (foundInChildren) {
          return browsing;
        }
      }
    }
  }
  browsing.splice(currentIndex, 1);
  return null;
}

function extract<Payload>(
  childrenExtractor: TreeChildrenExtractor<Payload>,
  item: Payload
): Array<Payload> {
  const extracted = childrenExtractor(item);
  if (extracted === null) return [];
  const children = Array.isArray(extracted) ? extracted : [extracted];
  return children;
}

const TreeExplorer = { searchItem, getNestedItems, getPath };

export default TreeExplorer;
