const colors = {
  // New
  blue: "#067D9C",
  lightblue: "#E1EFF3",
  darkblue: "#354E57",
  white: "#FFFFFF",
  teal: "#00C8CB",
  purple: "#8094EA",
  pink: "#EF6C8A",
  red: "#bf0000",
};

const Theme = {
  colors,
  sizes: {
    topbarHeight: 76,
    bottombarHeight: 76,
  },
};

export default Theme;
