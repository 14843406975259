import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import {
  BotError,
  InvalidFormat,
} from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import DataActionBubble from "./DataActionBubble";

export default class UrlBubble extends DataActionBubble {
  static action = "URL" as const;
  getAction() {
    return "URL" as const;
  }

  getErrors() {
    const errors: Array<BotError> = [...this.getMissingTranslationErrors()];
    const { natural_locale, other_locales } = this.dialog.manager.bot;
    const locales = [natural_locale, ...other_locales];
    locales.forEach((l) => {
      const url = this.getLabel(l);
      if (!url) return;
      let valid = false;
      try {
        new URL(url);
        valid = true;
      } catch (err) {
        valid = false;
      }
      if (!valid) {
        errors.push(new InvalidFormat(this, url, l));
      }
    });
    return errors;
  }

  getIcon(): NoeseIcons {
    return "step-url";
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    if (steps.length !== 1) {
      throw new BotStructureError(
        "Creating URL bubble from children that doesn't have 1 (and only 1) item",
        { steps }
      );
    }

    const step = steps[0];
    if (step.data.kind !== "AA") {
      throw new BotStructureError("Creating URL bubble from a non-AA step", {
        step,
      });
    }

    if (step.data.action !== "URL") {
      throw new BotStructureError(
        "Creating URL bubble from a non-AA URL step",
        { step }
      );
    }

    const me = new UrlBubble(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.setTargetable(step.data.targetable);

    return me;
  }
}
