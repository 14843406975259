/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import Assets, { AssetName } from "src/utilities/Assets";

type AssetProps = {
  name: AssetName;
  width?: number;
  imageCss?: SerializedStyles;
};

export default function Asset(props: AssetProps) {
  const { name, width } = props;
  const asset = Assets[name];

  const imageWidth = typeof width === "number" ? width : asset.width;
  const imageHeight =
    typeof width === "number"
      ? (width / asset.width) * asset.height
      : asset.height;

  const imageCss = css`
    ${props.imageCss}
    width: ${imageWidth}px;
    height: ${imageHeight}px;
    display: block;
  `;
  return (
    <img
      css={imageCss}
      src={asset.path1x}
      srcSet={`${asset.path1x} 1x, ${asset.path2x} 2x, ${asset.path3x} 3x`}
      alt={asset.alernativeText}
    />
  );
}
