import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import { BotPlayerTypes } from "src/player/types";
import { BotError, EmptyScript } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import { default as ChatbotApiTypes } from "../BotApi/BotApiTypes";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import Bubble from "./Bubble";
import MessagesIntervention from "./MessagesIntervention";
import { TargetableItem } from "./types";

export default class EntryPoint extends Bubble {
  // readonly type = "EntryPoint";
  // readonly remoteId: number;
  // readonly name: string;
  // private dialog: BotDialog;
  // readonly label: Partial<{ [locale in Locale]: number }> = {};
  constructor(id: number) {
    super(id);
  }
  // isTargetable() {
  //   return true;
  // }
  // get id() {
  //   return this.remoteId.toString();
  // }
  // findRemoteId() {
  //   return this.remoteId;
  // }
  // findChainableRemoteId() {
  //   return this.remoteId;
  // }
  // // Ahead

  //

  getKind(): ChatbotApiTypes.StepKind {
    return "RT";
  }

  getWeight(): number {
    let weight = 1;
    if (this.ahead) weight += this.ahead.getWeight();
    return weight;
  }

  // Registration

  isRegistrable(): boolean {
    return true;
  }

  isUnregistrable(): boolean {
    return false;
  }

  // Ahaed

  private aheadId: string | null = null;

  set ahead(intervention: MessagesIntervention | null) {
    if (intervention === null) {
      this.aheadId = null;
    } else {
      this.aheadId = intervention.id;
      intervention.parent = this;
    }
  }

  get ahead() {
    if (this.aheadId === null) return null;
    const intervention = this.dialog.find(this.aheadId);
    if (intervention instanceof MessagesIntervention) return intervention;
    throw new Error("Invalid EntryPoint ahead");
  }

  // destroy() {
  //   if (this.ahead) this.ahead.destroy();
  //   this.destroy();
  // }
  // // Weight
  // getWeight(tracker: number = 0) {
  //   let weight = tracker;
  //   weight += 1;
  //   if (this.ahead) weight += this.ahead.getWeight(tracker);
  //   return weight;
  // }
  // // To steps

  // // Labels
  // setLabelId(locale: Locale, labelId: number) {
  //   this.label[locale] = labelId;
  // }
  // countTranslations(locale: Locale, emptyOnes: boolean) {
  //   if (this.ahead) return this.ahead.countTranslations(locale, emptyOnes);
  //   return 0;
  // }

  getIcon(): NoeseIcons {
    return "robot-outline";
  }

  getErrors() {
    const errors: Array<BotError> = [];
    if (!this.ahead) errors.push(new EmptyScript(this));
    if (this.ahead) errors.push(...this.ahead.getErrors());
    return errors;
  }

  getTargetableItems() {
    const output: Array<TargetableItem> = [this];
    if (this.ahead) output.push(...this.ahead.getTargetableItems());
    return output;
  }

  getLabel(locale: Locale): string {
    return "Début du script";
  }

  // Release

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    if (steps.length !== 1 || steps[0].data.kind !== "RT") {
      throw new BotStructureError(
        "Cannot create dialog because root array doesn't contains 1 (and only 1) RT step",
        { steps }
      );
    }
    const step = steps[0];
    const me = new EntryPoint(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.register();

    // Children
    const children = step.children || [];
    if (children.length > 1) {
      throw new BotStructureError(
        "Cannot create dialog because RT children contains more than 1 BI step",
        { step }
      );
    }
    if (children.length === 0) {
      me.ahead = null;
    } else {
      const ahead = MessagesIntervention.fromSteps(children, dialog);
      me.ahead = ahead;
      ahead.register();
    }
    return me;
  }

  // Export to draft

  toDraftSteps(): Array<DraftBot.Step> {
    return [this.toDraftStep()];
  }

  toDraftStep(): DraftBot.Step {
    return {
      id: this.findRemoteId(),
      data: {
        kind: "RT",
        action: null,
        action_args: null,
        label: this.getReleaseLabel(),
        targetable: this.isTargetable(),
        name: this.findName(),
      },
      children: this.ahead ? this.ahead.toDraftSteps() : [],
    };
  }

  // Export to bundle

  toBundleSteps(locale: Locale): Array<BotPlayerTypes.Step> {
    return [this.toBundleStep(locale)];
  }

  toBundleStep(locale: Locale): BotPlayerTypes.Step {
    return {
      id: this.findRemoteId(),
      data: {
        kind: "RT",
        action: null,
        action_args: null,
        name: this.getLabel(locale),
      },
      children: this.ahead ? this.ahead.toBundleSteps(locale) : [],
    };
  }
}
