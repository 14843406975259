import { css } from "@emotion/react";
import { rgba } from "polished";
import Clickable, { ClickableProps } from "src/components/Clickable";
import Divider from "src/components/Divider";
import Icon from "src/components/Icon";
import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import Intersperse, { IntersperseProps } from "src/components/Intersperse";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import Theme from "src/utilities/Theme";

type SolutionsProps = Pick<IntersperseProps, "children">;

export function Solutions(props: SolutionsProps) {
  return (
    <Intersperse
      between={() => <Divider color={rgba(Theme.colors.blue, 0.3)} />}
      append
      prepend
    >
      {props.children}
    </Intersperse>
  );
}

type SolutionProps = {
  icon: NoeseIcons;
  text: string;
} & ClickableProps;

export function Solution(props: SolutionProps) {
  const { icon, text, ...clickableProps } = props;
  const containerCss = css({
    display: "flex",
    paddingBlock: 8,
    color: Theme.colors.blue,
    width: "100%",
    "&:hover": {
      backgroundColor: rgba(Theme.colors.blue, 0.05),
      cursor: "pointer",
    },
  });

  return (
    <Clickable containerCss={containerCss} {...clickableProps}>
      <Spacer scale={2} />
      <Icon name={icon} typo="bold" />
      <Spacer scale={1} />
      <Typo name="bold">{text}</Typo>
      <Spacer scale={2} />
    </Clickable>
  );
}
