/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ConnectDropTarget } from "react-dnd";
import Typo from "src/components/Typo";

type DroperProps = {
  dropRef: ConnectDropTarget;
  visible: boolean;
  over: boolean;
  lineColor: string;
};

export default function Droper(props: DroperProps) {
  const { dropRef, visible, over, lineColor } = props;

  const droperCss = css({
    position: "relative",
    zIndex: 2000,
    transition: "all 200ms",
    opacity: over ? 1 : visible ? 1 : 0,
    pointerEvents: visible ? undefined : "none",
    transform: `translateX(${over ? "0px" : "10px"})`,
  });

  const droperPinCss = css({
    position: "absolute",
    top: 0,
    height: 10,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
  });

  const droperLineCss = css({
    height: 4,
    background: lineColor,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    width: "calc(100% - 10px)",
    flexShrink: 0,
  });

  const droperArrowCss = css({
    flexShrink: 0,
  });

  const droperTextCss = css({
    flexShrink: 0,
    minHeight: 30 + 10,
    borderRadius: 5,
    background: lineColor,
    display: "flex",
    alignItems: "center",
    padding: "4px 10px",
    color: "white",
  });

  return (
    <div ref={dropRef} css={droperCss}>
      <div css={droperPinCss}>
        <div css={droperLineCss} />
        <svg width={15} height={30} css={droperArrowCss}>
          <path
            d="M15,0 L15,30 L14.8678804,30.0003885 C13.8902661,22.6614809 7.6062879,17 0,17 L0,13 C7.60612632,13 13.8899991,7.33875965 14.8678181,7.91995983e-05 L15,0 Z"
            id="Combined-Shape"
            fill={lineColor}
          ></path>
        </svg>
        <div css={droperTextCss}>
          <Typo name="button">Déplacer ici</Typo>
        </div>
      </div>
    </div>
  );
}
