/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, ReactNode, useCallback } from "react";
import { ConnectDragSource } from "react-dnd";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import stopPropagation from "src/utilities/stopPropagation";
import { useChatbotTreeStepperController } from "../ChatbotEditorStepper";
import { ItemsMetadataContext } from "../ChatbotEditorStepper/config";
import { feedback, feedbackItem } from "../ChatbotEditorStepper/feedbacks";
import Clickable from "../Clickable";
import Icon from "../Icon";
import NoeseIcons from "../Icon/NoeseIcons/NoeseIcons.generated";
import Spacer from "../Spacer";
import Typo from "../Typo";

type NodeHeaderProps = {
  icon?: NoeseIcons;
  title: string;
  loading?: boolean;
  targetControl?: boolean;
  dragRef?: ConnectDragSource;
  onPlay?: () => void;
  onDelete?: () => void;
};

export default function NodeHeader(props: NodeHeaderProps) {
  const { icon, title, targetControl, dragRef, onPlay, onDelete } = props;

  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { bubble, selected } = controller.useItemSelector(
    (c, itemId, columnId) => {
      const bubble = c.findItemData(itemId);
      return {
        bubble,
        selected: c.isItemSelected(itemId),
      };
    },
    []
  );

  const headerCss = css({
    display: "flex",
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: "center",
  });

  // Title

  const titleCss = css({
    paddingTop: 6,
    paddingBottom: 6,
  });

  const titleNode = (
    <div css={titleCss}>
      <Typo name="body" oneLine>
        {title}
      </Typo>
    </div>
  );

  // Targetable control

  const onChangeTargetable = useCallback(() => {
    if (!(bubble instanceof MessageBubble || bubble instanceof ChoiceBubble))
      return;
    const exec = manager.updateTargetable(bubble, !bubble.isTargetable());
    feedback(controller, exec);
    feedbackItem(itemsMetadata, exec, bubble.id);
  }, []);

  const entryPointNode: ReactNode = targetControl ? (
    <Clickable onClick={onChangeTargetable}>
      <Icon
        name={bubble.isTargetable() ? "bookmark-full-check" : "bookmark-empty"}
        typo="body"
      />
    </Clickable>
  ) : null;

  // Sort

  const isSortable = dragRef !== undefined;

  const sortNode: ReactNode = isSortable ? (
    <div ref={dragRef}>
      <Icon name="drag-horizontal-variant-2" typo="body" scale={1.1} />
    </div>
  ) : null;

  // Play control
  const isPlayable = onPlay !== undefined;

  const playNode: ReactNode = isPlayable ? (
    <div onClick={onPlay}>
      <Icon name="play-circle-outline" typo="body" scale={1.1} />
    </div>
  ) : null;

  // Remove control
  const isDeletable = onDelete !== undefined;

  const removeNode: ReactNode = isDeletable ? (
    <div onClick={onDelete}>
      <Icon name="trash-can-outline" typo="body" scale={1.1} />
    </div>
  ) : null;

  // Controls

  const controlsCss = css({
    opacity: selected ? 1 : 0,
    transition: "opacity 200ms",
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    pointerEvents: selected ? "auto" : "none",
  });

  const controlsNode = (
    <div css={controlsCss} onClick={stopPropagation}>
      {entryPointNode}
      <Spacer size={2} />
      {playNode}
      <Spacer size={2} />
      {sortNode}
      <Spacer size={2} />
      {removeNode}
    </div>
  );

  return (
    <Fragment>
      <div css={headerCss}>
        {icon ? <Icon name={icon} typo="body" scale={1.3} /> : null}
        <Spacer size={4} />
        {titleNode}
        <Spacer grow />
        {controlsNode}
      </div>
    </Fragment>
  );
}
