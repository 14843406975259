import { useEffect, useMemo } from "react";
import {
  generatePath,
  matchPath,
  PathMatch,
  useLocation,
  useMatch,
  useParams,
  useSearchParams,
} from "react-router-dom";

export type Route = {
  pattern: string;
  parent: Route | null;
};

export default class Routing<Routes extends Record<string, Route>> {
  readonly routes: Routes;

  constructor(routes: Routes) {
    this.routes = routes;
  }

  private getRoute(name: keyof Routes) {
    return this.routes[name];
  }

  private getParents(zone: Route) {
    const output: Array<Route> = [];
    let current: Route | null = zone;
    while (current !== null) {
      output.unshift(current);
      current = current.parent;
    }
    return output;
  }

  getPattern(zoneName: keyof Routes) {
    const zone = this.getRoute(zoneName);
    return zone.pattern;
  }

  getRootPattern(zoneName: keyof Routes) {
    const zone = this.getRoute(zoneName);
    return this.getZoneRootPattern(zone);
  }

  getZoneRootPattern(zone: Route) {
    const parents = this.getParents(zone);
    return parents
      .map((r) => `/${r.pattern}`)
      .join("")
      .replace(/[/]+/g, "/");
  }

  getPath(
    zoneName: keyof Routes,
    options: {
      params?: Record<string, string>;
      query?: Record<string, string>;
      hash?: string;
    } = {}
  ) {
    const { params, query, hash } = options;
    const matchingRoute = Object.values(this.routes).find((r) => {
      const match = matchPath(
        this.getZoneRootPattern(r),
        window.location.pathname
      );
      if (match) return true;
      else return false;
    });
    let allParams = {};
    if (matchingRoute) {
      const { params: baseParams } = matchPath(
        this.getZoneRootPattern(matchingRoute),
        window.location.pathname
      ) as PathMatch<string>;
      allParams = { ...allParams, ...baseParams };
    }
    allParams = { ...allParams, ...params };
    let path = generatePath(this.getRootPattern(zoneName), allParams);
    if (query) path += `?${new URLSearchParams(query).toString()}`;
    if (hash) path += `#${hash}`;
    return path;
  }

  useParam(name: string): string;
  useParam<TDef>(name: string, def: TDef): string | TDef;
  useParam<TDef>(name: string, defaultValue?: TDef) {
    const pararms = useParams();
    let value: string | TDef | undefined = pararms[name];
    if (value === undefined) value = defaultValue;
    if (value === undefined) throw new Error(`Param ${name} not found`);
    return value;
  }

  useQueryParam(name: string): string;
  useQueryParam<TDef>(name: string, def: TDef): string | TDef;
  useQueryParam<TDef>(name: string, defaultValue?: TDef) {
    const [pararms] = useSearchParams();
    let value: string | TDef | undefined = pararms.has(name)
      ? (pararms.get(name) as string)
      : undefined;
    if (value === undefined) value = defaultValue;
    if (value === undefined) throw new Error(`Query param ${name} not found`);
    return value;
  }

  useScrollToHash() {
    useEffect(() => {
      const hash = window.location.hash;
      if (!hash) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      const id = hash.substring(1);
      const el = document.getElementById(id);
      if (!el) return;
      el.scrollIntoView({ behavior: "smooth" });
    }, []);
  }

  useIsInRoute(zoneName: keyof Routes) {
    const pattern = this.getRootPattern(zoneName);
    const match = useMatch(pattern);
    return !!match;
  }

  useIsUnderRoute(zoneName: keyof Routes) {
    const zone = this.getRoute(zoneName);
    const pathname = useLocation().pathname;

    const routes = useMemo(() => this.getDescendings(zone), [zone]);
    const matchingRoute = routes.find((z) => {
      return matchPath(this.getZoneRootPattern(z), pathname);
    });
    return matchingRoute !== undefined;
  }

  private getDescendings(zone: Route) {
    const descendings: Array<Route> = [zone];
    const routes = Object.values(this.routes);
    let runAgain = true;
    while (runAgain) {
      runAgain = false;
      const parents = [...descendings];
      for (let parent of parents) {
        for (let child of routes) {
          if (child.parent === parent) {
            descendings.push(child);
            runAgain = false;
          }
        }
      }
    }
    return descendings;
  }
}

export type RouteKey<TRouting> = TRouting extends Routing<infer TRoutes>
  ? keyof TRoutes
  : never;
