/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useCallback, useRef } from "react";
import AutoHeightInput from "src/components/AutoHeightInput";
import Spinner from "src/components/Loading";
import Typos from "src/utilities/Typos";
import useHeight from "src/utilities/useHeight";
import useMemoState from "src/utilities/useMemoState";
import {
  border,
  FilesHover,
  FilesMetadata,
  FilesSelection,
  mixBackgroundColors,
  paddingBlock,
  useTranslationController,
} from "../utils";

type InputProps = {
  activeColor: string;
  value: string;
  onSave: (value: string) => any;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
};

export default function Input(props: InputProps) {
  const { activeColor, value, onSave, textareaRef } = props;

  const controller = useTranslationController();

  const { fileKey, isFirst } = controller.useFileSelector((c, key) => {
    return {
      fileKey: key,
      isFirst: c.isFirst(key),
    };
  }, []);

  const { loading } = FilesMetadata.use(fileKey);

  const hover = FilesHover.use(fileKey);
  const selected = FilesSelection.use(fileKey);

  const measureRef = useRef<HTMLDivElement>(null);
  const measureHeight = useHeight(measureRef);
  controller.useFileHeight(fileKey, measureHeight);

  const [inputValue, setInputValue] = useMemoState(() => value, [value]);

  const onFocus = useCallback(() => {
    FilesSelection.select(fileKey);
  }, [fileKey]);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      FilesSelection.unselect(fileKey);
      onSave(e.target.value);
    },
    [fileKey, onSave]
  );

  const wrapperCss = css(
    {
      borderLeft: border(),
      borderRight: border(),
      borderTop: isFirst ? border() : "none",
      borderBottom: selected ? border(activeColor) : border(),
      paddingBlock: 4,
      paddingInline: 4,
    },
    mixBackgroundColors(
      "#FFFFFF",
      rgba(activeColor, selected ? 0.2 : hover ? 0.1 : 0)
    )
  );

  const targetCss = css({
    borderRadius: 4,
    transition: "all 100ms",
    display: "flex",
    flexGrow: 1,
  });

  const containerCss = css({
    flexGrow: 1,
  });

  const paddingCss = css({
    paddingBlock,
    paddingInline: 10,
  });

  const typoCss = css(Typos.body, {});

  const spinnerCss = css({
    paddingBlock,
    paddingInline: 6,
  });

  const onMouseEnter = useCallback(() => {
    FilesHover.select(fileKey);
  }, [fileKey]);

  return (
    <div css={wrapperCss} onMouseEnter={onMouseEnter} ref={measureRef}>
      <div css={targetCss}>
        <AutoHeightInput
          value={inputValue}
          onChange={setInputValue}
          onFocus={onFocus}
          onBlur={onBlur}
          containerCss={containerCss}
          paddingCss={paddingCss}
          typoCss={typoCss}
          id={`translate${fileKey}`}
          textareaRef={textareaRef}
        />
        <div css={spinnerCss}>
          <Spinner typo="body" active={loading} />
        </div>
      </div>
    </div>
  );
}
