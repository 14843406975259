import { Route, Routes } from "react-router-dom";
import App from "./screens/App";
import ChatbotEditor from "./screens/ChatbotEditor";
import ChatbotHealth from "./screens/ChatbotHealth";
import ChatbotHome from "./screens/ChatbotHome";
import ChatbotRelease from "./screens/ChatbotRelease";
import ChatbotSettings from "./screens/ChatbotSettings";
import ChatbotTranslation from "./screens/ChatbotTranslation";
import ChatbotTranslationLocale from "./screens/ChatbotTranslationLocale";
import Home from "./screens/Home";
import Navigation from "./utilities/Navigation";

export default function MainRouter() {
  return (
    <Routes>
      <Route path={Navigation.getPattern("App")} element={<App />}>
        <Route index element={<Home />} />
        <Route
          path={Navigation.getPattern("ChatbotHome")}
          element={<ChatbotHome />}
        >
          <Route
            path={Navigation.getPattern("ChatbotEdition")}
            element={<ChatbotEditor />}
          ></Route>
          <Route
            path={Navigation.getPattern("ChatbotTranslation")}
            element={<ChatbotTranslation />}
          >
            <Route
              path={Navigation.getPattern("ChatbotTranslationLocale")}
              element={<ChatbotTranslationLocale />}
            ></Route>
          </Route>
          <Route
            path={Navigation.getPattern("ChatbotHealth")}
            element={<ChatbotHealth />}
          ></Route>
          <Route
            path={Navigation.getPattern("ChatbotRelease")}
            element={<ChatbotRelease />}
          ></Route>
          <Route
            path={Navigation.getPattern("ChatbotSettings")}
            element={<ChatbotSettings />}
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
}
