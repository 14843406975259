export type Locale = "fr-fr" | "en-gb" | "en-us" | "es-es" | "de-de" | "it-it";
export type Language = "fr" | "en" | "es" | "de" | "it";

export function localeToLanguage(locale: Locale): Language {
  return locale.split("-")[0] as Language;
}

export function localeToText(locale: Locale): string {
  return {
    "fr-fr": "Français",
    "en-gb": "Anglais britannique",
    "en-us": "Anglais américain",
    "es-es": "Espagnol",
    "de-de": "Allemand",
    "it-it": "Italien",
  }[locale];
}

export const LOCALES = [
  "fr-fr",
  "en-gb",
  "en-us",
  "es-es",
  "de-de",
  "it-it",
] as const;
