import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import { BotPlayerTypes } from "src/player/types";
import { BotError } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import Bubble from "./Bubble";
import MessagesIntervention from "./MessagesIntervention";
import { TargetableItem } from "./types";

export default class MessageBubble extends Bubble {
  readonly type = "MessageBubble";

  // Intervention

  private interventionId: string | null = null;

  set intervention(intervention: MessagesIntervention) {
    this.interventionId = intervention.id;
  }

  get intervention() {
    if (this.interventionId === null)
      throw new Error("MessageBubble without interventionId");
    const intervention = this.dialog.find(this.interventionId);
    if (intervention instanceof MessagesIntervention) return intervention;
    throw new Error("Invalid MessagesIntervention intervention");
  }

  // Registration

  isRegistrable(): boolean {
    if (!this.interventionId) return false;
    return true;
  }

  isUnregistrable(): boolean {
    if (this.interventionId !== null) return false;
    return true;
  }

  // Position in intervention

  get isFirst() {
    return this.intervention.bubbles[0] === this;
  }

  get isLast() {
    return this.intervention.lastBubble === this;
  }

  get index() {
    const bubbles = this.intervention.bubbles;
    const index = bubbles.indexOf(this);
    return index;
  }

  get previous() {
    if (this.isFirst) return null;
    else return this.intervention.bubbles[this.index - 1];
  }

  get next() {
    if (this.isLast) return null;
    else return this.intervention.bubbles[this.index + 1];
  }

  getIcon(): NoeseIcons {
    return "text-bubble";
  }

  // Weight

  getWeight(tracker: number = 0): number {
    let weight = tracker;
    weight += 1;
    if (this.next) {
      weight += this.next.getWeight(tracker);
    } else if (this.isLast) {
      if (this.intervention.ahead) {
        weight += this.intervention.ahead.getWeight(tracker);
      }
    }
    return weight;
  }

  getErrors() {
    const errors: Array<BotError> = [...this.getMissingTranslationErrors()];
    return errors;
  }

  getKind() {
    return "BI" as const;
  }

  destroy() {
    this.intervention.removeBubble(this);
    this.interventionId = null;
    this.unregister();
  }

  getTargetableItems(): Array<TargetableItem> {
    return this.isTargetable() ? [this] : [];
  }

  // Export to draft
  toDraftSteps(): Array<DraftBot.Step> {
    let children: Array<DraftBot.Step> = [];
    if (this.next) {
      children = this.next.toDraftSteps();
    } else if (this.intervention.ahead) {
      children = this.intervention.ahead.toDraftSteps();
    }
    return [
      {
        id: this.findRemoteId(),
        data: {
          kind: "BI",
          action: null,
          action_args: null,
          label: this.getReleaseLabel(),
          targetable: this.isTargetable(),
          name: this.findName(),
        },
        children,
      },
    ];
  }

  // Export to bundle
  toBundleSteps(locale: Locale): Array<BotPlayerTypes.Step> {
    let children: Array<BotPlayerTypes.Step> = [];
    if (this.next) {
      children = this.next.toBundleSteps(locale);
    } else if (this.intervention.ahead) {
      children = this.intervention.ahead.toBundleSteps(locale);
    }
    return [
      {
        id: this.findRemoteId(),
        data: {
          kind: "BI",
          action: null,
          action_args: null,
          name: this.getLabel(locale),
        },
        children,
      },
    ];
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    if (steps.length !== 1) {
      throw new BotStructureError(
        "Creating message bubble from children that doesn't have 1 (and only 1) item",
        { steps }
      );
    }

    const step = steps[0] as DraftBot.Step;
    if (step.data.kind !== "BI") {
      throw new BotStructureError(
        "Creating message bubble from a non-BI step",
        {
          step: step.id,
        }
      );
    }

    const me = new MessageBubble(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.setTargetable(step.data.targetable);

    return me;
  }
}
