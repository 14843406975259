/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Fragment, useCallback, useRef, useState } from "react";
import ChatbotSimulatorController from "src/components/ChatbotSimulator/ChatbotSimulatorController";
import Clickable from "src/components/Clickable";
import Icon from "src/components/Icon";
import NodeFooter from "src/components/Nodes/NodeFooter";
import StepSuggestions from "src/components/Nodes/StepView/StepSuggestions";
import styles from "src/components/Nodes/styles";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale } from "src/utilities/Locales";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import AllStepsView from "./AllStepsView";

export default function RootStepView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();

  const { itemKey, selected } = controller.useItemSelector(
    (c, itemKey, columnKey) => {
      return {
        itemKey,
        selected: c.isItemSelected(itemKey),
      };
    },
    []
  );

  const metadata = ItemsMetadataContext.use();

  const containerCss = css({
    background: Theme.colors.blue,
    color: Theme.colors.white,
    borderRadius: 10,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight;
  controller.useItemHeight(itemKey, height);

  const onPlay = useCallback(async () => {
    ChatbotSimulatorController.set({
      fetchBundleFn: async (_preferedLocale: string) => {
        const preferedLocale = _preferedLocale as Locale;
        const availableLocales = manager.getAllLocales();
        const locale = availableLocales.includes(preferedLocale)
          ? preferedLocale
          : manager.bot.natural_locale;
        await manager.loadTranslations(locale);
        return manager.getBundle(locale);
      },
      onStepConsumed: (_bundle, scriptUuid, step) => {
        if (scriptUuid !== manager.bot.uuid) return;
        metadata.update(step.id.toString(), (c) => ({ ...c, played: true }));
      },
      startLocale: manager.bot.natural_locale,
    });
  }, []);

  return (
    <Fragment>
      <AllStepsView>
        <div css={containerCss}>
          <div ref={measuredRef}>
            <div css={styles.content}>
              <Spacer size={10} />
              <Typo center name="bold">
                Ouverture du bot
              </Typo>
              <Spacer size={10} />
            </div>
          </div>
          <Revealable revealed={selected} onHeightStartChange={setFooterHeight}>
            <div css={css({ display: "flex", justifyContent: "center" })}>
              <Clickable onClick={onPlay}>
                <Icon height={32} name="play-circle-outline" />
              </Clickable>
            </div>
            <StepSuggestions />
            <NodeFooter />
          </Revealable>
        </div>
      </AllStepsView>
    </Fragment>
  );
}
