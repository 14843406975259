import EventEmitter from "events";
import { DependencyList, useEffect, useState } from "react";

type Unsubscriber = () => void;

export default class Events<EventArgsMap extends Record<string, Array<any>>> {
  private emitter = new EventEmitter();

  constructor() {
    this.emitter.setMaxListeners(Infinity);
  }

  on<EventName extends keyof EventArgsMap>(
    eventName: EventName,
    listener: (...args: EventArgsMap[EventName]) => void
  ): Unsubscriber {
    const cb = (...args: Array<any>) =>
      listener(...(args as EventArgsMap[EventName]));
    this.emitter.on(eventName as string, cb);
    return () => this.emitter.off(eventName as string, cb);
  }

  emit<EventName extends keyof EventArgsMap>(
    eventName: EventName,
    ...args: EventArgsMap[EventName]
  ) {
    this.emitter.emit(eventName as string, ...args);
  }

  removeAllListeners() {
    this.emitter.removeAllListeners();
  }

  useValueOn<EventName extends keyof EventArgsMap, TOutput>(
    eventName: EventName,
    fn: () => TOutput,
    deps: DependencyList = []
  ) {
    const [value, setValue] = useState(fn());
    useEffect(() => {
      const unsub = this.on(eventName, (...args) => setValue(fn()));
      return unsub;
    }, deps);
    return value;
  }
}
