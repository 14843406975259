/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import Icon, { IconSizeConfig } from "./Icon";

export type SpinnerProps = {
  active?: boolean;
  color?: string;
  opacity?: number;
} & IconSizeConfig;

export default function Spinner(props: SpinnerProps) {
  const active = props.active === false ? false : true;
  const anim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  `;

  const style = css({
    animation: `${anim} 1s linear infinite`,
    transition: "opacity 200ms",
    opacity: active ? 1 : 0,
  });

  return <Icon css={style} name="loading" {...props} />;
}
