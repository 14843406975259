/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import BotPlayer, { BotExecutionError } from "src/player";
import { EventConfig } from "src/player/types";
import { BotManagerContext } from "src/utilities/BotManager";
import PhoneNumber from "src/utilities/PhoneNumber";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import Clickable, { ClickableProps } from "../Clickable";
import Divider from "../Divider";
import Icon from "../Icon";
import Intersperse from "../Intersperse";
import Typo from "../Typo";
import ChatbotSimulatorController from "./ChatbotSimulatorController";

export default function ChatbotSimulator() {
  const { botApi } = Services.use();
  const manager = BotManagerContext.use();
  const player = ChatbotSimulatorController.use();
  if (!player) throw new Error("No script :/");

  const containerCss = css({
    marginTop: 40,
    paddingTop: 0,
    width: 400,
    height: "calc(100vh - 74px - 74px - 40px)",
    background: Theme.colors.lightblue,
    boxShadow: `0px 3px 6px rgba(0,0,0,0.2)`,
    borderTopLeftRadius: 20,
    display: "flex",
    flexDirection: "column",
  });

  const scrollerCss = css({
    flexGrow: 1,
    overflow: "auto",
    padding: 10,
    display: "flex",
    flexDirection: "column-reverse",
  });

  const messagesCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const [loading, setLoading] = useBooleanState(false);
  const [ended, setEnded] = useBooleanState(false);
  const [error, setError] = useBooleanState(false);

  const [messages, setMessages] = useState<Array<EventConfig.AnyEvent>>([]);

  useEffect(() => {
    const reader = new BotPlayer({
      fetchBundleFn: player.fetchBundleFn,
      onStepConsumed: player.onStepConsumed,
      onShowMessage: (c) => setMessages((m) => [...m, c]),
      onAskToChoose: (c) => setMessages((m) => [...m, c]),
      onOpenLink: (c) => setMessages((m) => [...m, c]),
      onMakePhoneCall: (c) => setMessages((m) => [...m, c]),
      onSendEmail: (c) => setMessages((m) => [...m, c]),
      onTyping: setLoading.to,
      onEnded: () => {
        setEnded.toTrue();
        setTimeout(() => {
          ChatbotSimulatorController.set(null);
        }, 500);
      },
      onError: (err) => {
        setError.toTrue();
        if (err instanceof BotExecutionError) {
          console.error(err, err.extra);
        } else {
          console.error(err);
        }
      },
    });
    reader.start(player.startLocale, player.startAt);
    return () => {
      setLoading.toFalse();
      setEnded.toFalse();
      setError.toFalse();
      reader.destroy();
    };
  }, [player]);

  const closerCss = css({
    display: "flex",
    padding: 10,
    justifyContent: "flex-end",
  });

  return (
    <div css={containerCss}>
      <Clickable
        containerCss={closerCss}
        onClick={() => ChatbotSimulatorController.set(null)}
      >
        <Icon
          name="close-circle-outline"
          typo="body"
          color={Theme.colors.blue}
          scale={2}
        />
      </Clickable>
      <div css={scrollerCss}>
        <div css={messagesCss}>
          {messages.map((m) => {
            if (m.type === "ShowMessage")
              return <ShowMessageBubble key={m.id} {...m} />;
            else if (m.type === "AskToChoose")
              return <AskToChooseBubble key={m.id} {...m} />;
            else if (m.type === "OpenLink")
              return <OpenLinkBubble key={m.id} {...m} />;
            else if (m.type === "MakePhoneCall")
              return <MakePhoneCallBubble key={m.id} {...m} />;
            else if (m.type === "SendEmail")
              return <SendEmailBubble key={m.id} {...m} />;
            else return null;
          })}
          {loading ? <Typo name="minor">Le bot est en d'écrire...</Typo> : null}
          {ended ? (
            <Typo name="minor">La conversation est terminée</Typo>
          ) : null}
          {error ? (
            <Typo name="minor">Le bot a rencontré un problème</Typo>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function ShowMessageBubble(props: EventConfig.ShowMessage) {
  const { text } = props;

  const containerCss = css({
    background: Theme.colors.teal,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    padding: 7,
    color: "white",
    alignSelf: "flex-start",
  });

  return (
    <BotBubble>
      <div css={containerCss}>
        <Typo name="bold">{text}</Typo>
      </div>
    </BotBubble>
  );
}

function AskToChooseBubble(props: EventConfig.AskToChoose) {
  const { options } = props;

  const containerCss = css({
    display: "flex",
    flexWrap: "wrap",
  });

  const proposalCss = css({
    borderRadius: 10,
    padding: 7,
    margin: 5,
    alignSelf: "flex-start",
    border: `2px solid ${Theme.colors.purple}`,
    color: Theme.colors.purple,
    "&:hover": {
      background: rgba(Theme.colors.purple, 0.1),
    },
  });

  const selectedProposalCss = css(proposalCss, {
    background: Theme.colors.purple,
    color: "white",
    "&:hover": {
      background: Theme.colors.purple,
    },
  });

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const onSelect = useCallback(
    (o: EventConfig.AskToChoose["options"][number]) => {
      setSelectedOption(o.id);
      o.onSelect();
    },
    []
  );

  return (
    <BotBubble>
      <div css={containerCss}>
        {options.map((o, i) => {
          return (
            <Clickable
              onClick={() => onSelect(o)}
              containerCss={
                selectedOption === o.id ? selectedProposalCss : proposalCss
              }
              key={i}
            >
              {<Typo name="bold">{o.text}</Typo>}
            </Clickable>
          );
        })}
      </div>
    </BotBubble>
  );
}

function OpenLinkBubble(props: EventConfig.OpenLink) {
  const { url, onVisited } = props;

  const containerCss = css({
    borderRadius: 10,
    borderTopLeftRadius: 0,
    alignSelf: "flex-start",
    color: "white",
    width: "70%",
    background: Theme.colors.pink,
  });

  const domain = useMemo(() => {
    const parsed = new URL(url);
    return parsed.hostname;
  }, [url]);

  const onClick = useCallback(() => {
    window.addEventListener("focus", () => onVisited(), { once: true });
  }, []);

  return (
    <BotBubble>
      <div css={containerCss}>
        <Intersperse between={() => <Divider color="white" />}>
          <Option href={url} target="_blank">
            <Clickable onClick={onClick}>
              <Typo name="bold">Visiter le site {domain}</Typo>
            </Clickable>
          </Option>
          <Option onClick={onVisited}>
            <Typo>Passer la visite du site</Typo>
          </Option>
        </Intersperse>
      </div>
    </BotBubble>
  );
}

function MakePhoneCallBubble(props: EventConfig.MakePhoneCall) {
  const { phoneNumber, onHangUp } = props;

  const containerCss = css({
    borderRadius: 10,
    borderTopLeftRadius: 0,
    alignSelf: "flex-start",
    color: "white",
    width: "70%",
    background: Theme.colors.pink,
  });

  const displayedPhoneNumber = useMemo(() => {
    const nb = PhoneNumber.parse(phoneNumber, null);
    if (!nb) throw new Error("Invalid phone");
    else return nb.getLocal();
  }, [phoneNumber]);

  const onClick = useCallback(() => {
    window.addEventListener("focus", () => onHangUp(), { once: true });
  }, []);

  return (
    <BotBubble>
      <div css={containerCss}>
        <Intersperse between={() => <Divider color="white" />}>
          <Option href={`tel:${phoneNumber}`}>
            <Clickable onClick={onClick}>
              <Typo name="bold">Appeler le {displayedPhoneNumber}</Typo>
            </Clickable>
          </Option>
          <Option onClick={onHangUp}>
            <Typo>Ne pas appeler</Typo>
          </Option>
        </Intersperse>
      </div>
    </BotBubble>
  );
}

function SendEmailBubble(props: EventConfig.SendEmail) {
  const { emailAddress, onSent } = props;

  const containerCss = css({
    borderRadius: 10,
    borderTopLeftRadius: 0,
    alignSelf: "flex-start",
    color: "white",
    width: "70%",
    background: Theme.colors.pink,
  });

  const onClick = useCallback(() => {
    window.addEventListener("focus", () => onSent(), { once: true });
  }, []);

  return (
    <BotBubble>
      <div css={containerCss}>
        <Intersperse between={() => <Divider color="white" />}>
          <Option href={`mailto:${emailAddress}`}>
            <Clickable onClick={onClick}>
              <Typo name="bold">Envoyer un email à {emailAddress}</Typo>
            </Clickable>
          </Option>
          <Option onClick={onSent}>
            <Typo>Ne pas envoyer d'email</Typo>
          </Option>
        </Intersperse>
      </div>
    </BotBubble>
  );
}

function BotBubble(props: PropsWithChildren<{}>) {
  const containerCss = css({
    display: "flex",
    paddingBottom: 10,
  });
  const iconCss = css({
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
    background: Theme.colors.blue,
    flexShrink: 0,
  });

  return (
    <div css={containerCss}>
      <div css={iconCss}></div>
      <div css={{ flexGrow: 1 }}>{props.children}</div>
    </div>
  );
}

function Option(props: ClickableProps) {
  return (
    <Clickable
      css={css({ paddingInline: 6, paddingBlock: 6 })}
      {...props}
    ></Clickable>
  );
}
