/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import ChatbotLines from "./ChatbotLines";
import PropsWithCss from "./PropsWithCss";

export type StrokeProps = PropsWithCss<{
  color?: string;
  strength?: number;
  dash?: string;
  transition?: string;
  x: number;
  y: number;
}>;

export default function Stroke(props: StrokeProps) {
  const { color, x, y, strength, transition, dash } = props;

  const containerCss = css({
    width: 0,
    height: 0,
    overflow: "visible",
    position: "relative",
  });

  const svgCss = css({
    position: "absolute",
    [y >= 0 ? "top" : "bottom"]: 0,
    [x >= 0 ? "left" : "right"]: 0,
    overflow: "visible",
  });

  const path = ChatbotLines.buildCurve(0, 0, x, y);
  const w = Math.abs(x) || 1;
  const h = Math.abs(y) || 1;

  let pathCss = css`
    d: path("${path}");
  `;
  if (transition) {
    pathCss = css(path, { transition });
  }

  return (
    <div css={containerCss} className={props.className}>
      <svg
        css={svgCss}
        width={w}
        height={h}
        viewBox={`${x < 0 ? x : 0} ${y < 0 ? y : 0} ${w} ${h}`}
      >
        <path
          fill="transparent"
          color={color}
          stroke={color}
          strokeWidth={strength}
          strokeDasharray={dash}
          strokeLinecap="round"
          css={pathCss}
        />
      </svg>
    </div>
  );
}
