/** @jsxImportSource @emotion/react */
import { Fragment, ReactNode, useCallback, useEffect, useRef } from "react";
import Icon from "src/components/Icon";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale } from "src/utilities/Locales";
import Zones from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import { feedback, feedbackItem } from "../feedbacks";
import FromParentStroke from "../strokes/FromParentStroke";
import FromPreviousStroke from "../strokes/FromPreviousStroke";
import { FilesMetadata, useTranslationController } from "../utils";
import Input from "./Input";
import Structure from "./Structure";
import Suggestion from "./Suggestion";

export default function MessageLine() {
  const manager = BotManagerContext.use();
  const controller = useTranslationController();

  const { natural_locale } = manager.useSelector(
    () => ({
      natural_locale: manager.bot.natural_locale,
    }),
    []
  );

  const targetLocale = Zones.useParam("locale") as Locale;

  const { fileKey, unfolded, bubble, index } = controller.useFileSelector(
    (c, key) => {
      return {
        fileKey: key,
        bubble: c.findFileData(key) as MessageBubble,
        intervention: c.findFileData(key),
        unfolded: c.isFileUnfolded(key),
        depth: c.findFileDepth(key),
        index: c.findFileIndex(key),
      };
    },
    []
  );

  const source = bubble.getLabel(natural_locale);
  const target = bubble.getLabel(targetLocale);

  const activeColor = Theme.colors.teal;

  useEffect(() => {
    if (!unfolded) controller.setUnfolded(fileKey, true);
  }, [unfolded, fileKey]);

  let stroke: ReactNode = null;
  if (index > 0) {
    if (bubble.isFirst) stroke = <FromParentStroke />;
    else stroke = <FromPreviousStroke />;
  }

  const ref = useRef<HTMLTextAreaElement>(null);

  const onSave = useCallback(
    (label: string) => {
      if (label === target) return;
      const exec = manager.updateLabel(bubble, targetLocale, label);
      feedback(controller, exec);
      feedbackItem(FilesMetadata, exec, fileKey);
    },
    [target, fileKey, targetLocale]
  );

  const onClick = useCallback(() => {
    if (ref.current) ref.current.focus();
  }, []);

  return (
    <Fragment>
      <Structure
        value={source}
        stroke={stroke}
        activeColor={activeColor}
        onClick={onClick}
        icon={
          <Icon
            name={"chat-processing-outline"}
            typo="body"
            square
            color={activeColor}
          />
        }
      />
      <Input
        activeColor={activeColor}
        textareaRef={ref}
        value={target}
        onSave={onSave}
      />
      <Suggestion value={source} onSave={onSave} activeColor={activeColor} />
    </Fragment>
  );
}
