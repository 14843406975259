import React, { PropsWithChildren, useContext } from "react";

export interface ServiceList {}
const ServicesContext = React.createContext<ServiceList | null>(null);

type ServicesProviderProps = PropsWithChildren<{ services: ServiceList }>;
function Provider(props: ServicesProviderProps) {
  return (
    <ServicesContext.Provider value={props.services}>
      {props.children}
    </ServicesContext.Provider>
  );
}

function use() {
  const services = useContext(ServicesContext);
  if (!services) throw new Error("No services");
  return services;
}

const Services = {
  use,
  Provider,
};

export default Services;
