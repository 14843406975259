/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Property } from "csstype";
import { PropsWithChildren } from "react";

type ButtonsProps = PropsWithChildren<{
  row?: boolean;
  align?: Property.JustifyContent;
}>;

export default function Buttons(props: ButtonsProps) {
  const { children, align = "flex-start", row } = props;

  const containerCss = css({
    display: "flex",
    flexDirection: row ? "row" : "column",
    justifyContent: align,
    alignItems: "center",
  });
  return <div css={containerCss}>{children}</div>;
}
