/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import Content from "src/components/Content";
import Modal from "src/components/Modal";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import StepSuggestions from "src/components/Nodes/StepView/StepSuggestions";
import styles from "src/components/Nodes/styles";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import PhoneNumber, { PhoneZone } from "src/utilities/PhoneNumber";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import useBooleanState from "src/utilities/useBooleanState";
import useHeight from "src/utilities/useHeight";
import useMemoState from "src/utilities/useMemoState";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function PhoneBubbleView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { itemId, bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as UrlBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const { locale } = itemsMetadata.use(itemId);

  const phone = bubble.getLabel(locale);

  const [zone, setZone] = useMemoState(
    (c?: PhoneZone | null) => {
      const nb = PhoneNumber.parse(phone, null);
      if (!nb) return c === undefined ? PhoneNumber.getUserZone() : c;
      return nb.getZone();
    },
    [phone]
  );

  const [number, setNumber] = useMemoState(() => {
    const nb = PhoneNumber.parse(phone, null);
    if (!nb) return "";
    return nb.getLocal();
  }, [phone]);

  const [dialed, setDialed] = useMemoState(() => {
    const nb = PhoneNumber.parse(phone, null);
    if (!nb) return null;
    return nb.getDial();
  }, [phone]);

  const savePhone = useCallback(
    (newPhone: string) => {
      if (phone === newPhone) return;
      const execution = manager.updateLabelAndActionArgs(
        bubble,
        locale,
        newPhone
      );
      feedback(controller, execution);
      feedbackItem(itemsMetadata, execution, bubbleId);
    },
    [phone, bubble, locale]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const input = e.target.value;
      const nb = PhoneNumber.parse(input, zone);
      if (nb) {
        setZone(nb.getZone());
        setNumber(nb.getLocal());
        setDialed(nb.getDial());
        savePhone(nb.getDial());
      } else {
        setNumber("");
        setDialed("");
        savePhone("");
      }
    },
    [savePhone, zone]
  );

  const changeZone = useCallback(
    (zone: PhoneZone) => {
      const nb = PhoneNumber.parse(number, zone);
      if (nb === null) {
        setZone(zone);
      } else {
        setZone(nb.getZone());
        setNumber(nb.getLocal());
        setDialed(nb.getDial());
        savePhone(nb.getDial());
      }

      setModal.toFalse();
    },
    [number]
  );

  const containerCss = css({
    borderRadius: 10,
    background: Theme.colors.pink,
    color: Theme.colors.white,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeActionBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  const inputContainerCss = css({
    background: "white",
    borderRadius: 8,
    color: "black",
    overflow: "hidden",
    alignItems: "center",
  });

  const inputRow = css({
    display: "flex",
  });

  const flagCss = css({
    paddingTop: 8,
    paddingRight: 8,
  });

  const inputCss = css(Typos.bold, {
    paddingTop: 8,
    paddingInline: 8,
    border: "none",
    margin: 0,
    flexGrow: 1,
    outline: "none",
  });

  const helperCss = css({
    paddingInline: 8,
    paddingBottom: 8,
  });

  const gridCss = css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  });

  const [modal, setModal] = useBooleanState(false);

  const zoneCss = css({
    padding: 8,
  });
  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon="step-phone"
              title="Lancement d'un appel"
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={styles.content}>
              <div css={inputContainerCss}>
                <div css={inputRow}>
                  <input
                    css={inputCss}
                    type="text"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    onBlur={onBlur}
                  />
                  <div css={flagCss} onClick={setModal.toTrue}>
                    <Typo name="bold">{zone ? zone.flag : "🌍"}</Typo>
                  </div>
                </div>
                <div css={helperCss}>
                  <Typo name="minor" color={rgba("black", 0.5)}>
                    {dialed === number ? "" : dialed}
                  </Typo>
                </div>
              </div>
            </div>
            <Spacer size={4} />
          </div>
          <Modal visible={modal} onClose={setModal.toFalse}>
            <Content scale={2}>
              <Spacer scale={2}></Spacer>
              <Typo name="heading">Indicatif téléphonique</Typo>
              <Spacer scale={2}></Spacer>
              <div css={gridCss}>
                {PhoneNumber.getZones().map((zone) => {
                  return (
                    <div css={zoneCss} onClick={() => changeZone(zone)}>
                      <Typo>{`${zone.flag} ${zone.label} (+${zone.prefix})`}</Typo>
                    </div>
                  );
                })}
              </div>
            </Content>
          </Modal>
          <Revealable revealed={selected} onHeightStartChange={setFooterHeight}>
            <StepSuggestions />
            <NodeFooter translationControl={true} />
          </Revealable>
        </div>
      </AllStepsView>
      <Spacer size={10} />
    </Fragment>
  );
}
