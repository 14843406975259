import ActionBubble from "./ActionBubble";
import { TargetableItem } from "./types";

export default abstract class DataActionBubble extends ActionBubble {
  isEnding(): boolean {
    return false;
  }

  getTargetableItems(): Array<TargetableItem> {
    return this.isTargetable() ? [this] : [];
  }
}
