import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import Navigation from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import BotErrorView from "./BotErrorView";
import { InvalidFormat } from "./types";

type InvalidFormatViewProps = {
  error: InvalidFormat;
};

export default function InvalidFormatView(props: InvalidFormatViewProps) {
  const { error } = props;

  const target = error.target;

  let message: string = "";
  if (target instanceof UrlBubble) {
    message = `Le bot redirige l'utilisateur sur la page Internet "${error.input}". Ce lien est invalide.`;
  } else if (target instanceof EmailBubble) {
    message = `Le bot propose à l'utilisateur d'envoyer un email à l'adresse "${error.input}". Cette adresse est invalide.`;
  }

  return (
    <BotErrorView
      type="Lien mort"
      icon="link-variant-off"
      description={message}
      solutions={
        <Buttons>
          <Button2
            label="Corriger"
            to={Navigation.getPath("ChatbotEdition", {
              query: { step: error.target.id },
            })}
            color={Theme.colors.blue}
            layout="solid"
          />
        </Buttons>
      }
    ></BotErrorView>
  );
}
