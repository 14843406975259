import { without } from "lodash";
import Events from "./Events";

export class AbortExecutionError {}

type ExecutionActions<TCommitOutput = any, TPushOutput = any> = {
  commit: () => TCommitOutput;
  push: (local: TCommitOutput) => Promise<TPushOutput>;
  merge: (local: TCommitOutput, remote: TPushOutput) => any;
  revert: (local: TCommitOutput, error: any) => any;
};

type Phase = "commit" | "push" | "merge" | "revert";

type ExecutionEvents<TCommitOutput = any, TPushOutput = any> = {
  // Global
  started: [];
  done: [];
  failed: [any];
  aborted: [any];
  ended: [];

  // Commit
  "commit-started": [];
  "commit-done": [TCommitOutput];
  "commit-failed": [any];
  "commit-aborted": [any];
  "commit-ended": [];

  // Push
  "push-started": [TCommitOutput];
  "push-done": [TCommitOutput, TPushOutput];
  "push-failed": [any, TCommitOutput];
  "push-ended": [TCommitOutput];

  // Merge
  "merge-started": [TCommitOutput, TPushOutput];
  "merge-done": [TCommitOutput, TPushOutput];
  "merge-failed": [any, TCommitOutput, TPushOutput];
  "merge-ended": [TCommitOutput, TPushOutput];

  // Revert
  "revert-started": [TCommitOutput];
  "revert-done": [TCommitOutput];
  "revert-failed": [any, TCommitOutput];
  "revert-ended": [TCommitOutput];

  // All phases
  "phase-started": [Phase];
  "phase-done": [Phase];
  "phase-failed": [any, Phase];
  "phase-ended": [Phase];
};

export class Execution<TCommitOutput = any, TPushOutput = any> {
  private running: boolean = false;
  private commitOutput: { value: TCommitOutput } | null = null;
  private pushOutput: { value: TPushOutput } | null = null;
  readonly events = new Events<ExecutionEvents<TCommitOutput, TPushOutput>>();

  constructor(private actions: ExecutionActions<TCommitOutput, TPushOutput>) {}

  isRunning() {
    return this.running;
  }

  isCommited() {
    return this.commitOutput !== null;
  }

  findCommitOutput() {
    if (this.commitOutput === null) {
      throw new Error("Execution is not committed");
    }
    return this.commitOutput.value;
  }

  findPushOutput() {
    if (this.pushOutput === null) throw new Error("Execution is not pushed");
    return this.pushOutput.value;
  }

  async commit() {
    let commited: boolean = false;
    this.events.emit("started");
    this.events.emit("phase-started", "commit");
    this.events.emit("commit-started");
    try {
      this.commitOutput = { value: this.actions.commit() };
      this.events.emit("commit-done", this.commitOutput.value);
      this.events.emit("phase-done", "commit");
      commited = true;
    } catch (err) {
      if (err instanceof AbortExecutionError) {
        this.events.emit("commit-aborted", err);
        this.events.emit("aborted", err);
      } else {
        this.events.emit("commit-failed", err);
        this.events.emit("phase-failed", err, "commit");
        this.events.emit("failed", err);
      }
    } finally {
      this.events.emit("commit-ended");
      this.events.emit("phase-ended", "commit");
    }
    return commited;
  }

  async push() {
    this.running = true;
    const commitOutput = this.findCommitOutput();
    this.events.emit("phase-started", "push");
    this.events.emit("push-started", commitOutput);
    try {
      const commitOutput = this.findCommitOutput();
      const pushOutput = await this.actions.push(this.findCommitOutput());
      this.pushOutput = { value: pushOutput };
      this.events.emit("push-done", commitOutput, pushOutput);
      this.events.emit("phase-done", "push");
    } catch (err) {
      this.events.emit("push-failed", err, this.findCommitOutput());
      this.events.emit("phase-failed", err, "push");
      throw err;
    } finally {
      this.events.emit("push-ended", this.findCommitOutput());
      this.events.emit("phase-ended", "push");
    }
  }

  async merge() {
    const commitOutput = this.findCommitOutput();
    const pushOutput = this.findPushOutput();
    this.events.emit("phase-started", "merge");
    this.events.emit("merge-started", commitOutput, pushOutput);
    try {
      this.actions.merge(this.findCommitOutput(), this.findPushOutput());
      this.events.emit("merge-done", commitOutput, pushOutput);
      this.events.emit("phase-done", "merge");
      this.events.emit("done");
    } catch (err) {
      this.events.emit("merge-failed", err, commitOutput, pushOutput);
      this.events.emit("phase-failed", err, "merge");
      this.events.emit("failed", err);
    } finally {
      this.events.emit("merge-ended", commitOutput, pushOutput);
      this.events.emit("phase-ended", "merge");
      this.events.emit("ended");
    }
  }

  async revert(err: any) {
    const commitOutput = this.findCommitOutput();
    this.events.emit("revert-started", commitOutput);
    this.events.emit("phase-started", "revert");
    try {
      this.actions.revert(this.findCommitOutput(), err);
      this.events.emit("revert-done", commitOutput);
      this.events.emit("phase-done", "revert");
    } catch (err) {
      this.events.emit("revert-failed", err, commitOutput);
      this.events.emit("phase-failed", err, "revert");
    } finally {
      this.events.emit("failed", err);
      this.events.emit("revert-ended", commitOutput);
      this.events.emit("phase-ended", "revert");
      this.events.emit("ended");
    }
  }
}

export default class Executions {
  private stack: Array<Execution> = [];

  execute<TCommitOutput, TPushOutput>(
    actions: ExecutionActions<TCommitOutput, TPushOutput>
  ) {
    const execution = new Execution(actions);
    setTimeout(async () => {
      const commited = await execution.commit();
      if (commited) {
        this.stack.push(execution);
        this.pushNext();
      }
    }, 10);
    return execution;
  }

  async pushNext() {
    const execution = this.stack[0];
    if (!execution) return;
    if (execution.isRunning()) return;
    try {
      await execution.push();
      execution.merge();
      this.stack = without(this.stack, execution);
      this.pushNext();
    } catch (err) {
      this.stack.forEach((e) => e.revert(err));
      this.stack = [];
    }
  }
}
