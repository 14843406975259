import { difference, uniq, without } from "lodash";
import { DependencyList } from "react";
import { BotPlayerTypes } from "src/player/types";
import BotAdapaters, { DraftBot, TranslationBundle } from "./BotAdapters";
import BotApi from "./BotApi";
import { default as ChatbotApiTypes } from "./BotApi/BotApiTypes";
import BotDialog from "./BotDialog";
import ActionBubble from "./BotDialog/ActionBubble";
import ActionsIntervention from "./BotDialog/ActionsIntervention";
import ChoiceBubble from "./BotDialog/ChoiceBubble";
import ChoicesIntervention from "./BotDialog/ChoicesIntervention";
import EntryPoint from "./BotDialog/EntryPoint";
import GotoBubble from "./BotDialog/GotoBubble";
import MessageBubble from "./BotDialog/MessageBubble";
import MessagesIntervention from "./BotDialog/MessagesIntervention";
import TranslateBubble from "./BotDialog/TranslateBubble";
import UrlBubble from "./BotDialog/UrlBubble";
import createRequiredContext from "./createRequiredContext";
import Executions, { AbortExecutionError } from "./Executions";
import { Locale } from "./Locales";
import Updater from "./Updater";

export default class BotManager {
  readonly api: BotApi;
  readonly bot: ChatbotApiTypes.Bot;
  readonly dialog: BotDialog;
  readonly localesPromises: Partial<{
    [locale in Locale]: Promise<void>;
  }> = {};
  readonly updater = new Updater();
  private executions = new Executions();
  private extranalManagers: Array<BotManager> = [];

  constructor(api: BotApi, bot: ChatbotApiTypes.Bot, script: DraftBot.Script) {
    this.api = api;
    this.bot = bot;
    this.dialog = new BotDialog(this, script);
  }

  useSelector<TOutput>(fn: () => TOutput, deps: DependencyList) {
    return this.updater.useValue(fn, deps);
  }

  setBotName(name: string) {
    this.bot.name = name;
    this.updater.update();
  }

  getDepth(id: string) {
    this.dialog.getEntryPoint();
  }

  addTranslations(bundle: TranslationBundle) {
    this.localesPromises[bundle.locale] = Promise.resolve();
    this.dialog.addTranslations(bundle);
    this.updater.update();
  }

  async loadTranslations(locale: Locale) {
    if (this.localesPromises[locale]) return this.localesPromises[locale];
    this.localesPromises[locale] = (async () => {
      const bundle = await this.fetchTranslationBundle(locale);
      this.dialog.addTranslations(bundle);
      this.updater.update();
    })();
    return this.localesPromises[locale];
  }

  private async fetchTranslationBundle(locale: Locale) {
    const raws = await this.api.loadRawSteps(this.bot.uuid, locale);
    const bundle = BotAdapaters.localizedRawStepsToTranslationBundle(
      raws,
      locale
    );
    return bundle;
  }

  async getBundle(locale: Locale): Promise<BotPlayerTypes.Bundle> {
    await this.loadTranslations(locale);
    return {
      uuid: this.bot.uuid,
      language: locale,
      script: this.dialog.getEntryPoint().toBundleSteps(locale),
      traversal: null,
      name: this.bot.name,
      external_scripts: await this.getExternalBundles(locale),
    };
  }

  async getExternalBundles(locale: Locale) {
    let toLoad = this.getAllGotoScripts();
    const output: Record<string, BotPlayerTypes.Step> = {};
    while (toLoad.length > 0) {
      const deps: Array<string> = [];
      for (let uuid of toLoad) {
        const manager = await this.findExternalManager(uuid);
        const localeToLoad = manager.getAllLocales().includes(locale)
          ? locale
          : manager.bot.natural_locale;
        await manager.loadTranslations(localeToLoad);
        const step = manager.dialog
          .getEntryPoint()
          .toBundleSteps(localeToLoad)[0];
        output[uuid] = step;
        deps.push(...manager.getAllGotoScripts());
      }
      toLoad = without(difference(deps, Object.keys(output)), this.bot.uuid);
    }
    return output;
  }

  getAllGotoScripts() {
    const gotos = Object.values(this.dialog.index).filter(
      (v) => v instanceof GotoBubble
    ) as Array<GotoBubble>;
    const targets = without(
      gotos.map((g) => g.getActionArgs()),
      null
    ) as Array<string>;
    const botUuids = uniq(targets.map((t) => t.split("#")[0]));
    return botUuids;
  }

  async findExternalManager(uuid: string) {
    const inCache = this.extranalManagers.find((m) => m.bot.uuid === uuid);
    if (inCache) return inCache;
    const bot = await this.api.getBot(uuid);
    const rawSteps = await this.api.loadRawSteps(uuid, this.bot.natural_locale);
    const script = BotAdapaters.rawStepsToDraftScript(rawSteps);
    const i18nBundle = BotAdapaters.localizedRawStepsToTranslationBundle(
      rawSteps,
      this.bot.natural_locale
    );
    const manager = new BotManager(this.api, bot, script);
    manager.addTranslations(i18nBundle);
    this.extranalManagers.push(manager);
    return manager;
  }

  // Bot

  async addLocale(locale: Locale) {
    const result = await this.api.updateBot(this.bot.uuid, {
      other_locales: [...this.bot.other_locales, locale],
    });
    this.bot.other_locales = result.other_locales;
    this.updater.update();
  }

  getAllLocales() {
    return [this.bot.natural_locale, ...this.bot.other_locales];
  }

  async removeLocale(locale: Locale) {
    const result = await this.api.updateBot(this.bot.uuid, {
      other_locales: without(this.bot.other_locales, locale),
    });
    this.bot.other_locales = result.other_locales;
    this.updater.update();
  }

  // Steps

  updateLabel(
    bubble: ChoiceBubble | MessageBubble,
    locale: Locale,
    text: string
  ) {
    return this.executions.execute({
      commit: () => {
        const oldLabel = bubble.getLabel(locale);
        const labelId = bubble.setLabel(locale, text);
        return { oldLabel, labelId };
      },
      push: async () => {
        const bubbleId = bubble.findRemoteId();
        return this.api.updateStep(this.bot.uuid, bubbleId, locale, {
          label: text,
          targetable: bubble.isTargetable(),
          action: null,
          action_args: null,
          kind: bubble.getKind(),
        });
      },
      merge: (commitResult, updateResult) => {
        bubble.setLabelId(locale, updateResult.label[locale]);
      },
      revert: (commitOutput) => {
        bubble.setLabel(locale, commitOutput.oldLabel);
      },
    });
  }

  updateLabelAndActionArgs(bubble: UrlBubble, locale: Locale, data: string) {
    return this.executions.execute({
      commit: () => {
        const oldData = bubble.getLabel(locale);
        bubble.setLabel(locale, data);
        bubble.setActionArgs(data);
        return oldData;
      },
      push: async () => {
        const bubbleId = bubble.findRemoteId();
        return this.api.updateStep(this.bot.uuid, bubbleId, locale, {
          label: data,
          action_args: bubble.getActionArgs(),
        });
      },
      merge: (commitResult, updateResult) => {
        // Nothing to do
      },
      revert: (oldData) => {
        bubble.setLabel(locale, oldData);
      },
    });
  }

  updateActionArgs(
    bubble: GotoBubble | TranslateBubble,
    action_args: string | null
  ) {
    return this.executions.execute({
      commit: () => {
        const oldActionArgs = bubble.getActionArgs();
        bubble.setActionArgs(action_args);
        return oldActionArgs;
      },
      push: async () => {
        const bubbleId = bubble.findRemoteId();
        return this.api.updateStep(
          this.bot.uuid,
          bubbleId,
          this.bot.natural_locale,
          { action_args }
        );
      },
      merge: (commitResult, updateResult) => {
        // Nothing to do
      },
      revert: (oldActionArgs) => {
        bubble.setActionArgs(oldActionArgs);
      },
    });
  }

  updateTargetable(bubble: MessageBubble | ChoiceBubble, targetable: boolean) {
    return this.executions.execute({
      commit: () => {
        const oldValue = bubble.isTargetable();
        bubble.setTargetable(targetable);
        return oldValue;
      },
      push: async () => {
        const bubbleId = bubble.findRemoteId();
        const naturalLocale = bubble.dialog.manager.bot.natural_locale;
        return this.api.updateStep(this.bot.uuid, bubbleId, naturalLocale, {
          label: bubble.getLabel(naturalLocale),
          targetable,
          action: null,
          action_args: null,
          kind: bubble.getKind(),
        });
      },
      merge: (commitResult, updateResult) => {
        // Nothing to do
      },
      revert: (oldValue) => {
        bubble.setTargetable(oldValue);
      },
    });
  }

  moveChoiceBubble(bubble: ChoiceBubble, after: ChoiceBubble | null) {
    return this.executions.execute({
      commit: () => {
        if (bubble === after) {
          throw new AbortExecutionError();
        }
        const intervention = bubble.intervention;
        const bubbles = intervention.bubbles;
        intervention.removeBubble(bubble);
        const index = after ? after.index + 1 : 0;
        intervention.injectBubbleAt(bubble, index);
        return bubbles;
      },
      push: async () => {
        const intervention = bubble.intervention;
        const parent = intervention.parent;
        const parentId =
          parent instanceof MessagesIntervention ||
          parent instanceof ActionsIntervention
            ? parent.lastBubble.findRemoteId()
            : parent.findRemoteId();
        return await this.api.sortSteps(
          this.bot.uuid,
          parentId,
          intervention.bubbles.map((b) => b.findRemoteId())
        );
      },
      merge: (intervention, stepCreation) => {
        // Nothing to do
      },
      revert: (bubbles) => {
        bubble.intervention.bubbles = bubbles;
      },
    });
  }

  moveMessageBubble(bubble: MessageBubble, after: MessageBubble | null) {
    return this.executions.execute({
      commit: () => {
        const intervention = bubble.intervention;
        const bubbles = intervention.bubbles;
        intervention.removeBubble(bubble);
        const index = after ? after.index + 1 : 0;
        intervention.injectBubbleAt(bubble, index);
        return bubbles;
      },
      push: async () => {
        const newParentId = after
          ? after.findRemoteId()
          : bubble.intervention.parent.findChainableRemoteId();
        return await this.api.moveStep(
          this.bot.uuid,
          bubble.findRemoteId(),
          newParentId
        );
      },
      merge: (intervention, stepCreation) => {
        // Nothing to do
      },
      revert: (bubbles) => {
        bubble.intervention.bubbles = bubbles;
      },
    });
  }

  createMessagesIntervention(
    parent: EntryPoint | ChoiceBubble | ActionsIntervention
  ) {
    const locale = this.bot.natural_locale;
    return this.executions.execute({
      commit: () => {
        const intervention = new MessagesIntervention(this.dialog);
        parent.ahead = intervention;
        intervention.register();
        const bubble = new MessageBubble(null);
        bubble.dialog = this.dialog;
        intervention.appendBubble(bubble);
        bubble.register();
        return intervention;
      },
      push: async () => {
        const botUuid = this.bot.uuid;
        return await this.api.createEmptyStep(
          botUuid,
          parent.findChainableRemoteId(),
          locale,
          "BI",
          false
        );
      },
      merge: (intervention, stepCreation) => {
        const createdBubble = intervention.bubbles[0];
        createdBubble.remoteId = stepCreation.id;
        createdBubble.setName(stepCreation.name);
      },
      revert: (intervention) => {
        intervention.destroy();
      },
    });
  }

  appendMessageBubble(intervention: MessagesIntervention) {
    return this.executions.execute({
      commit: () => {
        const bubble = new MessageBubble(null);
        bubble.dialog = this.dialog;
        intervention.appendBubble(bubble);
        bubble.register();
        return bubble;
      },
      push: async (bubble) => {
        const previous = bubble.previous;
        if (!previous) throw new Error("No previous");
        return await this.api.createEmptyStep(
          this.bot.uuid,
          bubble.previous.findRemoteId(),
          this.bot.natural_locale,
          "BI",
          !!intervention.ahead
        );
      },
      merge: (bubble, stepCreation) => {
        bubble.remoteId = stepCreation.id;
        bubble.setName(stepCreation.name);
      },
      revert: (bubble) => {
        bubble.destroy();
      },
    });
  }

  removeMessageBubble(bubble: MessageBubble) {
    return this.executions.execute({
      commit: () => {
        const intervention = bubble.intervention;
        const dropChildren = bubble.isLast && !intervention.ahead;
        bubble.destroy();
        return dropChildren;
      },
      push: async (dropChildren) => {
        return await this.api.deleteStep(
          this.bot.uuid,
          bubble.findRemoteId(),
          dropChildren
        );
      },
      merge: (bubble, stepCreation) => {
        // Nothing to do
      },
      revert: (restoreFn) => {
        bubble.restore();
      },
    });
  }

  removeActionBubble(bubble: ActionBubble) {
    return this.executions.execute({
      commit: () => {
        const intervention = bubble.intervention;
        const dropChildren = bubble.isLast && !intervention.ahead;
        bubble.destroy();
        return dropChildren;
      },
      push: async (dropChildren) => {
        return await this.api.deleteStep(
          this.bot.uuid,
          bubble.findRemoteId(),
          dropChildren
        );
      },
      merge: (bubble, stepCreation) => {
        // Nothing to do
      },
      revert: (restoreFn) => {
        bubble.restore();
      },
    });
  }

  createChoicesIntervention(
    parent: MessagesIntervention | ChoiceBubble | ActionsIntervention
  ) {
    return this.executions.execute({
      commit: () => {
        const intervention = new ChoicesIntervention(this.dialog);
        parent.ahead = intervention;
        intervention.register();
        const bubble = new ChoiceBubble(null);
        bubble.dialog = this.dialog;
        intervention.appendBubble(bubble);
        bubble.register();
        return intervention;
      },
      push: async () => {
        const botUuid = this.bot.uuid;
        const parentId = parent.findChainableRemoteId();
        const locale = this.bot.natural_locale;
        return await this.api.createEmptyStep(
          botUuid,
          parentId,
          locale,
          "CH",
          false
        );
      },
      merge: (intervention, stepCreation) => {
        const createdBubble = intervention.bubbles[0];
        createdBubble.remoteId = stepCreation.id;
        createdBubble.setName(stepCreation.name);
      },
      revert: (intervention) => {
        intervention.destroy();
      },
    });
  }

  createActionsIntervention(
    parent: MessagesIntervention | ChoiceBubble,
    initialBubble: ActionBubble
  ) {
    return this.executions.execute({
      commit: () => {
        // Intervention
        const intervention = new ActionsIntervention(this.dialog);
        parent.ahead = intervention;
        intervention.register();
        // Bubble
        initialBubble.dialog = this.dialog;
        intervention.appendBubble(initialBubble);
        initialBubble.register();
        return intervention;
      },
      push: async () => {
        const botUuid = this.bot.uuid;
        const bubble = "lastBubble" in parent ? parent.lastBubble : parent;
        const parentId = bubble.findRemoteId();
        const locale = this.bot.natural_locale;
        return await this.api.createEmptyActionStep(
          botUuid,
          parentId,
          locale,
          initialBubble.getAction(),
          initialBubble.getActionArgs(),
          false
        );
      },
      merge: (intervention, stepCreation) => {
        const createdBubble = intervention.bubbles[0];
        createdBubble.remoteId = stepCreation.id;
      },
      revert: (intervention) => {
        intervention.destroy();
      },
    });
  }

  appendChoiceBubble(intervention: ChoicesIntervention) {
    const parent = intervention.parent;
    let parentId: number;
    if (parent instanceof MessagesIntervention)
      parentId = parent.lastBubble.findRemoteId();
    else if (parent instanceof ChoiceBubble) parentId = parent.findRemoteId();
    else if (parent instanceof ActionsIntervention)
      parentId = parent.lastBubble.findRemoteId();

    return this.executions.execute({
      commit: () => {
        const bubble = new ChoiceBubble(null);
        bubble.dialog = this.dialog;
        intervention.appendBubble(bubble);
        bubble.register();
        return bubble;
      },
      push: async () => {
        return await this.api.createEmptyStep(
          this.bot.uuid,
          parentId,
          this.bot.natural_locale,
          "CH",
          false
        );
      },
      merge: (bubble, stepCreation) => {
        bubble.remoteId = stepCreation.id;
        bubble.setName(stepCreation.name);
      },
      revert: (bubble) => {
        bubble.destroy();
      },
    });
  }

  appendActionBubble(intervention: ActionsIntervention, bubble: ActionBubble) {
    return this.executions.execute({
      commit: () => {
        bubble.dialog = this.dialog;
        intervention.appendBubble(bubble);
        bubble.register();
        return bubble;
      },
      push: async (bubble) => {
        const previous = bubble.previous;
        if (!previous) throw new Error("No previous bubble");
        return await this.api.createEmptyActionStep(
          this.bot.uuid,
          previous.findRemoteId(),
          this.bot.natural_locale,
          bubble.getAction(),
          bubble.getActionArgs(),
          false
        );
      },
      merge: (bubble, stepCreation) => {
        bubble.remoteId = stepCreation.id;
      },
      revert: (bubble) => {
        bubble.destroy();
      },
    });
  }

  removeChoiceBubble(bubble: ChoiceBubble) {
    return this.executions.execute({
      commit: () => {
        bubble.destroy();
      },
      push: async () => {
        return await this.api.deleteStep(
          this.bot.uuid,
          bubble.findRemoteId(),
          true
        );
      },
      merge: (bubble, stepCreation) => {
        // Nothing to do
      },
      revert: () => {
        bubble.restore();
      },
    });
  }
}

export const BotManagerContext = createRequiredContext<BotManager>();
