/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useEffect, useRef } from "react";
import Icon from "src/components/Icon";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale } from "src/utilities/Locales";
import Zones from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import { feedback, feedbackItem } from "../feedbacks";
import FromParentStroke from "../strokes/FromParentStroke";
import { FilesMetadata, useTranslationController } from "../utils";
import Input from "./Input";
import Structure from "./Structure";
import Suggestion from "./Suggestion";

export default function ChoiceLine() {
  const manager = BotManagerContext.use();
  const controller = useTranslationController();

  const { natural_locale } = manager.useSelector(
    () => ({
      natural_locale: manager.bot.natural_locale,
    }),
    []
  );

  const { fileKey, bubble, unfolded } = controller.useFileSelector((c, key) => {
    return {
      fileKey: key,
      bubble: c.findFileData(key) as ChoiceBubble,
      unfolded: c.isFileUnfolded(key),
    };
  }, []);

  const targetLocale = Zones.useParam("locale") as Locale;

  const source = bubble.getLabel(natural_locale);
  const target = bubble.getLabel(targetLocale);

  const activeColor = Theme.colors.purple;

  const onSave = useCallback(
    (label: string) => {
      if (label === target) return;
      controller.setUnfolded(fileKey, true);
      const exec = manager.updateLabel(bubble, targetLocale, label);
      feedback(controller, exec);
      feedbackItem(FilesMetadata, exec, fileKey);
    },
    [target, fileKey, targetLocale]
  );

  const onToggle = useCallback(() => {
    controller.setUnfolded(fileKey, !unfolded);
  }, [fileKey, unfolded]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const el = textareaRef.current;
    if (!el) return;
    const onFocus = () => controller.setUnfolded(fileKey, true);
    el.addEventListener("focus", onFocus);
    return () => {
      el.removeEventListener("focus", onFocus);
    };
  }, []);

  return (
    <Fragment>
      <Structure
        value={source}
        activeColor={activeColor}
        icon={
          <Icon name={"open-in-new"} typo="body" square color={activeColor} />
        }
        toggler
        stroke={<FromParentStroke />}
        onClick={onToggle}
      />
      <Input
        activeColor={activeColor}
        textareaRef={textareaRef}
        value={target}
        onSave={onSave}
      />
      <Suggestion value={source} onSave={onSave} activeColor={activeColor} />
    </Fragment>
  );
}
