/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import NodeInput from "src/components/Nodes/NodeInput";
import StepSuggestions from "src/components/Nodes/StepView/StepSuggestions";
import styles from "src/components/Nodes/styles";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function EmailBubbleView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { itemId, bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as UrlBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const { locale } = itemsMetadata.use(itemId);

  const email = bubble.getLabel(locale);
  const [emailValue, setEmailValue] = useState<string>(email);

  const saveEmail = useCallback(
    (newEmail: string) => {
      if (email === newEmail) return;
      const execution = manager.updateLabelAndActionArgs(
        bubble,
        locale,
        newEmail
      );
      feedback(controller, execution);
      feedbackItem(itemsMetadata, execution, bubbleId);
    },
    [email, bubble, locale]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      const email = e.target.value;
      saveEmail(email);
    },
    [saveEmail]
  );

  const containerCss = css({
    borderRadius: 10,
    background: Theme.colors.pink,
    color: Theme.colors.white,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeActionBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon="step-email"
              title="Envoi d'un email"
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={styles.content}>
              <NodeInput
                value={emailValue}
                onChange={setEmailValue}
                onBlur={onBlur}
                noNewLines
              />
            </div>
            <Spacer size={4} />
          </div>
          <Revealable revealed={selected} onHeightStartChange={setFooterHeight}>
            <StepSuggestions />
            <NodeFooter translationControl={true} />
          </Revealable>
        </div>
      </AllStepsView>
      <Spacer size={10} />
    </Fragment>
  );
}
