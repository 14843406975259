import { without } from "lodash";
import {
  default as BotApiTypes,
  default as ChatbotApiTypes,
} from "./BotApi/BotApiTypes";
import BotStructureError from "./BotStructureError";
import { Locale } from "./Locales";

export namespace DraftBot {
  export type StepKind = BotApiTypes.StepKind;
  export type StepAction = BotApiTypes.StepAction;

  export type Script = Array<Step>;

  export type Step = {
    id: number;
    data: {
      kind: BotApiTypes.StepKind;
      action: BotApiTypes.StepAction | null;
      action_args: string | null;
      name: string;
      targetable: boolean;
      label: Partial<{ [locale in Locale]: number }>;
    };
    children?: Array<Step>;
  };
}

export type TranslationLabelIds = { [stepId: number]: number };

export type TranslationTexts = { [labelId: number | string]: string };

export type TranslationBundle = {
  locale: Locale;
  labelIds: TranslationLabelIds;
  texts: TranslationTexts;
};

function rawStepsToDraftScript(
  raws: Array<ChatbotApiTypes.RawStep>
): DraftBot.Script {
  const root = raws.find((r) => r.kind === "RT");
  if (!root) throw new BotStructureError("No root step found");
  const index: Record<string, DraftBot.Step> = {};

  const rootStep = rawStepToDraftStep(root);
  index[root.path] = rootStep;

  const otherRaws = without(raws, root);
  otherRaws.forEach((raw) => {
    const parentPath = raw.path.slice(0, -4);
    const parentStep = index[parentPath];
    if (!parentStep) {
      throw new BotStructureError("Raw step has no parent", { raw });
    }
    const step = rawStepToDraftStep(raw);
    parentStep.children = parentStep.children || [];
    parentStep.children.push(step);
    index[raw.path] = step;
  });

  const bot: DraftBot.Script = [rootStep];
  return bot;
}

function localizedRawStepsToTranslationLabelIds(
  raws: Array<ChatbotApiTypes.LocalizedRawStep>,
  locale: Locale
): TranslationLabelIds {
  const mapping: TranslationLabelIds = {};
  raws.forEach((raw) => {
    if (raw.language_code === locale) mapping[raw.id] = raw.label_id;
  });
  return mapping;
}

function rawStepToDraftStep(raw: ChatbotApiTypes.RawStep): DraftBot.Step {
  const step: DraftBot.Step = {
    id: raw.id,
    data: {
      kind: raw.kind,
      action: raw.action,
      action_args: raw.action_args,
      label: {},
      name: raw.name,
      targetable: raw.targetable,
    },
  };

  return step;
}

function localizedRawStepsToTranslationTexts(
  raws: Array<ChatbotApiTypes.LocalizedRawStep>,
  locale: string
): TranslationTexts {
  const output: TranslationTexts = {};
  raws.forEach((raw) => {
    if (raw.language_code === locale) output[raw.label_id] = raw.label;
  });
  return output;
}

function localizedRawStepsToTranslationBundle(
  raws: Array<ChatbotApiTypes.LocalizedRawStep>,
  locale: Locale
): TranslationBundle {
  return {
    locale,
    labelIds: localizedRawStepsToTranslationLabelIds(raws, locale),
    texts: localizedRawStepsToTranslationTexts(raws, locale),
  };
}

const BotAdapaters = {
  rawStepsToDraftScript,
  localizedRawStepsToTranslationLabelIds,
  localizedRawStepsToTranslationTexts,
  localizedRawStepsToTranslationBundle,
};

export default BotAdapaters;
