/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { difference, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import Bubble from "src/utilities/BotDialog/Bubble";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import ChoicesIntervention from "src/utilities/BotDialog/ChoicesIntervention";
import { ActionBubble } from "src/utilities/BotDialog/components";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale } from "src/utilities/Locales";
import ModelMetadataManager from "src/utilities/ModelMetadataManager";
import Navigation from "src/utilities/Navigation";
import Toasts from "src/utilities/Toasts";
import TreeExplorer from "src/utilities/TreeExplorer";
import TreeStepper from "src/utilities/TreeStepper";
import {
  TreeStepperContext,
  TreeStepperController,
} from "src/utilities/TreeStepper/TreeStepperController";
import { ColumnExtractorFn } from "src/utilities/TreeStepper/types";
import ChatbotSimulatorController from "../ChatbotSimulator/ChatbotSimulatorController";
import ChildrenComponent from "./ColumnView";
import {
  Column,
  columnKeyExtractor,
  EmptyColumn,
  Item,
  ItemMetadata,
  itemsExtractor,
  ItemsMetadataContext,
  stepKeyExtractor,
} from "./config";
import StepComponent from "./StepView";

export default function ChatbotEditorStepper() {
  const manager = BotManagerContext.use();

  const itemsMetadata = useMemo(() => {
    return new ModelMetadataManager<ItemMetadata>(() => ({
      locale: manager.bot.natural_locale,
      loading: false,
      played: false,
    }));
  }, [manager.bot.natural_locale]);

  const requestedLocales = itemsMetadata.updater.useValue(() => {
    return uniq(Object.values(itemsMetadata.index).map((v) => v.locale));
  }, []);

  const handledLocales = manager.useSelector(() => {
    return Object.keys(manager.localesPromises) as Array<Locale>;
  }, []);

  const loadTranslations = useCallback(async (locale: Locale) => {
    const toastId = Toasts.show({
      type: "info",
      title: `Langue ${locale}`,
      message: "Chargement en cours...",
      dismiss: "none",
    });
    try {
      await manager.loadTranslations(locale);
      Toasts.update(toastId, (t) => ({
        ...t,
        type: "success",
        message: "Chargement terminé !",
        dismiss: 1000,
      }));
    } catch (err) {
      Toasts.update(toastId, (t) => ({ ...t, ...Toasts.fromError(err) }));
    }
  }, []);

  useEffect(() => {
    const localesToLoad = difference(requestedLocales, handledLocales);
    localesToLoad.forEach((l) => loadTranslations(l));
  }, [requestedLocales.join(), handledLocales.join()]);

  const controllerRef = useRef<TreeStepperController<Column, Item>>();

  const columnExtractor: ColumnExtractorFn<Column, Item> = useCallback(
    (column, controller) => {
      if (column instanceof EmptyColumn) {
        return null;
      }
      if (column instanceof EntryPoint) {
        return column.ahead || EmptyColumn.after(column, manager.dialog);
      } else if (column instanceof MessagesIntervention) {
        return column.ahead || EmptyColumn.after(column, manager.dialog);
      } else if (column instanceof ActionsIntervention) {
        if (column.ahead) return column.ahead;
        else {
          if (column.lastBubble.isEnding()) return null;
          else return EmptyColumn.after(column, manager.dialog);
        }
      } else if (column instanceof ChoicesIntervention) {
        const selectedItem = controller.getSelectedItem(column.id);
        if (selectedItem === null) {
          return null;
        } else {
          const bubble = column.findBubble(selectedItem);
          return bubble.ahead || EmptyColumn.after(bubble, manager.dialog);
        }
      } else {
        throw new Error("Cannot extract column");
      }
    },
    []
  );

  useEffect(() => {
    if (!controllerRef.current) return;
    const unsub = controllerRef.current.updater.onUpdate(() => {
      manager.updater.update();
    });
    return unsub;
  }, []);

  const preselection = Navigation.useQueryParam("step", null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const controller = controllerRef.current;
    if (!controller) return;
    if (!preselection) return;
    const steps = TreeExplorer.getPath<Item | Column>(
      [manager.dialog.getEntryPoint()],
      (s) => {
        if (s instanceof EntryPoint) return s.ahead || [];
        else if (s instanceof ChoicesIntervention) return s.bubbles;
        else if (s instanceof ChoiceBubble) return s.ahead || [];
        else if (s instanceof MessagesIntervention) return s.bubbles;
        else if (s instanceof MessageBubble)
          return s.isLast ? s.intervention.ahead : null;
        else if (s instanceof ActionsIntervention) return s.bubbles;
        else if (s instanceof ActionBubble) {
          return s.isLast ? s.intervention.ahead : null;
        }
        return [];
      },
      (i) => i.id === preselection
    );
    if (steps) {
      const toBeSelected = steps.filter((s) => s instanceof Bubble);
      toBeSelected.forEach((s) => controller.selectItem(s.id));
    }
    searchParams.delete("step");
    setSearchParams(searchParams);
  }, [preselection]);

  const containerCss = css`
    height: calc(100vh - 74px - 74px);
    padding: 20px;
  `;

  const simulator = ChatbotSimulatorController.use();

  useEffect(() => {
    Object.keys(itemsMetadata.index).forEach((k) =>
      itemsMetadata.update(k, (m) => ({ ...m, played: false }))
    );
  }, [simulator]);

  return (
    <ItemsMetadataContext.Provider value={itemsMetadata}>
      <TreeStepper<Column, Item>
        containerCss={containerCss}
        controllerRef={controllerRef}
        column={manager.dialog.getEntryPoint()}
        ColumnComponent={ChildrenComponent}
        ItemComponent={StepComponent}
        itemsExtractor={itemsExtractor}
        columnExtractor={columnExtractor}
        itemKeyExtractor={stepKeyExtractor}
        columnKeyExtractor={columnKeyExtractor}
        minimumOffset={200}
      />
    </ItemsMetadataContext.Provider>
  );
}

export function useChatbotTreeStepperController() {
  return TreeStepperContext.use() as TreeStepperController<Column, Item>;
}
