/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import Clickable from "src/components/Clickable";
import Divider from "src/components/Divider";
import Flag from "src/components/Flag";
import Intersperse from "src/components/Intersperse";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import TranslateBubble from "src/utilities/BotDialog/TranslateBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale, localeToText } from "src/utilities/Locales";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function TranslateBubbleView() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as TranslateBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const containerCss = css({
    borderRadius: 10,
    background: Theme.colors.pink,
    color: Theme.colors.white,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeActionBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  const currentLocale = bubble.getActionArgs();

  const onChangeLocale = useCallback((locale: Locale) => {
    const exec = manager.updateActionArgs(bubble, locale);
    feedback(controller, exec);
    feedbackItem(itemsMetadata, exec, bubble.id);
  }, []);

  const resultZoneCss = css({
    background: "white",
    marginInline: 4,
    borderRadius: 8,

    color: "black",
  });

  const localeCss = css({
    padding: 8,
    display: "flex",
    alignItems: "center",
    width: "100%",
  });

  const locales = useMemo(() => manager.getAllLocales(), []);

  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon={bubble.getIcon()}
              title="Changement de langue"
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={resultZoneCss}>
              <Intersperse
                between={() => <Divider color={rgba("black", 0.1)} />}
              >
                {locales.map((locale) => (
                  <Clickable
                    css={localeCss}
                    onClick={() => onChangeLocale(locale)}
                  >
                    <Flag code={locale} size={20} />
                    <Spacer />
                    <Typo
                      name={locale === currentLocale ? "bold" : "body"}
                      opacity={locale === currentLocale ? 1 : 0.5}
                    >
                      {localeToText(locale)}
                    </Typo>
                  </Clickable>
                ))}
              </Intersperse>
            </div>
            <Spacer size={4} />
            <Revealable revealed={selected}>
              <NodeFooter />
            </Revealable>
          </div>
        </div>
      </AllStepsView>
      <Spacer size={10} />
    </Fragment>
  );
}
