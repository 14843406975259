import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import decodeJwt from "jwt-decode";
import Toasts from "./Toasts";
import Updater from "./Updater";

export type Tokens = {
  access: string;
  refresh: string;
};

export type DecodedAccessToken = {
  id: number;
  exp: number;
};

type AuthResponse = {
  access: string;
  email: string;
  refresh: string;
  username: string;
};

type RefreshResponse = {
  token: {
    access: string;
    refresh: string;
  };
};

export default class AuthorizationService {
  private tokens: Tokens | null = null;
  private localStorageKey: string;
  private api: AxiosInstance;
  private updater = new Updater();

  constructor(localStorageKey: string, api: AxiosInstance) {
    this.localStorageKey = localStorageKey;
    this.api = api;
  }

  async init() {
    await this.read();
  }

  async read() {
    try {
      const stored = localStorage.getItem(this.localStorageKey);
      if (stored === null) return;
      const parsed = JSON.parse(stored);
      this.tokens = parsed;
    } catch (err) {
      this.tokens = null;
    } finally {
      this.updater.update();
    }
  }

  setTokens(tokens: Tokens) {
    this.tokens = tokens;
    localStorage.setItem(this.localStorageKey, JSON.stringify(tokens));
    this.updater.update();
  }

  clearTokens() {
    this.tokens = null;
    localStorage.removeItem(this.localStorageKey);
    this.updater.update();
  }

  async getAccessToken(): Promise<string | null> {
    if (!this.tokens) return null;
    const decoded = decodeJwt<DecodedAccessToken>(this.tokens.access);
    const expirationDate = dayjs(decoded.exp * 1000);
    if (expirationDate.isAfter(dayjs())) {
      return this.tokens.access;
    } else {
      await this.refresh();
      return await this.getAccessToken();
    }
  }

  async refresh() {
    if (!this.tokens) throw new Error("Cannot refresh as there is no tokens");
    const decoded = decodeJwt<DecodedAccessToken>(this.tokens.refresh);
    const expirationDate = dayjs(decoded.exp * 1000);
    if (expirationDate.isAfter(dayjs())) {
      try {
        const response = await this.api.get<RefreshResponse>(
          "/api/pdl/auth/refresh/",
          { headers: { Authorization: `Bearer ${this.tokens.refresh} ` } }
        );
        this.setTokens({
          access: response.data.token.access,
          refresh: response.data.token.refresh,
        });
      } catch (err) {
        this.clearTokens();
      }
    } else {
      Toasts.show({
        title: "Déconnexion",
        message: "Votre session est expirée",
        type: "info",
        dismiss: 1000,
      });
      this.clearTokens();
    }
  }

  useIsLoggedIn() {
    return this.updater.useValue(() => !!this.tokens);
  }
}
