/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect } from "react";
import { useDrop } from "react-dnd";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import {
  InterventionViewProps,
  ItemsMetadataContext,
} from "src/components/ChatbotEditorStepper/config";
import {
  feedback,
  feedbackItem,
} from "src/components/ChatbotEditorStepper/feedbacks";
import Spacer from "src/components/Spacer";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import { BotManagerContext } from "src/utilities/BotManager";
import ChatbotLines from "src/utilities/ChatbotLines";
import Theme from "src/utilities/Theme";
import Droper from "../../Nodes/Fragments/Droper";

export default function MessagesInterventionView(props: InterventionViewProps) {
  const { ItemsComponent } = props;

  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { columnKey, intervention } = controller.useColumnSelector(
    (c, columnKey) => ({
      columnKey,
      intervention: c.findColumnData(columnKey) as MessagesIntervention,
      column: c.findColumn(columnKey),
      items: c.getColumnItems(columnKey),
      selectedItem: c.getSelectedItem(columnKey),
    })
  );

  const stepsWidth = 300;
  const linesWidth = ChatbotLines.useLinesWidth(intervention);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    width: stepsWidth,
    marginLeft: linesWidth,
    transition: "margin-left 200ms, padding-top 200ms",
  });

  controller.useColumnWidth(columnKey, stepsWidth + linesWidth);
  controller.useColumnHeaderHeight(columnKey, 10);

  useEffect(() => {
    controller.selectItem(intervention.lastBubble.id);
  }, []);

  const [{ dropVisible, dropOver }, dropRef] = useDrop(
    {
      accept: columnKey,
      collect: (monitor) => {
        return {
          dropVisible: monitor.canDrop(),
          dropOver: monitor.isOver(),
        };
      },
      drop: (drop: { bubbleId: string }) => {
        const bubble = controller.findItemData(drop.bubbleId) as MessageBubble;
        const exec = manager.moveMessageBubble(bubble, null);
        feedback(controller, exec);
        feedbackItem(itemsMetadata, exec, drop.bubbleId);
      },
    },
    []
  );

  return (
    <div css={containerCss}>
      <Droper
        dropRef={dropRef}
        visible={dropVisible}
        over={dropOver}
        lineColor={Theme.colors.teal}
      />
      <Spacer size={10} />
      <ItemsComponent />
      <Spacer size={30} />
      <AppendMessageButton />
    </div>
  );
}

function AppendMessageButton() {
  const manager = BotManagerContext.use();
  const itemsMetadata = ItemsMetadataContext.use();
  const controller = useChatbotTreeStepperController();

  const { intervention, distance } = controller.useColumnSelector(
    (c, columnKey) => ({
      intervention: c.findColumnData(columnKey) as MessagesIntervention,
      distance: c.getColumnDistanceFromLastOne(columnKey),
    })
  );

  const onAddBotInteraction = useCallback(() => {
    const execution = manager.appendMessageBubble(intervention);
    feedback(controller, execution);
    execution.events.on("commit-done", (r) => {
      controller.selectItem(r.id);
      feedbackItem(itemsMetadata, execution, r.id);
    });
  }, [intervention]);

  return (
    <Buttons align="center">
      <Button2
        icon="chat-plus-outline"
        label="Ajouter un message"
        onClick={onAddBotInteraction}
        layout="solid"
        color={Theme.colors.teal}
      />
    </Buttons>
  );
}
