/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropsWithCss from "src/utilities/PropsWithCss";
import { TypoDescriptors, TypoName } from "src/utilities/Typos";
import NoeseIcons from "./NoeseIcons/NoeseIcons.generated";
import "./NoeseIcons/style.css";

export type IconProps = PropsWithCss<{
  name: NoeseIcons;
  square?: boolean;
  color?: string;
  rotate?: string;
}> &
  IconSizeConfig;

export default function Icon(props: IconProps) {
  const { name, square, color, rotate, className, ...sizeConfig } = props;

  let size = getIconHeight(sizeConfig);

  let style = css({
    display: "inline-block",
    lineHeight: `${size}px !important`,
    fontSize: `${size}px !important`,
    height: `${size}px !important`,
    color,
    flexShrink: 0,
    transition: "all 100ms",
    verticalAlign: "bottom",
  });

  if (square) {
    style = css(style, {
      textAlign: "center",
      width: size,
    });
  }

  if (rotate) {
    style = css(style, { transform: `rotate(${rotate})` });
  }

  return <span css={style} className={`noese-${name} ${className}`} />;
}

type IconSizeByTypo = {
  typo: TypoName;
  scale?: number;
};

type IconSizeByHeight = {
  height: number;
};

export type IconSizeConfig = IconSizeByTypo | IconSizeByHeight;

export function getIconHeight(config: IconSizeConfig) {
  let size = 0;
  if ("typo" in config) {
    const scale = config.scale || 1;
    size = TypoDescriptors[config.typo].height * scale;
  } else {
    size = config.height;
  }
  return size;
}
