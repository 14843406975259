import { DraftBot, TranslationBundle, TranslationTexts } from "../BotAdapters";
import BotManager from "../BotManager";
import { Locale } from "../Locales";
import Bubble from "./Bubble";
import EntryPoint from "./EntryPoint";
import { IndexedItem } from "./types";

class BotDialog {
  readonly index: {
    [id: string]: IndexedItem;
  } = {};
  private entryPoint: EntryPoint;
  readonly texts: Partial<{ [locale in Locale]: TranslationTexts }> = {};

  constructor(readonly manager: BotManager, script: DraftBot.Script) {
    this.entryPoint = EntryPoint.fromSteps(script, this);
  }

  register(item: IndexedItem) {
    this.index[item.id] = item;
  }

  unregister(item: IndexedItem) {
    delete this.index[item.id];
  }

  find(id: string) {
    const value = this.index[id];
    if (!value) throw new Error(`Not found : ${id}`);
    return this.index[id];
  }

  getEntryPoint() {
    return this.entryPoint;
  }

  toReleaseScript() {
    const entryPoint = this.getEntryPoint();
    return entryPoint.toDraftSteps();
  }

  addTranslations(bundle: TranslationBundle) {
    const { locale, labelIds, texts } = bundle;
    const items = Object.values(this.index);
    items.forEach((item) => {
      if (item instanceof EntryPoint) {
        const labelId = labelIds[item.findRemoteId()];
        item.setLabelId(locale, labelId);
      } else if (item instanceof Bubble) {
        if (item.remoteId === null) return;
        const labelId = labelIds[item.remoteId];
        item.setLabelId(locale, labelId);
      }
    });
    this.texts[locale] = texts;
  }

  setText(locale: Locale, labelId: number | string, text: string) {
    const texts = this.texts[locale] || {};
    this.texts[locale] = texts;
    texts[labelId] = text;
  }

  getText(locale: Locale, labelId: number | string) {
    const texts = this.texts[locale] || {};
    return texts[labelId];
  }

  moveText(
    locale: Locale,
    oldLabelId: string | number,
    newLabelId: string | number
  ) {
    if (oldLabelId === newLabelId) return;
    const texts = this.texts[locale] || {};
    this.texts[locale] = texts;
    texts[newLabelId] = texts[oldLabelId];
    delete texts[oldLabelId];
  }

  countTranslations(locale: Locale, emptyOnes: boolean) {
    return this.entryPoint.countTranslations(locale, emptyOnes);
  }

  getErrors() {
    return this.entryPoint.getErrors();
  }

  getTargetableItems() {
    return this.getEntryPoint().getTargetableItems();
  }

  toReleaseTranslations(locale: Locale) {
    return this.texts[locale] || {};
  }
}

export default BotDialog;
