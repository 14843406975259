/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Outlet } from "react-router-dom";
import TopBar from "src/components/TopBar";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import AuthScreen from "./AuthScreen";

export default function App() {
  const { authorization } = Services.use();
  const token = authorization.useIsLoggedIn();

  const containerCss = css({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  });

  const contentCss = css({
    minHeight: `calc(100vh - ${Theme.sizes.topbarHeight}px)`,
    display: "flex",
    flexDirection: "column",
  });

  return (
    <div css={containerCss}>
      <TopBar />
      <div css={contentCss}>{token ? <Outlet /> : <AuthScreen />}</div>
    </div>
  );
}
