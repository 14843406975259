/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

type SpacerProps = {
  size?: number;
  direction?: "both" | "vertical" | "horizontal";
  grow?: boolean;
  inline?: boolean;
  scale?: number;
};

function Spacer(props: SpacerProps) {
  const {
    size = 10,
    direction = "both",
    grow = false,
    inline,
    scale = 1,
  } = props;

  const s = size * scale;

  const containerCss = css`
    width: ${direction === "vertical" ? 0 : s}px;
    height: ${direction === "horizontal" ? 0 : s}px;
    flex-grow: ${grow ? 1 : 0};
    flex-shrink: 0;
    display: ${inline ? "inline-block" : "block"};
  `;

  return <div css={containerCss} />;
}

export default Spacer;
