/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ChatbotPlayer from "src/components/ChatbotSimulator";
import ChatbotSimulatorController from "src/components/ChatbotSimulator/ChatbotSimulatorController";
import ChatbotEditorBar from "src/components/ChatbotTabbar";
import { default as BotAdapaters } from "src/utilities/BotAdapters";
import ChatbotApi from "src/utilities/BotApi";
import BotManager, { BotManagerContext } from "src/utilities/BotManager";
import DelayedZone from "src/utilities/DelayedZone";
import Environment from "src/utilities/Environment";
import Zones from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function ChatbotHome() {
  const { authorization } = Services.use();
  const uuid = Zones.useParam("chatbot");

  const manager = useMemo(async () => {
    const api = new ChatbotApi(
      { url: Environment.findString("REACT_APP_API_ORIGIN") },
      { authorization }
    );
    const bot = await api.getBot(uuid);
    const locale = bot.natural_locale;
    const rawSteps = await api.loadRawSteps(uuid, locale);
    const script = BotAdapaters.rawStepsToDraftScript(rawSteps);
    const bundle = BotAdapaters.localizedRawStepsToTranslationBundle(
      rawSteps,
      locale
    );
    const manager = new BotManager(api, bot, script);
    manager.addTranslations(bundle);
    return manager;
  }, []);

  const isOnHome = Zones.useIsInRoute("ChatbotHome");

  if (isOnHome) {
    return (
      <Navigate
        to={Zones.getPath("ChatbotEdition", { params: { chatbot: uuid } })}
        replace={true}
      />
    );
  } else {
    return (
      <DelayedZone promise={manager}>
        {(manager) => <ChatbotScreenView manager={manager} />}
      </DelayedZone>
    );
  }
}

type ChatbotScreenViewProps = {
  manager: BotManager;
};

function ChatbotScreenView(props: ChatbotScreenViewProps) {
  const { manager } = props;
  const player = ChatbotSimulatorController.use();

  const containerCss = css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
  });

  const contentCss = css({
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  });

  const playerCss = css({
    flexShrink: 0,
  });

  return (
    <BotManagerContext.Provider value={manager}>
      <ChatbotEditorBar />
      <div css={containerCss}>
        <div css={contentCss}>
          <Outlet />
        </div>
        {player ? (
          <div css={playerCss}>
            <ChatbotPlayer />
          </div>
        ) : null}
      </div>
    </BotManagerContext.Provider>
  );
}
