/** @jsxImportSource @emotion/react */
import React, { useCallback } from "react";
import { inputCss } from "./styles";

type TextFieldProps = {
  label: string;
  value: string;
  onChange: (s: string) => any;
};

export default function TextField(props: TextFieldProps) {
  const { label, value, onChange } = props;

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <input
      type="text"
      value={value}
      onChange={onInputChange}
      css={inputCss}
      placeholder={label}
    />
  );
}
