/** @jsxImportSource @emotion/react */

import React, { Fragment, ReactNode, useMemo, useState } from "react";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import TextField from "src/components/Fields/TextField";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { BotManagerContext } from "src/utilities/BotManager";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { Panel } from "../ChatbotSettings";
import { PublicationTask } from "./types";

type ReleaseNowProps = {
  isFirst: boolean;
  onDone: (task: PublicationTask) => any;
};

export default function ReleaseNow(props: ReleaseNowProps) {
  const { isFirst, onDone } = props;
  const { botApi } = Services.use();
  const scriptUuid = Navigation.useParam("chatbot");
  const manager = BotManagerContext.use();

  const [label, setLabel] = useState<string>(isFirst ? "Version initiale" : "");

  const [onRelease, releasing] = useSubmitCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const taskId = await botApi.createRelease(scriptUuid, label, true);
      await onDone({ id: taskId, name: label });
    },
    [scriptUuid, label, onDone]
  );

  const errors = useMemo(() => {
    return manager.dialog.getErrors();
  }, []);

  let node: ReactNode = null;
  if (errors.length === 0) {
    node = (
      <form onSubmit={onRelease}>
        <Typo>
          {isFirst
            ? "Ce bot est prêt à être mis en ligne !"
            : "Vos dernières modifications sont prêtes à être mises en ligne !"}
        </Typo>
        <Spacer />
        {isFirst ? null : (
          <Fragment>
            <Spacer />
            <Typo>
              Décrivez succintement les améliorations de cette version et/ou les
              changements apportés depuis la version précédente.
            </Typo>
            <Spacer />
            <TextField
              value={label}
              onChange={setLabel}
              label="Notes de version"
            />
            <Spacer />
          </Fragment>
        )}
        <Buttons align="center">
          <Button2
            label="Mettre en ligne"
            submit
            color={Theme.colors.blue}
            layout="solid"
            loading={releasing}
          />
        </Buttons>
      </form>
    );
  } else {
    node = (
      <Fragment>
        <Typo>
          {isFirst
            ? `Ce bot ne peut pas être mis en ligne car il comporte ${errors.length} erreur(s). Corrigez ces erreurs`
            : `Les dernières modifications ne peuvent pas être mises en ligne car le bot comporte actuellement ${errors.length} erreur(s)`}
        </Typo>
        <Spacer scale={2} />
        <Buttons align="center">
          <Button2
            label="Corriger"
            to={Navigation.getPath("ChatbotHealth")}
            color={Theme.colors.blue}
            layout="solid"
          />
        </Buttons>
      </Fragment>
    );
  }

  return (
    <Panel heading="Mettre en ligne" icon="cloud-arrow-up-outline">
      {node}
    </Panel>
  );
}
