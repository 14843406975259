import { css } from "@emotion/react";
import { rgba } from "polished";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";

export const inputCss = css(Typos.body, {
  backgroundColor: "white",
  height: 48,
  borderRadius: 48 / 2,
  width: "100%",
  paddingInline: 48 / 2,
  border: `1px solid ${rgba(Theme.colors.blue, 0.4)}`,
  transition: "all 200ms",
  outline: `0px solid ${rgba(Theme.colors.blue, 0)}`,
  outlineOffset: -2,
  color: Theme.colors.darkblue,
  "&:focus": {
    border: `1px solid ${rgba(Theme.colors.blue, 1)}`,
    outline: `1px solid ${rgba(Theme.colors.blue, 1)}`,
    color: Theme.colors.blue,
  },
});

export const labelCss = css(Typos.label, {
  paddingLeft: 10,
  paddingBottom: 5,
  display: "block",
});
