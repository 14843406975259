/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";

type ContentProps = PropsWithChildren<{
  scale?: number;
}>;

export default function Content(props: ContentProps) {
  const { scale = 1, children } = props;
  const size = 10 * scale;
  return <div css={css({ paddingInline: size })}>{children}</div>;
}
