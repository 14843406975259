/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback } from "react";
import Zones from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Asset from "./Asset";
import Clickable from "./Clickable";
import Icon from "./Icon";
import Spacer from "./Spacer";
import Typo from "./Typo";

export default function TopBar() {
  const { authorization } = Services.use();

  const loggedIn = authorization.useIsLoggedIn();

  const containerCss = css({
    backgroundColor: Theme.colors.blue,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
    height: Theme.sizes.topbarHeight,
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  });

  const userCss = css({
    height: Theme.sizes.topbarHeight,
    borderBottomLeftRadius: (Theme.sizes.topbarHeight * 2) / 3,
    backgroundColor: Theme.colors.teal,
    paddingLeft: Theme.sizes.topbarHeight,
    paddingRight: Theme.sizes.topbarHeight / 2,
    display: "flex",
    alignItems: "center",
    color: Theme.colors.white,
  });

  const textsCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const onLogout = useCallback(() => {
    authorization.clearTokens();
  }, []);

  return (
    <div css={containerCss}>
      <Spacer size={20} />
      <Clickable to={Zones.getPath("App")}>
        <Asset name="logo" />
      </Clickable>
      <Spacer grow />
      {loggedIn ? (
        <div css={userCss}>
          <div css={textsCss}>
            <Typo name="body">Bienvenue</Typo>
            <Typo name="bold">Vous...</Typo>
          </div>
          <Spacer size={30} />
          <Clickable onClick={onLogout}>
            <Icon name="power" height={24} />
          </Clickable>
        </div>
      ) : null}
    </div>
  );
}
