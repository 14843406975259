/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Spinner from "./Loading";
import Typo from "./Typo";

type LoadingViewProps = { message?: string };

export default function LoadingView(props: LoadingViewProps) {
  const { message = "Chargement en cours..." } = props;

  const containerCss = css({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div css={containerCss}>
      <Spinner height={20} />
      <Typo center>{message}</Typo>
    </div>
  );
}
