/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { MutableRefObject, useMemo } from "react";
import TreeStructureController, {
  TreeStructureControllerConfig,
  TreeStructureControllerContext,
} from "./TreeStructureController";
import TreeStructureFile from "./TreeStructureFile";

type TreeStructureProps<TFile> = {
  controllerRef: MutableRefObject<TreeStructureController<TFile> | undefined>;
  containerCss?: SerializedStyles;
} & TreeStructureControllerConfig<TFile>;

export default function TreeStructure<TFile>(props: TreeStructureProps<TFile>) {
  const { controllerRef, containerCss, ...controllerConfig } = props;

  const controller = useMemo(
    () => new TreeStructureController(controllerConfig),
    []
  );
  controllerRef.current = controller;

  const { files } = controller.updater.useValue(
    () => ({
      files: controller.getFiles(),
    }),
    []
  );

  controller.updater.useValue(() => new Date());

  return (
    <TreeStructureControllerContext.Provider value={controller}>
      {files.map((file) => (
        <TreeStructureFile fileKey={file} key={file} />
      ))}
    </TreeStructureControllerContext.Provider>
  );
}
