/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { FrontError } from "src/utilities/getErrorMessage";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Toasts from "src/utilities/Toasts";
import { Panel } from "../ChatbotSettings";
import { PublicationTask } from "./types";

type TaskCheckerProps = {
  task: PublicationTask;
  onDone: () => any;
};

export default function TaskChecker(props: TaskCheckerProps) {
  const { task, onDone } = props;
  const { botApi } = Services.use();

  const [released, setReleased] = useState<boolean>(false);

  useEffect(() => {
    if (!released) {
      const i = setInterval(async () => {
        const release = await botApi.getPublicationTaskResult(task.id);
        if (release === "Running") return;
        else {
          if (release === "false")
            Toasts.fromError(new FrontError("La mise en ligne a échoué"));
          setReleased(true);
        }
      }, 2000);
      return () => {
        clearInterval(i);
      };
    }
  }, [released, task.id]);

  useEffect(() => {
    if (released) onDone();
  }, [released, onDone]);

  return (
    <Panel heading="En cours de mise en ligne" icon="cloud-arrow-up">
      <Typo name="subheading" color={Theme.colors.pink}>
        {task.name}
      </Typo>
      <Spacer />
      <Typo>
        La mise en ligne peut prendre plusieurs minutes… La version en ligne
        apparaîtra ici dès que la mise en ligne sera effective.
      </Typo>
    </Panel>
  );
}
