import { Execution } from "src/utilities/Executions";
import ModelMetadataManager from "src/utilities/ModelMetadataManager";
import TreeStructureController from "src/utilities/TreeStructure/TreeStructureController";
import { ChatbotTranslatorStructureFile, FileMetadata } from "./utils";

export function feedback(
  controller: TreeStructureController<ChatbotTranslatorStructureFile>,
  execution: Execution
) {
  execution.events.on("commit-done", () => {
    controller.compute();
  });
  execution.events.on("merge-done", () => {
    controller.compute();
  });
  execution.events.on("revert-done", () => {
    controller.compute();
  });
}

export function feedbackItem(
  metadata: ModelMetadataManager<FileMetadata>,
  execution: Execution,
  itemKey: string
) {
  execution.events.on("push-started", () =>
    metadata.update(itemKey, (c) => ({ ...c, loading: true }))
  );
  execution.events.on("push-ended", () =>
    metadata.update(itemKey, (c) => ({ ...c, loading: false }))
  );
}
