import ActionBubble from "src/utilities/BotDialog/ActionBubble";
import Bubble from "src/utilities/BotDialog/Bubble";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import GotoBubble from "src/utilities/BotDialog/GotoBubble";
import MessagesIntervention from "src/utilities/BotDialog/MessagesIntervention";
import { Locale } from "src/utilities/Locales";

export class BotError {}
export class EmptyScript extends BotError {
  constructor(private entryPoint: EntryPoint) {
    super();
  }
}
export class DeadPath extends BotError {
  constructor(
    readonly target: ActionBubble | MessagesIntervention | ChoiceBubble
  ) {
    super();
  }
}

export class EmptyDelivery extends BotError {
  constructor(readonly target: Bubble, locale: Locale) {
    super();
  }
}

export class MissingTranslation extends BotError {
  constructor(
    readonly target: Bubble,
    readonly locale: Locale,
    readonly naturalLocaleText: string
  ) {
    super();
  }
}

export class InvalidFormat extends BotError {
  constructor(
    readonly target: Bubble,
    readonly input: string,
    readonly locale: Locale
  ) {
    super();
  }
}

export class InvalidRedirection extends BotError {
  constructor(readonly target: GotoBubble) {
    super();
  }
}
