import { sum, without } from "lodash";
import { BotPlayerTypes } from "src/player/types";
import { BotError, DeadPath } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import NonEmptyArray from "../NonEmptyArray";
import toBeChecked from "../toBeChecked";
import ActionBubble from "./ActionBubble";
import ChoiceBubble from "./ChoiceBubble";
import ChoicesIntervention from "./ChoicesIntervention";
import EmailBubble from "./EmailBubble";
import GotoBubble from "./GotoBubble";
import Intervention from "./Intervention";
import MessagesIntervention from "./MessagesIntervention";
import PhoneBubble from "./PhoneBubble";
import StopBubble from "./StopBubble";
import TranslateBubble from "./TranslateBubble";
import { TargetableItem } from "./types";
import UrlBubble from "./UrlBubble";

export default class ActionsIntervention extends Intervention {
  // Parent

  private parentId: string | null = null;

  set parent(parent: MessagesIntervention | ChoiceBubble) {
    this.parentId = parent.id;
  }

  get parent() {
    if (this.parentId === null)
      throw new Error("MessagesIntervention has no parentIntervention");
    const parent = this.dialog.find(this.parentId);
    if (parent instanceof MessagesIntervention) return parent;
    else if (parent instanceof ChoiceBubble) return parent;
    throw new Error("Invalid MessagesIntervention parent");
  }

  findChainableRemoteId() {
    return this.lastBubble.findRemoteId();
  }

  // Ahead

  private aheadId: string | null = null;

  set ahead(
    ahead:
      | MessagesIntervention
      | ChoicesIntervention
      | ActionsIntervention
      | null
  ) {
    if (ahead === null) {
      this.aheadId = null;
    } else if (ahead instanceof ActionsIntervention) {
      ahead.bubbles.forEach((bubble) => this.appendBubble(bubble));
      this.ahead = ahead.ahead;
    } else {
      this.aheadId = ahead.id;
      ahead.parent = this;
    }
  }

  get ahead() {
    if (this.aheadId === null) return null;
    const ahead = this.dialog.find(this.aheadId);
    if (ahead instanceof ChoicesIntervention) return ahead;
    else if (ahead instanceof MessagesIntervention) return ahead;
    throw new Error("Invalid ActionsIntervention ahead");
  }

  // Bubbles

  private bubblesIds: Array<string> = [];

  set bubbles(bubbles: NonEmptyArray<ActionBubble>) {
    this.bubblesIds = bubbles.map((b) => b.id);
  }

  get bubbles() {
    const bubbles = this.bubblesIds.map((id) => {
      const bubble = this.dialog.find(id);
      if (bubble instanceof ActionBubble) return bubble;
      throw new Error("Invalid MessageBubble bubbles type");
    });
    if (bubbles.length === 0) throw new Error("Empty bubbles");
    return bubbles as NonEmptyArray<ActionBubble>;
  }

  get lastBubble() {
    const lastBubbleId = this.bubblesIds[this.bubblesIds.length - 1];
    if (lastBubbleId === undefined)
      throw new Error("No bubbles in MessageIntervention");
    const bubble = this.dialog.find(lastBubbleId);
    if (bubble instanceof ActionBubble) return bubble;
    throw new Error("Invalid last Bubble in MessageIntervention");
  }

  appendBubble(bubble: ActionBubble) {
    this.bubblesIds.push(bubble.id);
    bubble.intervention = this;
  }

  injectBubbleAt(bubble: ActionBubble, index: number) {
    this.bubblesIds.splice(index, 0, bubble.id);
    bubble.intervention = this;
  }

  removeBubble(bubble: ActionBubble) {
    this.bubblesIds = without(this.bubblesIds, bubble.id);
    if (this.bubblesIds.length === 0) this.destroy();
  }

  // Weight

  getWeight(tracker: number = 0) {
    let weight = tracker;
    weight += this.bubbles[0].getWeight(tracker);
    return weight;
  }

  // Registration

  isRegistrable(): boolean {
    if (!this.parentId) return false;
    return true;
  }

  isUnregistrable(): boolean {
    if (this.parentId !== null) return false;
    return true;
  }

  destroy() {
    this.parent.ahead = this.ahead;
    this.parentId = null;
    this.unregister();
  }

  // Export to draft

  toDraftSteps(): Array<DraftBot.Step> {
    return this.bubbles[0].toDraftSteps();
  }

  // Export to bundle

  toBundleSteps(locale: Locale): Array<BotPlayerTypes.Step> {
    return this.bubbles[0].toBundleSteps(locale);
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    const me = new ActionsIntervention(dialog);

    // Bubbles
    const bubble = ActionsIntervention.createBubblefromSteps(steps, dialog);
    me.appendBubble(bubble);
    bubble.register();

    let children = steps[0].children || [];
    while (true) {
      try {
        const bubble = ActionsIntervention.createBubblefromSteps(
          children,
          dialog
        );
        me.appendBubble(bubble);
        bubble.register();
        children = children[0].children || [];
      } catch (err) {
        break;
      }
    }

    // Ahead

    if (children.length === 0) {
      me.ahead = null;
    } else {
      const firstChildren = children[0];
      if (firstChildren.data.kind === "CH") {
        const intervention = ChoicesIntervention.fromSteps(children, dialog);
        me.ahead = intervention;
        intervention.register();
      } else if (firstChildren.data.kind === "BI") {
        const intervention = MessagesIntervention.fromSteps(children, dialog);
        me.ahead = intervention;
        intervention.register();
      } else {
        throw new BotStructureError(
          "Last message of a chain of message can only have CH steps or AA step as children",
          { step: children }
        );
      }
    }

    return me;
  }

  static createBubblefromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    if (steps.length !== 1) {
      throw new BotStructureError(
        "Action cannot be created from an array of containing other than 1 step",
        { steps }
      );
    }

    const step = steps[0];

    if (step.data.kind !== "AA") {
      throw new BotStructureError(
        "Cannot create action bubble from a non-AA step",
        { steps }
      );
    }

    const action = step.data.action;
    if (action === "URL") {
      return UrlBubble.fromSteps(steps, dialog);
    } else if (action === "EMA") {
      return EmailBubble.fromSteps(steps, dialog);
    } else if (action === "CAL") {
      return PhoneBubble.fromSteps(steps, dialog);
    } else if (action === "GTO") {
      return GotoBubble.fromSteps(steps, dialog);
    } else if (action === "I18N") {
      return TranslateBubble.fromSteps(steps, dialog);
    } else if (action === "STOP") {
      return StopBubble.fromSteps(steps, dialog);
    } else {
      toBeChecked("Not implemented");
    }
  }

  countTranslations(locale: Locale, emptyOnes: boolean) {
    let nb = sum(
      this.bubbles.map((b) => b.countTranslations(locale, emptyOnes))
    );
    if (this.ahead) nb += this.ahead.countTranslations(locale, emptyOnes);
    return nb;
  }

  getErrors() {
    const errors: Array<BotError> = [];
    this.bubbles.forEach((b) => errors.push(...b.getErrors()));
    if (!this.ahead && !this.lastBubble.isEnding()) {
      errors.push(new DeadPath(this.lastBubble));
    }
    if (this.ahead) errors.push(...this.ahead.getErrors());
    return errors;
  }

  getTargetableItems() {
    const output: Array<TargetableItem> = [];
    this.bubbles.forEach((b) => output.push(...b.getTargetableItems()));
    if (this.ahead) output.push(...this.ahead.getTargetableItems());
    return output;
  }
}
