/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useEffect } from "react";
import getErrorMessage from "src/utilities/getErrorMessage";
import Spacer from "./Spacer";
import Typo from "./Typo";

type ErrorZoneProps = { error: any };

export default function ErrorZone(props: ErrorZoneProps) {
  const containerCss = css({
    background: rgba("darkred", 0.1),
    border: `1px solid darkred`,
    padding: 20,
    margin: 20,
    color: "darkred",
  });

  useEffect(() => {
    console.log(props.error);
  }, []);

  return (
    <div css={containerCss}>
      <Typo center>{"ERREUR"}</Typo>
      <Spacer />
      <Typo center>{getErrorMessage(props.error)}</Typo>
    </div>
  );
}
