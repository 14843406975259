/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useCallback, useEffect } from "react";
import { useDrop } from "react-dnd";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import {
  InterventionViewProps,
  ItemsMetadataContext,
} from "src/components/ChatbotEditorStepper/config";
import {
  feedback,
  feedbackItem,
} from "src/components/ChatbotEditorStepper/feedbacks";
import Spacer from "src/components/Spacer";
import ActionsIntervention from "src/utilities/BotDialog/ActionsIntervention";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import GotoBubble from "src/utilities/BotDialog/GotoBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import PhoneBubble from "src/utilities/BotDialog/PhoneBubble";
import StopBubble from "src/utilities/BotDialog/StopBubble";
import I18nBubble from "src/utilities/BotDialog/TranslateBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import ChatbotLines from "src/utilities/ChatbotLines";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import Droper from "../../Nodes/Fragments/Droper";
import StepCreator from "../../Nodes/Fragments/StepCreator";

export default function ActionsInterventionView(props: InterventionViewProps) {
  const { ItemsComponent } = props;

  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { columnKey, intervention } = controller.useColumnSelector(
    (c, columnKey) => ({
      columnKey,
      intervention: c.findColumnData(columnKey) as ActionsIntervention,
      column: c.findColumn(columnKey),
      items: c.getColumnItems(columnKey),
      selectedItem: c.getSelectedItem(columnKey),
    })
  );

  const stepsWidth = 300;
  const linesWidth = ChatbotLines.useLinesWidth(intervention);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    width: stepsWidth,
    marginLeft: linesWidth,
    transition: "margin-left 200ms, padding-top 200ms",
  });

  controller.useColumnWidth(columnKey, stepsWidth + linesWidth);
  controller.useColumnHeaderHeight(columnKey, 10);

  useEffect(() => {
    controller.selectItem(intervention.lastBubble.id);
  }, []);

  const [{ dropVisible, dropOver }, dropRef] = useDrop(
    {
      accept: columnKey,
      collect: (monitor) => {
        return {
          dropVisible: monitor.canDrop(),
          dropOver: monitor.isOver(),
        };
      },
      drop: (drop: { bubbleId: string }) => {
        const bubble = controller.findItemData(drop.bubbleId) as MessageBubble;
        const exec = manager.moveMessageBubble(bubble, null);
        feedback(controller, exec);
        feedbackItem(itemsMetadata, exec, drop.bubbleId);
      },
    },
    []
  );

  const ending = intervention.lastBubble.isEnding();

  return (
    <div css={containerCss}>
      <Droper
        dropRef={dropRef}
        visible={dropVisible}
        over={dropOver}
        lineColor={Theme.colors.pink}
      />
      <Spacer size={10} />
      <ItemsComponent />
      <Spacer size={30} />
      {!ending ? <AppendMessageButton /> : null}
    </div>
  );
}

function AppendMessageButton() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();

  const itemsMetadata = ItemsMetadataContext.use();

  const { intervention } = controller.useColumnSelector((c, columnKey) => ({
    intervention: c.findColumnData(columnKey) as ActionsIntervention,
    distance: c.getColumnDistanceFromLastOne(columnKey),
  }));

  const [creatorVisible, setCreatorVisible] = useBooleanState(false);

  const onAddUrlAction = useCallback(() => {
    setCreatorVisible.toFalse();
    const execution = manager.appendActionBubble(
      intervention,
      new UrlBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      const id = intervention.lastBubble.id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [intervention]);

  const onAddEmailAction = useCallback(() => {
    setCreatorVisible.toFalse();
    const execution = manager.appendActionBubble(
      intervention,
      new EmailBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      const id = intervention.lastBubble.id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [intervention]);

  const onAddPhoneAction = useCallback(() => {
    setCreatorVisible.toFalse();
    const execution = manager.appendActionBubble(
      intervention,
      new PhoneBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      const id = intervention.lastBubble.id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [intervention]);

  const onAddGotoAction = useCallback(() => {
    setCreatorVisible.toFalse();
    const bubble = new GotoBubble(null);
    const bot = manager.bot.uuid;
    const entryPoint = manager.dialog.getEntryPoint().findRemoteId();
    bubble.setActionArgs(`${bot}#${entryPoint}`);
    const execution = manager.appendActionBubble(intervention, bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      const id = intervention.lastBubble.id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [intervention]);

  const onAddTranslateAction = useCallback(() => {
    setCreatorVisible.toFalse();
    const execution = manager.appendActionBubble(
      intervention,
      new I18nBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      const id = intervention.lastBubble.id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [intervention]);

  const onAddStopAction = useCallback(() => {
    setCreatorVisible.toFalse();
    const execution = manager.appendActionBubble(
      intervention,
      new StopBubble(null)
    );
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      const id = intervention.lastBubble.id;
      controller.selectItem(id);
      feedbackItem(itemsMetadata, execution, id);
    });
  }, [intervention]);

  return (
    <Fragment>
      <Buttons align="center">
        <Button2
          icon="open-in-new"
          label="Ajouter une action"
          onClick={setCreatorVisible.toTrue}
          layout="solid"
          color={Theme.colors.pink}
        />
      </Buttons>
      <Spacer scale={2} />
      <StepCreator
        visible={creatorVisible}
        onClose={setCreatorVisible.toFalse}
        onAddUrlAction={onAddUrlAction}
        onAddPhoneAction={onAddPhoneAction}
        onAddEmailAction={onAddEmailAction}
        onAddGotoAction={onAddGotoAction}
        onAddTranslateAction={onAddTranslateAction}
        onAddStopAction={onAddStopAction}
      />
    </Fragment>
  );
}
