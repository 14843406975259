/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { ReactNode } from "react";
import Content from "src/components/Content";
import Icon from "src/components/Icon";
import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import Spinner from "src/components/Loading";
import { Panel2 } from "src/components/Panel2";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";

type BotErrorViewProps = {
  type: string;
  icon: NoeseIcons;
  description: string;
  solutions: ReactNode;
  fixing?: boolean;
};

export default function BotErrorView(props: BotErrorViewProps) {
  const { type, icon, description, solutions, fixing } = props;
  const containerCss = css({
    width: 400,
  });
  return (
    <div css={containerCss}>
      <Panel2>
        <Content scale={2}>
          <Spacer scale={2} />
          <Typo name="body" color={rgba("black", 0.5)}>
            {fixing ? (
              <Spinner typo="body" scale={1.2} />
            ) : (
              <Icon name={icon} typo="body" scale={1.2} />
            )}{" "}
            {type}
          </Typo>
          <Spacer />
          <Typo name="body">{description}</Typo>
        </Content>
        <Spacer scale={1} />
        {solutions}
        <Spacer scale={2} />
      </Panel2>
    </div>
  );
}
