/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { nanoid } from "nanoid";
import { rgba } from "polished";
import { Fragment, PropsWithChildren, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import Clickable from "src/components/Clickable";
import Icon from "src/components/Icon";
import RootClickBoundary, {
  useRootClick,
} from "src/utilities/RootClickBoundary";

type ModalProps = PropsWithChildren<{
  visible: boolean;
  onClose: () => any;
}>;

export default function Modal(props: ModalProps) {
  const { visible, children, onClose } = props;

  const id = useMemo(() => nanoid(), []);

  useEffect(() => {
    const element = document.createElement("div");
    element.id = id;
    document.body.append(element);
    return () => {
      element.remove();
    };
  }, [id]);

  const containerCss = css({
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: `translate3d(-50%, -50%, 0px)`,
    opacity: visible ? 1 : 0,
    zIndex: 3000,
    pointerEvents: visible ? "auto" : "none",
  });

  const windowCss = css({
    width: 600,
    maxWidth: "90%",
    minHeight: 300,
    maxHeight: "60vh",
    backgroundColor: rgba("white", 0.5),
    backdropFilter: `blur(${visible ? "40px" : "0px"})`,
    boxShadow: `0px 0px 50px ${rgba("black", 0.5)} `,
    borderRadius: 4,
    border: `1px solid white`,
    overflow: "auto",
  });

  const closerCss = css({
    position: "absolute",
    opacity: 0.2,
    transition: "opacity 100ms",
    top: -50,
    right: -0,
    "&:hover": {
      opacity: 1,
    },
  });

  useRootClick(onClose, visible, []);

  const node = (
    <RootClickBoundary enabled={visible}>
      <div css={containerCss}>
        <div css={windowCss}>
          <Clickable containerCss={closerCss} onClick={onClose}>
            <Icon square name="cross" height={40} />
          </Clickable>
          {children}
        </div>
      </div>
    </RootClickBoundary>
  );

  const element = document.getElementById(id);
  if (!element) return <Fragment />;
  return ReactDOM.createPortal(node, element);
}
