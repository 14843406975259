/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag } from "react-dnd";
import Icon from "src/components/Icon";
import Spinner from "src/components/Loading";
import NodeFooter from "src/components/Nodes/NodeFooter";
import NodeHeader from "src/components/Nodes/NodeHeader";
import NodeInput from "src/components/Nodes/NodeInput";
import StepSuggestions from "src/components/Nodes/StepView/StepSuggestions";
import styles from "src/components/Nodes/styles";
import Revealable from "src/components/Revealable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import DelayedView from "src/utilities/DelayedView";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useHeight from "src/utilities/useHeight";
import useMemoState from "src/utilities/useMemoState";
import { useChatbotTreeStepperController } from "..";
import { ItemsMetadataContext } from "../config";
import { feedback, feedbackItem } from "../feedbacks";
import FromAboveLine from "../Lines/FromAboveLine";
import FromPreviousColumnToItemLine from "../Lines/FromPreviousColumnToItemLine";
import AllStepsView from "./AllStepsView";

export default function UrlBubbleView() {
  const { botApi } = Services.use();
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { itemId, bubbleId, bubble, interventionId, selected } =
    controller.useItemSelector(
      (c, itemId, interventionId) => ({
        itemId,
        bubbleId: itemId,
        interventionId,
        bubble: c.findItemData(itemId) as UrlBubble,
        selected: c.isItemSelected(itemId),
      }),
      []
    );

  const { locale } = itemsMetadata.use(itemId);

  const url = bubble.getLabel(locale);
  const [urlValue, setUrlValue] = useMemoState(() => url, [url]);

  const saveUrl = useCallback(
    (newUrl: string) => {
      if (url === newUrl) return;
      const execution = manager.updateLabelAndActionArgs(
        bubble,
        locale,
        newUrl
      );
      feedback(controller, execution);
      feedbackItem(itemsMetadata, execution, bubbleId);
    },
    [url, bubble, locale]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      const url = e.target.value;
      setUrlValue(url);
      saveUrl(url);
    },
    [saveUrl]
  );

  const containerCss = css({
    borderRadius: 10,
    background: Theme.colors.pink,
    color: Theme.colors.white,
  });

  const measuredRef = useRef<HTMLDivElement>(null);
  const bodyHeight = useHeight(measuredRef);
  const [footerHeight, setFooterHeight] = useState<number>(9);
  const height = bodyHeight + footerHeight + 10;
  controller.useItemHeight(bubbleId, height);

  const [, dragRef, dragPreview] = useDrag({
    type: interventionId,
    item: { bubbleId: bubbleId },
  });

  const previewCss = css({
    borderRadius: 10,
    overflow: "hidden",
    background: "white",
    color: "black",
    marginBottom: 4,
  });

  const imagePreviewCss = css({
    height: 120,
    borderRadius: 10,
    backgroundSize: "cover",
    backgroundPosition: "center",
  });

  const previewTexts = css({
    padding: 5,
  });

  const onDelete = useCallback(() => {
    const previous = bubble.previous;
    const next = bubble.next;
    const execution = manager.removeActionBubble(bubble);
    feedback(controller, execution);
    execution.events.on("commit-done", () => {
      if (next) controller.selectItem(next.id);
      else if (previous) controller.selectItem(previous.id);
    });
  }, []);

  const linkStatus = useMemo(() => {
    if (!url) return null;
    return botApi.checkLink(url);
  }, []);

  const linkStatusCss = css({
    paddingInline: 6,
    paddingBlock: 2,
    display: "flex",
  });

  const linkVerified: ReactNode = (
    <div css={linkStatusCss}>
      <Icon typo="body" name="cloud-check-variant" />
      <Spacer scale={0.5} />
      <Typo name="body">Lien vérifié</Typo>
    </div>
  );

  const linkChecking: ReactNode = (
    <div css={linkStatusCss}>
      <Spinner typo="body" />
      <Spacer scale={0.5} />
      <Typo name="body">Vérification en cours...</Typo>
    </div>
  );

  const linkUnknown: ReactNode = (
    <div css={linkStatusCss}>
      <Icon typo="body" name="attention-circle" />
      <Spacer scale={0.5} />
      <Typo name="body">Impossible de vérifier ce lien</Typo>
    </div>
  );

  return (
    <Fragment>
      {bubble.isFirst ? (
        <FromPreviousColumnToItemLine selected={true} virtual={false} />
      ) : (
        <FromAboveLine />
      )}
      <AllStepsView>
        <div css={containerCss} ref={dragPreview}>
          <div ref={measuredRef}>
            <NodeHeader
              icon="step-url"
              title="Ouverture d'une page Web"
              onDelete={onDelete}
            />
            <Spacer size={4} />
            <div css={styles.content}>
              <NodeInput
                value={urlValue}
                onChange={setUrlValue}
                onBlur={onBlur}
                noNewLines
              />
            </div>
            <Spacer size={4} />
          </div>
          <Revealable revealed={selected} onHeightStartChange={setFooterHeight}>
            {linkStatus ? (
              <DelayedView
                promise={linkStatus}
                renderPending={() => linkChecking}
                renderRejected={(err) => linkUnknown}
              >
                {(data) => {
                  if (typeof data.code === "number") {
                    if (data.code >= 200 && data.code < 300) {
                      return linkVerified;
                    } else {
                      return <Typo>Lien invalide</Typo>;
                    }
                  } else {
                    return linkUnknown;
                  }
                }}
              </DelayedView>
            ) : null}
            <StepSuggestions />
            <NodeFooter translationControl={true} />
          </Revealable>
        </div>
      </AllStepsView>
      {/* <Droper
      dropRef={dropRef}
      visible={dropVisible}
      over={dropOver}
      lineColor={Theme.colors.red}
      zoneGradient={Theme.gradients.bot}
    /> */}
      <Spacer size={10} />
    </Fragment>
  );
}
