import { Fragment } from "react";
import {
  TreeStructureControllerContext,
  TreeStructureFileContext,
} from "./TreeStructureController";

type TreeStructureFileProps = {
  fileKey: string;
};

export default function TreeStructureLine(props: TreeStructureFileProps) {
  const { fileKey } = props;

  const controller = TreeStructureControllerContext.use();

  return (
    <Fragment>
      <TreeStructureFileContext.Provider value={fileKey}>
        <controller.FileComponent />
      </TreeStructureFileContext.Provider>
    </Fragment>
  );
}
