/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useCallback } from "react";
import ChatbotSimulatorController from "src/components/ChatbotSimulator/ChatbotSimulatorController";
import Clickable from "src/components/Clickable";
import Flag from "src/components/Flag";
import Intersperse from "src/components/Intersperse";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import BotApiTypes from "src/utilities/BotApi/BotApiTypes";
import { Publication } from "./types";

type PublicationViewProps = {
  publication: Publication;
  color: string;
  onChat: (release: BotApiTypes.Release) => any;
};

export default function PublicationView(props: PublicationViewProps) {
  const { publication } = props;

  const containerCss = css({
    display: "flex",
  });
  const textsCss = css({
    flexGrow: 1,
  });

  const localesCss = css({
    display: "flex",
  });

  const onPlay = useCallback(async (release: BotApiTypes.Release) => {
    ChatbotSimulatorController.set({
      fetchBundleFn: async (locale: string) => {
        let localeRelease = publication.releases.find(
          (r) => r.language === locale
        );
        if (!localeRelease) localeRelease = release;
        const res = await fetch(localeRelease.file);
        const json = await res.json();
        return json;
      },
      startLocale: release.language,
    });
  }, []);

  return (
    <div css={containerCss}>
      <div css={textsCss}>
        <Typo color={props.color} name="subheading">
          {publication.name}
        </Typo>
        <Spacer scale={0.5} />
        <Typo>Créée le {dayjs(publication.created_at).format("LL")}</Typo>
      </div>
      <Spacer />
      <div css={localesCss}>
        <Intersperse between={() => <Spacer />}>
          {publication.releases.map((release) => (
            <Clickable key={release.id} onClick={() => onPlay(release)}>
              <Flag code={release.language} size={24} />
            </Clickable>
          ))}
        </Intersperse>
      </div>
    </div>
  );
}

/*
  const loadFromRaws: FetchBundleFn = useCallback(
    async (_preferedLocale: string) => {
      const preferedLocale = _preferedLocale as Locale;
      const availableLocales = manager.getAllLocales();
      const locale = availableLocales.includes(preferedLocale)
        ? preferedLocale
        : manager.bot.natural_locale;
      await manager.loadTranslations(locale);
      return manager.getBundle(locale);
    },
    []
  );

  const loadFromRelease: FetchBundleFn = useCallback(
    async (preferedLocale: string) => {
      throw new Error("Boum");
    },
    []
  );

*/
