/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback } from "react";
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import { ItemsMetadataContext } from "src/components/ChatbotEditorStepper/config";

import Clickable from "src/components/Clickable";
import Flag from "src/components/Flag";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale } from "src/utilities/Locales";
import stopPropagation from "src/utilities/stopPropagation";
import useBooleanState from "src/utilities/useBooleanState";

export default function LanguageSwitch() {
  const manager = BotManagerContext.use();
  const controller = useChatbotTreeStepperController();
  const itemsMetadata = ItemsMetadataContext.use();

  const { locales } = manager.useSelector(
    () => ({
      locales: [manager.bot.natural_locale, ...manager.bot.other_locales],
    }),
    []
  );

  const { itemKey } = controller.useItemSelector(
    (c, itemKey) => ({
      itemKey,
    }),
    []
  );

  const { locale } = itemsMetadata.use(itemKey);

  const [unfolded, setUnfolded] = useBooleanState(false);

  const onClickOnLanguage = useCallback(
    (l: Locale) => {
      if (!unfolded) {
        setUnfolded.toTrue();
      } else {
        itemsMetadata.update(itemKey, (m) => ({ ...m, locale: l }));
        controller.compute();
        setUnfolded.toFalse();
      }
    },
    [unfolded]
  );

  const margin = 10;
  const marginEnds = 4;
  const flagSize = 16;

  const sharedCss = css({
    transition: "margin-left 100ms",
    marginTop: marginEnds,
    marginBottom: marginEnds,
    borderRadius: 1000,
  });

  const currentCss = css(sharedCss, {
    marginRight: margin,
  });

  const selectableCss = css(sharedCss, {
    marginRight: margin,
    outline: `2px solid rgba(255,255,255, 0.2)`,
  });

  const hiddenCss = css(sharedCss, {});

  const containerCss = css({
    display: "flex",
    alignItems: "center",
    height: flagSize + marginEnds,
    paddingLeft: 4,
    paddingBottom: marginEnds,
  });

  return (
    <div css={containerCss} onClick={stopPropagation}>
      {locales.map((l) => {
        const css = unfolded
          ? selectableCss
          : l === locale
          ? currentCss
          : hiddenCss;
        const size = unfolded ? flagSize : l === locale ? flagSize : 0;
        return (
          <Clickable
            containerCss={css}
            onClick={() => onClickOnLanguage(l)}
            key={l}
          >
            <Flag code={l} size={size} />
          </Clickable>
        );
      })}
    </div>
  );
}
