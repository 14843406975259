import { Fragment, ReactNode } from "react";
import useDeferred from "src/utilities/Deferred";

export type DelayedViewProps<TValue> = {
  promise: Promise<TValue>;
  children: (value: TValue) => ReactNode;
  renderIdling?: () => ReactNode;
  renderPending: () => ReactNode;
  renderRejected: (error: any) => ReactNode;
};

export default function DelayedView<TValue>(props: DelayedViewProps<TValue>) {
  const { promise, children } = props;

  const deferred = useDeferred(promise, true);

  const renderIdling = props.renderIdling || (() => <Fragment />);
  const renderPending = props.renderPending;
  const renderRejected = props.renderRejected;

  if (deferred.state === "idling") {
    return <Fragment>{renderIdling()}</Fragment>;
  } else if (deferred.state === "pending") {
    return <Fragment>{renderPending()}</Fragment>;
  } else if (deferred.state === "resolved") {
    return <Fragment>{children(deferred.value)}</Fragment>;
  } else {
    return <Fragment>{renderRejected(deferred.error)}</Fragment>;
  }
}

export function useDelayedString<TValue>(
  promise: Promise<TValue>,
  config: {
    resolved: (v: TValue) => string;
    idling?: () => string;
    pending: () => string;
    rejected: (error: any) => string;
  }
) {
  const deferred = useDeferred(promise, true);

  if (deferred.state === "idling") {
    return config.idling ? config.idling() : "";
  } else if (deferred.state === "pending") {
    return config.pending();
  } else if (deferred.state === "resolved") {
    return config.resolved(deferred.value);
  } else {
    return config.rejected(deferred.error);
  }
}
