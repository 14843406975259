import { ReactNode } from "react";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import PhoneBubble from "src/utilities/BotDialog/PhoneBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import { BotManagerContext } from "src/utilities/BotManager";
import { localeToText } from "src/utilities/Locales";
import Navigation from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import BotErrorView from "./BotErrorView";
import { MissingTranslation } from "./types";

type MissingTranslationViewProps = {
  error: MissingTranslation;
};

export default function MissingTranslationView(
  props: MissingTranslationViewProps
) {
  const { error } = props;
  const manager = BotManagerContext.use();

  const target = error.target;

  const lang = localeToText(error.locale).toLocaleLowerCase();
  let message: string = `"${error.naturalLocaleText}" n'est pas traduit en ${lang}`;
  let options: ReactNode = (
    <Buttons>
      <Button2
        label="Corriger"
        to={Navigation.getPath("ChatbotEdition", {
          query: { step: target.id },
        })}
        color={Theme.colors.blue}
        layout="solid"
      />
    </Buttons>
  );
  if (target instanceof MessageBubble) {
    message = `Le message "${error.naturalLocaleText}" n'est pas traduit en ${lang}`;
  } else if (target instanceof ChoiceBubble) {
    message = `Le choix "${error.naturalLocaleText}" n'est pas traduit en ${lang}`;
  } else if (target instanceof UrlBubble) {
    message = `Le lien "${error.naturalLocaleText}" n'a pas d'équivalent en ${lang}`;
  } else if (target instanceof EmailBubble) {
    message = `L'adresse email "${error.naturalLocaleText}" n'a pas d'équivalent en ${lang}`;
  } else if (target instanceof PhoneBubble) {
    message = `Le numéro de téléphone "${error.naturalLocaleText}" n'a pas d'équivalent en ${lang}`;
  }

  return (
    <BotErrorView
      type="Traduction manquante"
      icon="translate"
      description={message}
      solutions={options}
    ></BotErrorView>
  );
}
