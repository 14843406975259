class MissingEnvironmentVariableException extends Error {
  constructor(name: string) {
    super(`Env. variable named ${name} is missing`);
  }
}

function findString(name: string) {
  const value = process.env[name];
  if (!value) throw new MissingEnvironmentVariableException(name);
  return value;
}

const Environment = {
  findString,
};

export default Environment;
