/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { difference } from "lodash";
import { rgba } from "polished";
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { border } from "src/components/ChatbotTranslatorStructure/utils";
import Clickable from "src/components/Clickable";
import Flag from "src/components/Flag";
import Spinner from "src/components/Loading";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { BotManagerContext } from "src/utilities/BotManager";
import DelayedView from "src/utilities/DelayedView";
import getErrorMessage from "src/utilities/getErrorMessage";
import { Locale, LOCALES, localeToText } from "src/utilities/Locales";
import Zones from "src/utilities/Navigation";
import Theme from "src/utilities/Theme";
import { TypoDescriptors } from "src/utilities/Typos";

export default function ChatbotTranslation() {
  const navigate = useNavigate();

  const manager = BotManagerContext.use();

  const { botUuid, otherLocales } = manager.useSelector(
    () => ({
      botUuid: manager.bot.uuid,
      otherLocales: manager.bot.other_locales,
    }),
    []
  );

  const isOnHome = Zones.useIsInRoute("ChatbotTranslation");

  useEffect(() => {
    if (isOnHome) {
      const firstOtherLocale = otherLocales[0];
      if (firstOtherLocale) {
        navigate(
          Zones.getPath("ChatbotTranslationLocale", {
            params: {
              chatbot: botUuid,
              locale: firstOtherLocale,
            },
          })
        );
      }
    }
  }, [isOnHome, botUuid, otherLocales.join("-")]);

  const containerCss = css({
    display: "grid",
    gridTemplateColumns: "300px 1fr",
    padding: 20,
    columnGap: 20,
  });

  return (
    <div css={containerCss}>
      <BotLocales />
      <div css={css({ flexGrow: 1 })}>
        <Outlet />
      </div>
    </div>
  );
}

function BotLocales() {
  const manager = BotManagerContext.use();
  const navigationLocale = Zones.useParam("locale", null);

  const containerCss = css({
    background: "white",
    alignSelf: "start",
    borderRadius: 6,
    border: border(),
  });

  const subhedingCss = css({
    padding: 10,
    borderBottom: border(),
    marginTop: 20,
  });

  const selectaableLocale = css({
    "&:hover": {
      backgroundColor: rgba(Theme.colors.teal, 0.2),
    },
  });

  const selectedLocale = css({
    backgroundColor: rgba(Theme.colors.teal, 0.2),
  });

  const { natural_locale, otherLocales } = manager.useSelector(() => {
    return {
      natural_locale: manager.bot.natural_locale,
      otherLocales: manager.bot.other_locales,
    };
  }, []);

  const onAddLocale = useCallback((l: Locale) => {
    manager.addLocale(l);
  }, []);

  const availableLocales = useMemo(() => {
    return difference(LOCALES, [
      natural_locale,
      ...otherLocales,
    ]) as Array<Locale>;
  }, [natural_locale, otherLocales.join("-")]);

  return (
    <div css={containerCss}>
      <div css={subhedingCss}>
        <Typo name="body">Langue originale</Typo>
      </div>
      <BotLocale locale={natural_locale}>
        <LocaleFullfillment locale={natural_locale} />
      </BotLocale>
      {otherLocales.length > 0 ? (
        <Fragment>
          <div css={subhedingCss}>
            <Typo name="body">Autres langues du bot</Typo>
          </div>
          {otherLocales.map((l) => (
            <Clickable
              key={l}
              containerCss={
                l === navigationLocale ? selectedLocale : selectaableLocale
              }
              to={Zones.getPath("ChatbotTranslationLocale", {
                params: {
                  chatbot: manager.bot.uuid,
                  locale: l,
                },
              })}
            >
              <BotLocale key={l} locale={l}>
                <LocaleFullfillment locale={l} />
              </BotLocale>
            </Clickable>
          ))}
        </Fragment>
      ) : null}
      {availableLocales.length > 0 ? (
        <Fragment>
          <div css={subhedingCss}>
            <Typo name="body">Ajouter d'autres langues</Typo>
          </div>
          {availableLocales.map((l) => (
            <BotLocale key={l} locale={l}>
              <Button
                label="Ajouter"
                color={"white"}
                textColor={Theme.colors.teal}
                onClick={() => onAddLocale(l)}
              />
            </BotLocale>
          ))}
        </Fragment>
      ) : null}
    </div>
  );
}

type BotLocaleProps = PropsWithChildren<{
  locale: Locale;
}>;

function BotLocale(props: BotLocaleProps) {
  const { locale } = props;

  const containerCss = css({
    display: "flex",
    padding: 10,
    alignItems: "center",
  });

  return (
    <div css={containerCss}>
      <Flag code={locale} size={TypoDescriptors.minor.height + 6} />
      <Spacer size={10} />
      <Typo name="bold">{localeToText(locale)}</Typo>
      <Spacer grow />
      {props.children}
    </div>
  );
}

type LocaleFullfillmentProps = {
  locale: Locale;
};

function LocaleFullfillment(props: LocaleFullfillmentProps) {
  const { locale } = props;
  const manager = BotManagerContext.use();

  const loading = useMemo(() => manager.loadTranslations(locale), [locale]);

  const fullfillement = manager.useSelector(() => {
    const all = manager.dialog.countTranslations(locale, true);
    const filled = manager.dialog.countTranslations(locale, false);
    return Math.round((filled / all) * 100) + " %";
  }, []);

  return (
    <DelayedView
      promise={loading}
      renderIdling={() => <Fragment />}
      renderPending={() => <Spinner typo="minor" />}
      renderRejected={(err) => <Typo>{getErrorMessage(err)}</Typo>}
    >
      {() => <Typo name="bold">{fullfillement}</Typo>}
    </DelayedView>
  );
}
