import { get } from "lodash";
import { DependencyList, SyntheticEvent, useCallback } from "react";
import getErrorMessage from "./getErrorMessage";
import useBooleanState from "./useBooleanState";

export default function useSubmitCallback<P extends Array<any>>(
  fn: (...args: P) => any,
  deps: DependencyList
) {
  return useAsyncCallback(
    async (...args: P) => {
      const event = args[0];
      if (isSyntheticEvent(event) && event.nativeEvent instanceof SubmitEvent) {
        event.preventDefault();
      }
      await fn(...args);
    },
    () => {},
    (err) => alert(getErrorMessage(err)),
    deps
  );
}

function useAsyncCallback<P extends Array<any>, V>(
  fn: (...args: P) => V | Promise<V>,
  onSuccess: (value: V) => any,
  onError: (err: any) => any,
  deps: DependencyList
) {
  const [running, setRunning] = useBooleanState(false);
  const outputFn = useCallback(async (...args: P) => {
    try {
      setRunning.toTrue();
      const res = await fn(...args);
      onSuccess(res);
    } catch (err) {
      onError(err);
    } finally {
      setRunning.toFalse();
    }
  }, deps);

  return [outputFn, running] as const;
}

function isSyntheticEvent(e: any): e is SyntheticEvent {
  return (
    get(e, "nativeEvent") instanceof Event &&
    typeof get(e, "_reactName") === "string"
  );
}
