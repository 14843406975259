import createRequiredContext from "./createRequiredContext";
import Updater from "./Updater";

export default class SelectedElementManager {
  readonly updater = new Updater();
  readonly context = createRequiredContext<SelectedElementManager>();
  private selection: string | null = null;
  readonly Provider = this.context.Provider;

  select(id: string | null) {
    this.selection = id;
    this.updater.update();
  }

  unselect(id: string) {
    if (this.selection === id) {
      this.selection = null;
      this.updater.update();
    }
  }

  use(id: string) {
    return this.updater.useValue(() => {
      return this.selection === id;
    }, [id]);
  }
}
