/** @jsxImportSource @emotion/react */

import { Fragment, useCallback, useState } from "react";
import Button2 from "src/components/Button2";
import Buttons from "src/components/Buttons";
import Content from "src/components/Content";
import TextField from "src/components/Fields/TextField";
import Intersperse from "src/components/Intersperse";
import Modal from "src/components/Modal";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import BotApiTypes from "src/utilities/BotApi/BotApiTypes";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { Panel } from "../ChatbotSettings";
import PublicationView from "./PublicationView";
import { Publication } from "./types";

type OnlineReleaseProps = {
  isFirst: boolean;
  publication: Publication;
  onArchive: (release: Publication) => any;
  onRename: (release: Publication, newName: string) => any;
  onChat: (release: BotApiTypes.Release) => any;
};

export default function OnlineRelease(props: OnlineReleaseProps) {
  const { publication, isFirst, onArchive, onRename, onChat } = props;

  const [label, setLabel] = useState(publication.name);

  const [renameModal, setRenameModal] = useBooleanState(false);

  const [onRenameSubmit, renaming] = useSubmitCallback(async () => {
    await onRename(publication, label);
    setRenameModal.toFalse();
  }, [onRename, publication, label]);

  const onPressArchive = useCallback(() => {
    onArchive(publication);
  }, [onArchive, publication]);

  return (
    <Fragment>
      <Panel heading="Version en ligne" icon="cloud-check-variant">
        <PublicationView
          publication={publication}
          onChat={onChat}
          color={Theme.colors.blue}
        />
        <Spacer scale={2} />
        <Buttons align="right" row>
          <Intersperse between={() => <Spacer />}>
            {!isFirst ? (
              <Button2
                onClick={setRenameModal.toTrue}
                label="Modifier"
                color={Theme.colors.blue}
                layout="outline"
              />
            ) : null}
            <Button2
              onClick={onPressArchive}
              label="Archiver"
              color={Theme.colors.blue}
              layout="outline"
            />
          </Intersperse>
        </Buttons>
      </Panel>
      <Modal visible={renameModal} onClose={setRenameModal.toFalse}>
        <form onSubmit={onRenameSubmit}>
          <Content scale={2}>
            <Spacer scale={2} />
            <Typo name="heading">Notes de version</Typo>
            <Spacer />
            <Typo>
              Décrivez succintement les apports majeurs de cette version et/ou
              les changements apportées depuis la dernière mise en ligne
            </Typo>
            <Spacer />
            <TextField
              value={label}
              onChange={setLabel}
              label="Notes de version"
            />
            <Spacer />
            <Buttons>
              <Button2
                label="Valider"
                submit
                color={Theme.colors.blue}
                layout="solid"
                loading={renaming}
              />
            </Buttons>
          </Content>
        </form>
      </Modal>
    </Fragment>
  );
}
