/** @jsxImportSource @emotion/react */
import { Fragment } from "react";

export default function StepSuggestions() {
  return <Fragment />;
  // const controller = useChatbotTreeStepperController();
  // const itemKey = TreeStepperItemContext.use();
  // const suggestions = controller.useItemSuggestions(itemKey);

  // let contentCss = css({
  //   paddingInline: 6,
  //   paddingBlock: 3,
  //   border: `1px solid ${rgba("white", 0.5)}`,
  //   borderRadius: 6,
  // });

  // return (
  //   <div css={styles.content}>
  //     {suggestions.map((suggestion) => {
  //       return (
  //         <Fragment key={suggestion.key}>
  //           <div css={contentCss}>
  //             <Icon name="bulb-on" typo="minor" />
  //             <Spacer inline size={4} />
  //             <Typo inline name="minor">
  //               {suggestion.message}
  //             </Typo>
  //           </div>
  //           <Spacer size={4} />
  //         </Fragment>
  //       );
  //     })}
  //   </div>
  // );
}
