import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import { BotError } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import DataActionBubble from "./DataActionBubble";

export default class PhoneBubble extends DataActionBubble {
  getAction() {
    return "CAL" as const;
  }

  getErrors() {
    const errors: Array<BotError> = [...this.getMissingTranslationErrors()];
    const { natural_locale, other_locales } = this.dialog.manager.bot;
    const locales = [natural_locale, ...other_locales];
    locales.forEach((l) => {
      const phone = this.getLabel(l);
      // if (!email) return;
      // const input = document.createElement("input");
      // input.type = "email";
      // input.required = true;
      // input.value = email;
      // if (!input.checkValidity()) {
      //   errors.push(new InvalidFormat(this, email, l));
      // }
    });
    return errors;
  }

  getIcon(): NoeseIcons {
    return "step-phone";
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Self
    if (steps.length !== 1) {
      throw new BotStructureError(
        "Creating CAL bubble from children that doesn't have 1 (and only 1) item",
        { steps }
      );
    }

    const step = steps[0];
    if (step.data.kind !== "AA") {
      throw new BotStructureError("Creating CAL bubble from a non-AA step", {
        step,
      });
    }

    if (step.data.action !== "CAL") {
      throw new BotStructureError(
        "Creating CAL bubble from a non-AA CAL step",
        { step }
      );
    }

    const me = new PhoneBubble(step.id);
    me.dialog = dialog;
    me.setName(step.data.name);
    me.setTargetable(step.data.targetable);

    return me;
  }
}
