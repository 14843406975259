import { createContext, PropsWithChildren, useContext } from "react";

export default function createRequiredContext<TValue>() {
  const context = createContext<TValue | null>(null);

  const Provider = (props: PropsWithChildren<{ value: TValue }>) => {
    return <context.Provider {...props} />;
  };

  const use = () => {
    const value = useContext(context);
    if (value === null) throw new Error("No context");
    return value;
  };

  return { Provider, use };
}
