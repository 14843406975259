/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import Typos, { TypoName } from "src/utilities/Typos";

type TypoProps = {
  name?: TypoName;
  children: ReactNode;
  center?: boolean;
  oneLine?: boolean;
  color?: string;
  inline?: boolean;
  opacity?: number;
};

export default function Typo(props: TypoProps) {
  const { name = "body", oneLine, color, inline, opacity } = props;
  let containerCss = css(Typos[name], {
    textAlign: props.center ? "center" : "justify",
    color,
    display: inline ? "inline" : "block",
    opacity,
  });

  if (oneLine) {
    containerCss = css`
      ${containerCss}
      white-space : nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }

  const children = oneLine ? props.children || "\u00A0" : props.children;

  return <div css={containerCss}>{children}</div>;
}
