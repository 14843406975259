import { Fragment } from "react";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import DataActionBubble from "src/utilities/BotDialog/DataActionBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import ChoiceLine from "./Lines/ChoiceLine";
import DataActionLine from "./Lines/DataActionLine";
import MessageLine from "./Lines/MessageLine";
import { useTranslationController } from "./utils";

export default function TranslationSequence() {
  const controller = useTranslationController();

  const { data } = controller.useFileSelector(
    (c, file) => ({
      data: c.findFileData(file),
    }),
    []
  );

  if (data instanceof MessageBubble) {
    return <MessageLine />;
  } else if (data instanceof ChoiceBubble) {
    return <ChoiceLine />;
  } else if (data instanceof DataActionBubble) {
    return <DataActionLine />;
  }

  return <Fragment />;
}
