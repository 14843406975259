/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export type StrokeProps = {
  path: string | null;
  color?: string;
  strength?: number;
  dash?: string;
  transition?: string;
};

export default function Stroke(props: StrokeProps) {
  const { color, strength, dash, path, transition } = props;

  const containerCss = css({
    width: 0,
    height: 0,
    overflow: "visible",
    position: "relative",
  });

  const svgCss = css`
    overflow: visible;
    display: block;
    position: absolute;
  `;

  let pathCss = css`
    d: path("${path}");
  `;

  if (transition) {
    pathCss = css(path, { transition });
  }

  return (
    <div css={containerCss}>
      <svg
        width={1}
        height={1}
        viewBox="0 0 1 1"
        css={svgCss}
        overflow="visible"
      >
        <path
          fill="transparent"
          color={color}
          stroke={color}
          strokeWidth={strength}
          strokeDasharray={dash}
          css={pathCss}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}
