import { ItemProps } from ".";
import createRequiredContext from "../createRequiredContext";
import { ItemWrapper } from "./TreeStepperController";

type TreeStepperItemProps<TItem> = {
  itemWrapper: ItemWrapper<TItem>;
  ItemComponent: React.ComponentType<ItemProps>;
};

export default function TreeStepperItem<TItem>(
  props: TreeStepperItemProps<TItem>
) {
  const { itemWrapper, ItemComponent } = props;

  return (
    <div>
      <TreeStepperItemContext.Provider value={itemWrapper.key}>
        <ItemComponent />
      </TreeStepperItemContext.Provider>
    </div>
  );
}

export const TreeStepperItemContext = createRequiredContext<string>();
