import { nanoid } from "nanoid";
import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import {
  BotError,
  EmptyDelivery,
  MissingTranslation,
} from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import BotApiTypes from "../BotApi/BotApiTypes";
import { Locale } from "../Locales";
import { TargetableItem } from "./types";

export default abstract class Bubble {
  constructor(remoteId: number | null) {
    this.remoteId = remoteId;
    this.localId = remoteId === null ? nanoid() : remoteId.toString();
  }

  // Dialog

  private dialogInstance: BotDialog | null = null;

  get dialog() {
    if (!this.dialogInstance) throw new Error("No dialog set");
    return this.dialogInstance;
  }

  set dialog(dialog: BotDialog) {
    if (this.dialogInstance) throw new Error("BotDialog is already set");
    this.dialogInstance = dialog;
  }

  // Id

  readonly localId: string;
  remoteId: number | null;

  get id() {
    return this.localId;
  }

  findRemoteId() {
    if (this.remoteId === null) throw new Error("No remote id");
    return this.remoteId;
  }

  findChainableRemoteId() {
    return this.findRemoteId();
  }

  // Kind

  abstract getKind(): BotApiTypes.StepKind;

  // Icon
  abstract getIcon(): NoeseIcons;

  // Name

  private name: string | null = null;

  setName(name: string) {
    this.name = name;
  }

  findName() {
    if (this.name === null) {
      throw new Error("Bubble has no name");
    }
    return this.name;
  }

  // Targetable

  private targetable: boolean = false;

  setTargetable(targetable: boolean) {
    this.targetable = targetable;
  }

  isTargetable() {
    return this.targetable;
  }

  // Label

  protected label: Partial<{ [locale in Locale]: number | string }> = {};

  setLabelId(locale: Locale, labelId: number) {
    const currentLabelId = this.label[locale];
    if (currentLabelId !== undefined)
      this.dialog.moveText(locale, currentLabelId, labelId);
    this.label[locale] = labelId;
  }

  findLabelId(locale: Locale) {
    let labelId = this.label[locale];
    if (!labelId) {
      labelId = nanoid();
      this.label[locale] = labelId;
    }
    return labelId;
  }

  setLabel(locale: Locale, text: string) {
    const labelId = this.findLabelId(locale);
    this.dialog.setText(locale, labelId, text);
    return labelId;
  }

  getLabel(locale: Locale) {
    const labelId = this.label[locale];
    if (labelId === undefined) return "";
    return this.dialog.getText(locale, labelId);
  }

  countTranslations(locale: Locale, emptyOnes: boolean): number {
    return emptyOnes ? 1 : this.getLabel(locale) === "" ? 0 : 1;
  }

  // Weight
  abstract getWeight(): number;

  // Errors

  abstract getErrors(): Array<BotError>;

  getMissingTranslationErrors() {
    const errors: Array<EmptyDelivery | MissingTranslation> = [];
    const { natural_locale, other_locales } = this.dialog.manager.bot;
    const inNaturalLocal = this.getLabel(natural_locale);
    if (inNaturalLocal === "") {
      errors.push(new EmptyDelivery(this, natural_locale));
    } else {
      other_locales.forEach((l) => {
        const inLocale = this.getLabel(l);
        if (inLocale === "")
          errors.push(new MissingTranslation(this, l, inNaturalLocal));
      });
    }
    return errors;
  }

  // Registration

  abstract isRegistrable(): boolean;

  register() {
    if (!this.isRegistrable()) throw new Error("Item is not registrable");
    this.dialog.register(this);
  }

  abstract isUnregistrable(): boolean;

  unregister() {
    if (!this.isUnregistrable()) throw new Error("Item is not unregistrable");
    this.dialog.unregister(this);
  }

  // TargetableItems

  abstract getTargetableItems(): Array<TargetableItem>;

  // Release

  getReleaseLabel() {
    const locales = Object.keys(this.label) as Array<Locale>;
    return Object.fromEntries(locales.map((l) => [l, this.getLabel(l)]));
  }

  // Restore mecanism

  protected restorer: (() => any) | null = null;

  restore() {
    if (!this.restorer) throw new Error("No restore fn");
    this.restore();
  }
}
