import { useCallback } from "react";
import { BotManagerContext } from "src/utilities/BotManager";
import Navigation from "src/utilities/Navigation";
import Toasts from "src/utilities/Toasts";
import useBooleanState from "src/utilities/useBooleanState";
import { Solution, Solutions } from "../Solutions";
import BotErrorView from "./BotErrorView";
import { InvalidRedirection } from "./types";

type InvalidRedirectionViewProps = {
  error: InvalidRedirection;
  onFixed: () => any;
};

export default function InvalidRedirectionView(
  props: InvalidRedirectionViewProps
) {
  const { error, onFixed } = props;
  const manager = BotManagerContext.use();
  const [resolving, setResolving] = useBooleanState(false);

  const target = error.target;

  let message: string = `Le bot fait une redirection, mais la cible n'a pas été définie.`;

  const goToRoot = useCallback(() => {
    const botUuid = manager.bot.uuid;
    const rootNodeid = manager.dialog.getEntryPoint().findRemoteId();
    const actionArgs = `${botUuid}#${rootNodeid}`;
    const exec = manager.updateActionArgs(target, actionArgs);
    exec.events.on("started", setResolving.toTrue);
    exec.events.on("failed", (err) => Toasts.fromError(err));
    exec.events.on("done", onFixed);
    exec.events.on("ended", setResolving.toFalse);
  }, [target, onFixed]);

  let solutions = (
    <Solutions>
      <Solution
        icon="subdirectory-arrow-left"
        text="Corriger : Reprendre au début du script"
        onClick={goToRoot}
      />
      <Solution
        icon="eye"
        text="Voir le problème"
        to={Navigation.getPath("ChatbotEdition", {
          query: { step: target.id },
        })}
      />
    </Solutions>
  );

  return (
    <BotErrorView
      type="Redirection invalide"
      icon="sign-direction-remove"
      description={message}
      solutions={solutions}
    ></BotErrorView>
  );
}
