import Updater from "./Updater";

export default class ModelMetadataManager<TMetadata> {
  readonly updater = new Updater();
  readonly index: { [id: string]: TMetadata } = {};
  readonly defaultFn: () => TMetadata;

  constructor(defaultFn: () => TMetadata) {
    this.defaultFn = defaultFn;
  }

  set(id: string, metadata: TMetadata) {
    this.index[id] = metadata;
    this.updater.update();
  }

  update(id: string, updateFn: (current: TMetadata) => TMetadata) {
    const current = this.index[id] || this.defaultFn();
    this.index[id] = updateFn(current);
    this.updater.update();
  }

  use(id: string) {
    return this.updater.useValue(() => {
      if (!this.index[id]) this.index[id] = this.defaultFn();
      return this.index[id];
    }, [id]);
  }
}
