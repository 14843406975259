/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { sum } from "lodash";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import TreeStepperColumn from "./TreeStepperColumn";
import {
  TreeStepperContext,
  TreeStepperController,
  TreeStepperControllerConfig,
} from "./TreeStepperController";

export type ColumnProps = {
  ItemsComponent: React.ComponentType;
};

export type ItemProps = {};

type TreeStepperProps<TColumn, TItem> = {
  controllerRef: MutableRefObject<
    TreeStepperController<TColumn, TItem> | undefined
  >;
  ItemComponent: React.ComponentType<ItemProps>;
  ColumnComponent: React.ComponentType<ColumnProps>;
  containerCss?: SerializedStyles;
} & TreeStepperControllerConfig<TColumn, TItem>;

export default function TreeStepper<TColumn, TItem>(
  props: TreeStepperProps<TColumn, TItem>
) {
  const {
    ColumnComponent,
    ItemComponent,
    controllerRef,
    containerCss,
    ...config
  } = props;

  const controller = useMemo(() => {
    return new TreeStepperController(config);
  }, []);

  controllerRef.current = controller;

  controller.updater.useValue(() => new Date());

  const columns = controller.updater.useValue(() => {
    return controller.getColumnsWrappers();
  }, []);

  const width = sum(columns.map((c) => c.width || 0));

  const outerCss = css(containerCss, {
    overflow: "auto",
  });

  const columnsCss = css({
    display: "flex",
    width: width,
    transition: "width 400ms",
  });

  const outerRef = useRef<HTMLDivElement>(null);
  const previousWidth = useRef<number>(0);
  useEffect(() => {
    if (!outerRef.current) return;
    if (width > previousWidth.current)
      outerRef.current.scrollTo({ left: 100000, behavior: "smooth" });
    previousWidth.current = width;
  }, [width]);

  return (
    <TreeStepperContext.Provider value={controller}>
      <div css={outerCss} ref={outerRef}>
        <div css={columnsCss}>
          {columns.map((column) => (
            <TreeStepperColumn
              key={column.key}
              columnWrapper={column}
              ColumnComponent={ColumnComponent}
              ItemComponent={ItemComponent}
            />
          ))}
        </div>
      </div>
    </TreeStepperContext.Provider>
  );
}
