import EventEmitter from "events";
import {
  createContext,
  DependencyList,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

class RootClickBoundaryController {
  readonly events = new EventEmitter();
  listen(fn: () => any) {
    this.events.on("click", fn);
    return () => this.events.off("click", fn);
  }
  emit() {
    this.events.emit("click");
  }
}

type RootClickBoundaryProps = PropsWithChildren<{
  enabled?: boolean;
}>;

export default function RootClickBoundary(props: RootClickBoundaryProps) {
  const { children, enabled = true } = props;
  const controller = useMemo(() => new RootClickBoundaryController(), []);
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (!enabled) return;
      e.stopPropagation();
      controller.emit();
    },
    [enabled]
  );
  return (
    <RootClickBoundaryContext.Provider value={controller}>
      <span onClick={onClick}>{children}</span>
    </RootClickBoundaryContext.Provider>
  );
}

export function useRootClick(
  fn: () => any,
  enabled: boolean,
  deps: DependencyList = []
) {
  const controller = useContext(RootClickBoundaryContext);
  return useEffect(() => {
    if (!enabled) return;
    const unsub = controller.listen(fn);
    return () => {
      unsub();
    };
  }, [enabled, ...deps]);
}

export const RootClickBoundaryContext =
  createContext<RootClickBoundaryController>(new RootClickBoundaryController());
