import { AxiosError, AxiosResponse } from "axios";
import { get } from "lodash";
import BotStructureError from "./BotStructureError";

export default function getErrorMessage(e: any): string {
  if (e instanceof AxiosError) {
    if (e.code === "ERR_NETWORK") {
      return "Vous semblez être déconnecté d'Internet. Vérifiez votre connexion et recommencez.";
    } else if (e.response) {
      const inAxiosResponse = searchInAxiosResponse(e.response);
      if (inAxiosResponse) return inAxiosResponse;
    }
    return e.message;
  }
  if (e instanceof FrontError) {
    return e.message;
  }
  if (e instanceof BotStructureError) {
    return e.toString();
  }
  return "Une erreur est survenue :/";
}

export class FrontError {
  constructor(readonly message: string) {}
}

function searchInAxiosResponse(response: AxiosResponse): string | null {
  const contentType = get(response, "headers.content-type");
  const data = response.data;
  if (contentType === "text/plain") return data;
  else return null;
}
