import { nanoid } from "nanoid";
import getErrorMessage from "./getErrorMessage";
import Updater from "./Updater";

export type Toast = {
  id: string;
  key: string;
  title: string;
  message: string;
  type: "info" | "error" | "success";
  dismiss: number | "manual" | "none";
};

type ToastConfig = Omit<Toast, "id" | "key">;

const updater = new Updater();

let store: Array<Toast> = [];

function build(id: string | null, config: ToastConfig): Toast {
  return {
    ...config,
    id: id || nanoid(),
    key: nanoid(),
  };
}

function show(input: ToastConfig) {
  const toast = build(null, input);
  store = [...store, toast];
  updater.update();
  return toast.id;
}

function hide(id: string) {
  store = store.filter((t) => t.id !== id);
  updater.update();
}

function update(id: string, updateFn: (t: Toast) => ToastConfig) {
  const index = store.findIndex((t) => t.id === id);
  if (index < 0) return;
  const toast = store[index];
  const config = updateFn(toast);
  const newToast = build(toast.id, config);
  store[index] = newToast;
  updater.update();
}

function useList() {
  return updater.useValue(() => [...store], []);
}

function fromError(err: any): ToastConfig {
  console.log(err);
  return {
    title: "Erreur",
    message: getErrorMessage(err),
    type: "error",
    dismiss: "manual",
  };
}

const Toasts = {
  show,
  hide,
  useList,
  fromError,
  update,
};

export default Toasts;
