import { set } from "lodash";
import { nanoid } from "nanoid";

export default class BotStructureError extends Error {
  static index: { [id: string]: BotStructureError } = {};
  private id = nanoid();
  readonly data: any;
  constructor(message: string, data: any = null) {
    super(message);
    this.data = data;
    BotStructureError.index[this.id] = this;
  }
  toString() {
    return `${this.message} - To see detail, run getBotStructureError("${this.id}") in console`;
  }
}

set(window, "getBotStructureError", (id: string) => {
  return {
    message: BotStructureError.index[id],
    data: BotStructureError.index[id].data,
  };
});
