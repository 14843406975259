/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BotManagerContext } from "src/utilities/BotManager";
import { Locale, localeToText } from "src/utilities/Locales";
import {
  default as Navigation,
  default as Zones,
} from "src/utilities/Navigation";
import Clickable from "../Clickable";
import Flag from "../Flag";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Typo from "../Typo";
import { border } from "./utils";

export default function Header() {
  const manager = BotManagerContext.use();
  const navigate = useNavigate();

  const { natural_locale } = manager.useSelector(
    () => ({
      botUuid: manager.bot.uuid,
      natural_locale: manager.bot.natural_locale,
    }),
    []
  );

  const otherLocale = Zones.useParam("locale") as Locale;

  const onRemove = useCallback(() => {
    navigate(Navigation.getPath("ChatbotTranslation"));
    manager.removeLocale(otherLocale);
  }, [otherLocale]);

  const { fullfillement } = manager.useSelector(() => {
    const attended = manager.dialog.countTranslations(natural_locale, true);
    const translated = manager.dialog.countTranslations(otherLocale, false);
    return {
      fullfillement: translated / attended,
    };
  }, [natural_locale, otherLocale]);

  const sourceCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#F0F0F0",
    borderTopLeftRadius: 6,
    borderTop: border(),
    borderLeft: border(),
    padding: 20,
  });

  const targetCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "white",
    borderTop: border(),
    borderLeft: border(),
    borderRight: border(),
    padding: 20,
    position: "relative",
  });

  const suggestionCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#F0F0F0",
    borderTop: border(),
    borderRight: border(),
    borderTopRightRadius: 6,
    padding: 20,
  });

  const trashCss = css({
    position: "absolute",
    top: 10,
    right: 10,
  });

  return (
    <Fragment>
      <div css={sourceCss}>
        <Flag code={natural_locale} size={30} />
        <Spacer size={4} />
        <Typo name="heading" center>
          {localeToText(natural_locale)}
        </Typo>
        <Typo name="minor" center>
          Texte original
        </Typo>
      </div>
      <div css={targetCss}>
        <Flag code={otherLocale} size={30} />
        <Spacer size={4} />
        <Typo name="heading">{localeToText(otherLocale)}</Typo>
        <Typo name="minor" center>
          Texte traduit à {Math.round(fullfillement * 100)}%
        </Typo>
        <Spacer />
        <Clickable css={trashCss} onClick={onRemove}>
          <Icon
            name="trash"
            typo="body"
            scale={1.5}
            color={rgba("black", 0.5)}
          />
        </Clickable>
      </div>
      <div css={suggestionCss}>
        <Flag code={otherLocale} size={30} />
        <Spacer size={4} />
        <Typo name="heading">Suggestions</Typo>
      </div>
    </Fragment>
  );
}
