import { sum, sumBy, without } from "lodash";
import { BotPlayerTypes } from "src/player/types";
import { BotError } from "src/screens/ChatbotHealth/errors/types";
import BotDialog from ".";
import { DraftBot } from "../BotAdapters";
import BotStructureError from "../BotStructureError";
import { Locale } from "../Locales";
import NonEmptyArray from "../NonEmptyArray";
import ActionsIntervention from "./ActionsIntervention";
import ChoiceBubble from "./ChoiceBubble";
import Intervention from "./Intervention";
import MessagesIntervention from "./MessagesIntervention";
import { TargetableItem } from "./types";

export default class ChoicesIntervention extends Intervention {
  readonly type = "ChoicesIntervention";

  // Parent
  private parentId: string | null = null;

  set parent(
    parent: MessagesIntervention | ChoiceBubble | ActionsIntervention
  ) {
    this.parentId = parent.id;
  }

  get parent() {
    const parentId = this.parentId;
    if (parentId === null) throw new Error("No parent intervention id");
    const parent = this.dialog.find(parentId);
    if (parent instanceof MessagesIntervention) return parent;
    if (parent instanceof ChoiceBubble) return parent;
    if (parent instanceof ActionsIntervention) return parent;
    throw new Error("Bad ChoiceIntervention parent");
  }

  // Bubbles

  private bubblesIds: Array<string> = [];

  set bubbles(bubbles: NonEmptyArray<ChoiceBubble>) {
    this.bubblesIds = bubbles.map((b) => b.id);
  }

  get bubbles() {
    const bubbles = this.bubblesIds.map((id) => {
      const bubble = this.dialog.find(id);
      if (bubble instanceof ChoiceBubble) return bubble;
      throw new Error("Invalid ChoicesIntervention bubbles type");
    });
    if (bubbles.length === 0)
      throw new Error("Empty bubbles in ChoicesIntervention");
    return bubbles as NonEmptyArray<ChoiceBubble>;
  }

  findBubble(id: string) {
    const bubble = this.bubbles.find((b) => b.id === id);
    if (!bubble) throw new Error(`Cannot find bubble ${id}`);
    return bubble;
  }

  appendBubble(bubble: ChoiceBubble) {
    this.bubblesIds.push(bubble.id);
    bubble.intervention = this;
  }

  injectBubbleAt(bubble: ChoiceBubble, index: number) {
    this.bubblesIds.splice(index, 0, bubble.id);
    bubble.intervention = this;
  }

  removeBubble(bubble: ChoiceBubble) {
    this.bubblesIds = without(this.bubblesIds, bubble.id);
    if (this.bubblesIds.length === 0) this.destroy();
  }

  getWeight(tracker: number = 0) {
    let weight = tracker;
    weight += sumBy(this.bubbles, (b) => b.getWeight(tracker));
    return weight;
  }

  countTranslations(locale: Locale, emptyOnes: boolean): number {
    return sum(
      this.bubbles.map(
        (b) =>
          b.countTranslations(locale, emptyOnes) +
          (b.ahead ? b.ahead.countTranslations(locale, emptyOnes) : 0)
      )
    );
  }

  getErrors() {
    const errors: Array<BotError> = [];
    this.bubbles.forEach((b) => {
      errors.push(...b.getErrors());
    });
    return errors;
  }

  getTargetableItems() {
    const items: Array<TargetableItem> = [];
    this.bubbles.forEach((b) => {
      items.push(...b.getTargetableItems());
    });
    return items;
  }

  // Registration

  isRegistrable(): boolean {
    if (!this.parentId) return false;
    return true;
  }

  isUnregistrable(): boolean {
    if (this.parentId !== null) return false;
    return true;
  }

  destroy() {
    this.parent.ahead = null;
    this.parentId = null;
    this.unregister();
  }

  // Export to draft

  toDraftSteps(): Array<DraftBot.Step> {
    return this.bubbles.map((b) => b.toDraftStep());
  }

  // Export to bundle

  toBundleSteps(locale: Locale): Array<BotPlayerTypes.Step> {
    return this.bubbles.map((b) => b.toBundleStep(locale));
  }

  static fromSteps(steps: Array<DraftBot.Step>, dialog: BotDialog) {
    // Checks
    if (steps.length === 0) {
      throw new BotStructureError(
        "Cannot create choices intervention with no choices"
      );
    }

    // Self
    const me = new ChoicesIntervention(dialog);

    // Bubbles
    steps.forEach((s) => {
      const bubble = ChoiceBubble.fromStep(s, dialog);
      me.appendBubble(bubble);
      bubble.register();
    });
    return me;
  }
}
