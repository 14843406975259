/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { nanoid } from "nanoid";
import { rgba } from "polished";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { useDelayedString } from "src/utilities/DelayedView";
import DelayedZone from "src/utilities/DelayedZone";
import getErrorMessage from "src/utilities/getErrorMessage";
import RootClickBoundary, {
  useRootClick,
} from "src/utilities/RootClickBoundary";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import Divider from "../Divider";
import Intersperse from "../Intersperse";
import Typo from "../Typo";
import { inputCss, labelCss } from "./styles";

type SelectFieldProps<O> = {
  label: string;
  value: string | null;
  onChange: (s: string | null) => any;
  options: Array<O> | Promise<Array<O>>;
  keyExtractor: (option: O) => string;
  labelExtractor: (option: O) => string;
  placeholder?: string;
};

export default function SelectField<O>(props: SelectFieldProps<O>) {
  const {
    label,
    value,
    onChange,
    options,
    keyExtractor,
    labelExtractor,
    placeholder,
  } = props;

  const id = useMemo(() => `field-${nanoid()}`, []);

  const optionsPromise = useMemo(async () => {
    if (options instanceof Promise) return options;
    else return Promise.resolve(options);
  }, [options]);

  const selectedOption = useMemo(async () => {
    if (value === null) return null;
    const options = await optionsPromise;
    const option = options.find((o) => keyExtractor(o) === value);
    return option;
  }, [optionsPromise, value]);

  useEffect(() => {
    selectedOption.then((s) => {
      if (s === undefined) {
        // Means value is not in the options, even after resolve
        onChange(null);
      }
    });
  }, [selectedOption]);

  const displayedText = useDelayedString(selectedOption, {
    resolved: (o) => (o ? labelExtractor(o) : ""),
    rejected: (err) => getErrorMessage(err),
    pending: () => "Chargement en cours...",
  });

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {},
    [onChange]
  );

  const [dropdown, setDropdown] = useBooleanState(false);

  const dropdownParentCss = css({ position: "relative" });
  const dropdownCss = css({
    position: "absolute",
    top: "calc(100%)",
    left: 24,
    right: 24,
    opacity: dropdown ? 1 : 0,
    background: "white",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderLeft: `1px solid ${rgba(Theme.colors.blue, 0.4)}`,
    borderRight: `1px solid ${rgba(Theme.colors.blue, 0.4)}`,
    borderBottom: `1px solid ${rgba(Theme.colors.blue, 0.4)}`,
    zIndex: 3000,
  });

  const itemCss = css({
    padding: 10,
  });

  useRootClick(setDropdown.toFalse, dropdown, []);

  return (
    <RootClickBoundary enabled={dropdown}>
      <label htmlFor={id} css={labelCss}>
        {label}
      </label>
      <div css={dropdownParentCss}>
        <input
          type="text"
          value={displayedText}
          onChange={onInputChange}
          css={inputCss}
          placeholder={placeholder}
          onFocus={setDropdown.toTrue}
          readOnly={true}
        />
        {dropdown ? (
          <div css={dropdownCss}>
            <DelayedZone promise={optionsPromise}>
              {(options) => (
                <Intersperse
                  between={() => (
                    <Fragment>
                      <Divider color={rgba("black", 0.1)} />
                    </Fragment>
                  )}
                >
                  {options.map((o) => (
                    <div
                      css={itemCss}
                      key={keyExtractor(o)}
                      onClick={() => {
                        onChange(keyExtractor(o));
                        setDropdown.toFalse();
                      }}
                    >
                      <Typo>{labelExtractor(o)}</Typo>
                    </div>
                  ))}
                </Intersperse>
              )}
            </DelayedZone>
          </div>
        ) : null}
      </div>
    </RootClickBoundary>
  );
}
