/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { linearGradient } from "polished";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Button2 from "src/components/Button2";
import Clickable from "src/components/Clickable";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import useNow from "src/useNow";
import DelayedZone from "src/utilities/DelayedZone";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";

export default function Home() {
  const { botApi } = Services.use();
  const navigate = useNavigate();

  const containerCss = css({
    padding: 60,
  });

  const barCss = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  });

  const listCss = css({
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: 0,
    margin: 0,
    gap: 20,
  });

  const itemCss = css(
    {
      padding: 20,
      color: Theme.colors.white,
      borderRadius: 10,
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    linearGradient({
      colorStops: [Theme.colors.blue, Theme.colors.teal],
      toDirection: "to right",
    })
  );

  const [loadAt, relaod] = useNow();
  const load = useMemo(() => botApi.loadBots(), [loadAt]);

  const createScript = useCallback(async () => {
    const name = prompt("Nom du bot");
    if (!name) return;
    const output = await botApi.createBot(name);
    relaod();
    navigate(
      Navigation.getPath("ChatbotHome", { params: { chatbot: output.uuid } })
    );
  }, []);

  return (
    <div css={containerCss}>
      <div css={barCss}>
        <Typo name="heading" color={Theme.colors.blue}>
          Vos bots en création
        </Typo>
        <Button2
          label="Créer un nouveau bot"
          onClick={createScript}
          color={Theme.colors.teal}
          layout="solid"
          icon="robot-outline"
        />
      </div>
      <Spacer size={30} />
      <DelayedZone promise={load}>
        {(bots) => {
          return (
            <div css={listCss}>
              {bots.map((bot) => (
                <Clickable
                  key={bot.uuid}
                  containerCss={itemCss}
                  to={Navigation.getPath("ChatbotHome", {
                    params: { chatbot: bot.uuid },
                  })}
                >
                  <Typo name="bold" center>
                    {bot.name}
                  </Typo>
                </Clickable>
              ))}
            </div>
          );
        }}
      </DelayedZone>
    </div>
  );
}
