/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useCallback } from "react";
import Button2 from "src/components/Button2";
import { ClickableProps } from "src/components/Clickable";
import Divider from "src/components/Divider";
import NoeseIcons from "src/components/Icon/NoeseIcons/NoeseIcons.generated";
import Intersperse from "src/components/Intersperse";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import RootClickBoundary, {
  useRootClick,
} from "src/utilities/RootClickBoundary";
import Theme from "src/utilities/Theme";

type StepCreatorProps = {
  visible: boolean;
  onClose: () => any;
  onAddUserChoice?: () => any;
  onAddBotInteraction?: () => any;
  onAddUrlAction?: () => any;
  onAddEmailAction?: () => any;
  onAddPhoneAction?: () => any;
  onAddGotoAction?: () => any;
  onAddTranslateAction?: () => any;
  onAddStopAction?: () => any;
};

export default function StepCreator(props: StepCreatorProps) {
  const {
    visible,
    onClose,
    onAddUserChoice,
    onAddBotInteraction,
    onAddUrlAction,
    onAddPhoneAction,
    onAddEmailAction,
    onAddGotoAction,
    onAddTranslateAction,
    onAddStopAction,
  } = props;

  const textColor = rgba("black", 0.5);

  const containerCss = css({
    backgroundColor: rgba("white", 0.5),
    padding: 10,
    color: textColor,
    display: "flex",
    flexDirection: "column",
    backdropFilter: `blur(40px)`,
    borderRadius: 10,
    border: `1px solid black`,
    marginLeft: 10,
    marginRight: 10,
  });

  const partCss = css({
    display: "flex",
    alignItems: "center",
  });

  const renderPart = useCallback((label: string) => {
    return (
      <div css={partCss}>
        <Typo name="bold">{label}</Typo>
        <Spacer size={20} direction="horizontal" />
        <Divider color={textColor} grow />
      </div>
    );
  }, []);

  useRootClick(onClose, visible, []);
  if (!visible) return null;
  return (
    <RootClickBoundary enabled={visible}>
      <div css={containerCss}>
        <Intersperse between={() => <Spacer scale={1} />}>
          {renderPart("Le bot envoie...")}
          <StepCreationPill
            icon="chat-processing-outline"
            label="Message"
            theme="bot"
            onClick={onAddBotInteraction}
          />
          {/* <StepCreationPill
          icon="images"
          label="Icone"
          theme="bot"
          onClick={onUnhandledAction}
          disabled={true}
        /> */}
          {renderPart("L'utilisateur choisit...")}
          <StepCreationPill
            icon="open-in-new"
            label="Bouton"
            theme="user"
            onClick={onAddUserChoice}
          />
          {/* <StepCreationPill
          icon="carousel"
          label="Carousel"
          theme="user"
          onClick={onUnhandledAction}
          disabled={true}
        />
        <StepCreationPill
          icon="images"
          label="Icône"
          theme="user"
          onClick={onUnhandledAction}
          disabled={true}
        />
        <StepCreationPill
          icon="text-bubbles"
          label="Multi-textes"
          theme="user"
          onClick={onUnhandledAction}
          disabled={true}
        /> */}
          {renderPart("Le bot effectue une action")}
          <StepCreationPill
            icon="step-url"
            label="Ouvrir une page web"
            theme="action"
            onClick={onAddUrlAction}
          />
          <StepCreationPill
            icon="step-phone"
            label="Lancer un appel"
            theme="action"
            onClick={onAddPhoneAction}
          />
          <StepCreationPill
            icon="step-email"
            label="Envoyer un email"
            theme="action"
            onClick={onAddEmailAction}
          />
          <StepCreationPill
            icon="subdirectory-arrow-left"
            label="Aller ailleurs dans le script"
            theme="action"
            onClick={onAddGotoAction}
          />
          <StepCreationPill
            icon="translate"
            label="Changer de langue"
            theme="action"
            onClick={onAddTranslateAction}
          />
          <StepCreationPill
            icon="stop"
            label="Mettre fin à la discussion"
            theme="action"
            onClick={onAddStopAction}
          />
          {/* 
        <StepCreationPill
          icon="pin-circle"
          label="Map"
          theme="action"
          onClick={onUnhandledAction}
          disabled={true}
        />

        <StepCreationPill
          icon="code-circle"
          label="Code"
          theme="action"
          onClick={onUnhandledAction}
          disabled={true}
        />
        
        <StepCreationPill
          icon="magic-wand-circle"
          label="Dialoge IA + MLN"
          theme="action"
          onClick={onUnhandledAction}
          disabled={true}
        /> */}
        </Intersperse>
      </div>
    </RootClickBoundary>
  );
}

type StepCreationPillProps = {
  label: string;
  icon: NoeseIcons;
  disabled?: boolean;
  theme: "bot" | "user" | "action";
} & ClickableProps;

function StepCreationPill(props: StepCreationPillProps) {
  const { label, icon, theme, ...clickableProps } = props;

  const color = {
    bot: Theme.colors.teal,
    user: Theme.colors.purple,
    action: Theme.colors.pink,
  }[theme];

  return (
    <Button2
      label={label}
      icon={icon}
      color={color}
      layout="solid"
      {...clickableProps}
    />
  );
}
