/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import dayjs from "dayjs";
import { groupBy, minBy, sortBy, uniq, without } from "lodash";
import { rgba } from "polished";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Divider from "src/components/Divider";
import Intersperse from "src/components/Intersperse";
import Spacer from "src/components/Spacer";
import useNow from "src/useNow";
import BotApiTypes from "src/utilities/BotApi/BotApiTypes";
import { BotManagerContext } from "src/utilities/BotManager";
import DelayedZone from "src/utilities/DelayedZone";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useMemoState from "src/utilities/useMemoState";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { Panel } from "../ChatbotSettings";
import HistoryRelease from "./HistoryRelease";
import OnlineRelease from "./OnlineRelease";
import ReleaseNow from "./ReleaseNow";
import ReleaseTaskChecker from "./TaskChecker";
import { Publication, PublicationTask } from "./types";

export default function ChatbotRelease() {
  const { botApi } = Services.use();
  const scriptUuid = Navigation.useParam("chatbot");
  const manager = BotManagerContext.use();

  const [loadKey, reload] = useNow();

  const releasesToPublications = useCallback(
    (releases: Array<BotApiTypes.Release>) => {
      const groups = Object.values(groupBy(releases, (r) => r.published_id));
      return groups.map((releases) => {
        const names = uniq(releases.map((r) => r.name));
        if (names.length !== 1) throw new Error("Bad names in releases");
        const publisheds = uniq(releases.map((r) => r.published));
        if (publisheds.length !== 1)
          throw new Error("Bad published in releases");
        const firstRelease = minBy(releases, (r) =>
          dayjs(r.created_at)
        ) as BotApiTypes.Release;
        const publication: Publication = {
          id: releases[0].published_id,
          name: names[0],
          created_at: firstRelease.created_at,
          published: publisheds[0],
          releases: sortBy(releases, (r) =>
            manager.getAllLocales().indexOf(r.language)
          ),
        };
        return publication;
      });
    },
    []
  );

  const releases = useMemo(async () => {
    const locales = Promise.all(
      manager.bot.other_locales.map((l) => manager.loadTranslations(l))
    );
    const releases = await botApi.getReleases(scriptUuid);
    const publications = releasesToPublications(releases);
    return Promise.all([publications, locales]);
  }, [scriptUuid, loadKey]);

  return (
    <DelayedZone promise={releases}>
      {([publications]) => (
        <ChatbotReleaseView publications={publications} onReload={reload} />
      )}
    </DelayedZone>
  );
}

type ChatbotReleaseViewProps = {
  publications: Array<Publication>;
  onReload: () => any;
};

function ChatbotReleaseView(props: ChatbotReleaseViewProps) {
  const { onReload } = props;
  const { botApi } = Services.use();
  const scriptUuid = Navigation.useParam("chatbot");

  const [publications, setPublications] = useMemoState<Array<Publication>>(
    () => props.publications,
    [props.publications]
  );

  const [task, setTask] = useState<PublicationTask | null>(null);

  useEffect(() => {
    if (task) {
      localStorage.setItem(
        `${scriptUuid}-publication-task`,
        JSON.stringify(task)
      );
    } else {
      localStorage.removeItem(`${scriptUuid}-publication-task`);
    }
  }, [task, scriptUuid]);

  //   const [taskRelease, setTaskRelease] = useState<
  //   BotApiTypes.Release | null | undefined
  // >();

  //   useEffect(() => {
  //     if (taskRelease === undefined) {
  //       const stored = localStorage.getItem(`${scriptUuid}-release-task`);
  //       if (stored) setTaskRelease(JSON.parse(stored));
  //       else setTaskRelease(null);
  //     }
  //   }, [taskRelease]);

  const onlinePublication = useMemo(() => {
    const published = publications.filter((r) => r.published === true);
    if (published.length > 1) return published[0];
    //throw new Error("Several publications are published");
    return published[0] || null;
  }, [publications]);

  const firstRelease = useMemo(() => {
    if (!publications) return null;
    return sortBy(publications, (r) => new Date(r.created_at).valueOf())[0];
  }, [publications]);

  const history = useMemo(() => {
    const list = onlinePublication
      ? without(publications, onlinePublication)
      : publications;
    return sortBy(list, (r) => dayjs(r.created_at).valueOf() * -1);
  }, [onlinePublication, publications]);

  const onTaskDone = useCallback(() => {
    setTask(null);
    onReload();
  }, [onReload]);

  const [onPublish] = useSubmitCallback(
    async (publication: Publication) => {
      await botApi.updatePublication(publication.id, {
        published: true,
      });
      onReload();
    },
    [onReload]
  );

  const onArchive = useCallback(
    async (publication: Publication) => {
      await botApi.updatePublication(publication.id, {
        published: false,
      });
      onReload();
    },
    [onReload]
  );

  const [onRename] = useSubmitCallback(
    async (publication: Publication, newName: string) => {
      await botApi.updatePublication(publication.id, {
        name: newName,
      });
      onReload();
    },
    [onReload]
  );

  const [onChat] = useSubmitCallback(async (release: BotApiTypes.Release) => {
    //alert("Indisponible pour le moment");
    // const response = await fetch(release.file);
    // const data = (await response.json()) as BotPlayerTypes.Bundle;
    // console.log(data);
    // ChatbotSimulatorController.set({
    //   script: data.script,
    // });
  }, []);

  const containerCss = css({
    display: "flex",
    width: "100%",
    maxWidth: 1000,
    marginInline: "auto",
    justifyContent: "center",
  });

  const columnCss = css({
    width: "50%",
  });

  let taskNode: ReactNode;
  if (task) {
    taskNode = <ReleaseTaskChecker task={task} onDone={onTaskDone} />;
  }

  let onlinePublicationNode: ReactNode;
  if (onlinePublication) {
    onlinePublicationNode = (
      <OnlineRelease
        publication={onlinePublication}
        isFirst={
          firstRelease ? onlinePublication.id === firstRelease.id : false
        }
        onArchive={onArchive}
        onRename={onRename}
        onChat={onChat}
      />
    );
  }

  let releaseNode: ReactNode;
  if (!task) {
    releaseNode = (
      <ReleaseNow isFirst={publications.length === 0} onDone={setTask} />
    );
  }

  let historyNode: ReactNode;
  if (history.length > 0) {
    historyNode = (
      <div css={columnCss}>
        <Panel heading="Historique des versions" icon="history">
          <Intersperse
            between={() => (
              <Fragment>
                <Spacer scale={2} />
                <Divider color={rgba("black", 0.1)} />
                <Spacer scale={2} />
              </Fragment>
            )}
          >
            {history.map((h) => (
              <HistoryRelease
                publication={h}
                key={h.id}
                onPublish={onPublish}
                onRename={onRename}
                isFirst={firstRelease ? firstRelease.id === h.id : false}
                onChat={onChat}
              />
            ))}
          </Intersperse>
        </Panel>
      </div>
    );
  }

  return (
    <Fragment>
      <Spacer scale={5} />
      <div css={containerCss}>
        <Intersperse between={() => <Spacer scale={2} />}>
          <div css={columnCss}>
            <Intersperse between={() => <Spacer scale={2} />}>
              {taskNode}
              {onlinePublicationNode}
              {releaseNode}
            </Intersperse>
          </div>
          {historyNode}
        </Intersperse>
      </div>
    </Fragment>
  );
}
