import { Fragment } from "react";
import { useChatbotTreeStepperController } from "src/components/ChatbotEditorStepper";
import ChoiceBubble from "src/utilities/BotDialog/ChoiceBubble";
import EmailBubble from "src/utilities/BotDialog/EmailBubble";
import EntryPoint from "src/utilities/BotDialog/EntryPoint";
import GotoBubble from "src/utilities/BotDialog/GotoBubble";
import MessageBubble from "src/utilities/BotDialog/MessageBubble";
import PhoneBubble from "src/utilities/BotDialog/PhoneBubble";
import StopBubble from "src/utilities/BotDialog/StopBubble";
import TranslateBubble from "src/utilities/BotDialog/TranslateBubble";
import UrlBubble from "src/utilities/BotDialog/UrlBubble";
import ChoiceBubbleView from "./ChoiceBubbleView";
import EmailBubbleView from "./EmailBubbleView";
import EntryPointStepView from "./EntryPointStepView";
import GotoBubbleView from "./GotoBubbleView";
import MessageBubbleView from "./MessageBubbleView";
import PhoneBubbleView from "./PhoneBubbleView";
import StopBubbleView from "./StopBubbleView";
import TranslateBubbleView from "./TranslateBubbleView";
import UrlBubbleView from "./UrlBubbleView";

export default function StepView() {
  const controller = useChatbotTreeStepperController();

  const { item } = controller.useItemSelector(
    (c, item, columnKey) => ({
      item: c.findItemData(item),
    }),
    []
  );

  let Component: React.ComponentType<{}> = () => <Fragment />;
  if (item instanceof EntryPoint) Component = EntryPointStepView;
  else if (item instanceof MessageBubble) Component = MessageBubbleView;
  else if (item instanceof ChoiceBubble) Component = ChoiceBubbleView;
  else if (item instanceof UrlBubble) Component = UrlBubbleView;
  else if (item instanceof EmailBubble) Component = EmailBubbleView;
  else if (item instanceof PhoneBubble) Component = PhoneBubbleView;
  else if (item instanceof GotoBubble) Component = GotoBubbleView;
  else if (item instanceof TranslateBubble) Component = TranslateBubbleView;
  else if (item instanceof StopBubble) Component = StopBubbleView;
  return <Component />;
}
