/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChatbotLines from "src/utilities/ChatbotLines";
import Stroke2 from "src/utilities/Stroke2";

export default function FromAboveLine() {
  return (
    <Stroke2
      x={0}
      y={-10}
      css={css({ left: 20, zIndex: 2 })}
      {...ChatbotLines.getChatbotStrokeProps(true, false)}
    />
  );
}
